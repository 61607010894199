import { Component } from '@angular/core';
import { MctradeTenantSettingsDto } from '../../shared/dto/mctrade-tenant-settings-dto';
import { DashboardService } from 'src/app/dashboard/shared/dashboard.service';
import { LogService } from 'src/app/shared/log/log.service';
import { UtilService } from 'src/app/shared/util/util.service';
import { AuthService } from 'src/app/auth/shared/auth.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { McTradeService } from '../../shared/mctrade.service';
import { SelectableItemDto } from 'src/app/shared/dto/selectable-item-dto';
import { ModelFieldOptionsDto } from '../../shared/dto/model-field-options.dto';
import { SelectableHiddenItemDto } from 'src/app/shared/dto/selectable-hidden-litem-dto';

@Component({
  selector: 'mctrade-settings',
  templateUrl: './mctrade-settings.component.html',
  styleUrl: './mctrade-settings.component.css'
})
export class MctradeSettingsComponent {


  public settings: MctradeTenantSettingsDto = new MctradeTenantSettingsDto();
  public retentionFieldNames: Array<SelectableHiddenItemDto> = new Array<SelectableHiddenItemDto>();
  public segmentationFieldNames: Array<SelectableHiddenItemDto> = new Array<SelectableHiddenItemDto>();
  public minDateValue: Date = new Date(2019, 0, 1);
  public maxDateValue: Date = new Date();
  private _productId: string = "";
  private _tenantId: string = "";

  constructor(private _dashboardService: DashboardService, private _logService: LogService, private _utilService: UtilService, private _authService: AuthService, private _notificationService: NotificationService, private _mctradeService: McTradeService) {


  }

  ngOnInit() {

    this._tenantId = this._authService.getUserSession().tenantId;
    this._productId = this._authService.getUserSession().productId;

    this.loadSettings();



  }

  loadSettings() {
    var promise1 = this._mctradeService.GetSegmentationFeatureColumnNames().then((data: Array<string>) => {

      this.segmentationFieldNames = Array<SelectableHiddenItemDto>();
      for (let item of data) {
        var vm = new SelectableHiddenItemDto();
        vm.item = item.replace("_ft", "");
        vm.selected = false;
        this.segmentationFieldNames.push(vm);
      }

    });

    var promise2 = this._mctradeService.GetRetentionFeatureColumnNames().then((data: Array<string>) => {

      this.retentionFieldNames = Array<SelectableHiddenItemDto>();
      for (let item of data) {
        var vm = new SelectableHiddenItemDto();
        vm.item = item.replace("_ft", "");
        vm.selected = false;
        this.retentionFieldNames.push(vm);
      }


    });

    Promise.all([promise1, promise2]).then(() => {
      this._mctradeService.GetTenantSettings().then((data: MctradeTenantSettingsDto) => {

        this._utilService.copyObjectPropertyValues(data, this.settings);
        this.settings.ingestionAsOfDate = new Date(data.ingestionAsOfDate);

        for (let item of this.settings.retentionModelFieldOptions) {
          for (let vm of this.retentionFieldNames) {
            if (this._utilService.toLowerTrim(vm.item) == this._utilService.toLowerTrim(item.fieldName)) {
              if (item.exclude == true) {
                vm.selected = true;
              }

            }
          }
        }

        for (let item of this.settings.segmentationModelFieldOptions) {
          for (let vm of this.segmentationFieldNames) {
            if (this._utilService.toLowerTrim(vm.item) == this._utilService.toLowerTrim(item.fieldName)) {
              if (item.exclude == true) {
                vm.selected = true;
              }
            }
          }
        }

        this.enableOrDisableRetentionCategoricalFields();
        this.enableOrDisableSegmentationCategoricalFields()

      });


    });
  }

  // handleIngestionAsOfDateBlur(){
  //   if(this.settings.ingestionAsOfDate < this.minDateValue ){
  //     this.settings.ingestionAsOfDate  = this.minDateValue;
  //   }
  //   if(this.settings.ingestionAsOfDate > this.maxDateValue ){
  //     this.settings.ingestionAsOfDate  = this.maxDateValue;
  //   }

  // }

  saveSettings() {

    this.settings.retentionModelFieldOptions = new Array<ModelFieldOptionsDto>();
    this.settings.segmentationModelFieldOptions = new Array<ModelFieldOptionsDto>();

    for (let vm of this.retentionFieldNames) {
      if (vm.selected == true) {
        var item = new ModelFieldOptionsDto();
        item.fieldName = vm.item;
        item.exclude = true;
        this.settings.retentionModelFieldOptions.push(item);
      }

    }

    for (let vm of this.segmentationFieldNames) {
      if (vm.selected == true) {
        var item = new ModelFieldOptionsDto();
        item.fieldName = vm.item;
        item.exclude = true;
        this.settings.segmentationModelFieldOptions.push(item);
      }

    }

    this._mctradeService.UpsertTenantSettings(this.settings);


  }

  segmentationModelExcludeCategoricalDataCheckboxChanged() {
    this.enableOrDisableSegmentationCategoricalFields();
  }

  enableOrDisableSegmentationCategoricalFields() {

    for (let vm of this.segmentationFieldNames) {
      if (this.isCategoricalField(vm.item) == true) {
        vm.hidden = this.settings.segmentationModelExcludeCategoricalData;
        vm.selected = this.settings.segmentationModelExcludeCategoricalData;
      } else {
        vm.hidden = false;
      }

    }
    //sort the disabled to the bottom
    this.segmentationFieldNames = this.segmentationFieldNames.sort(x => {
      
       
      if (x.hidden == true ) {
        return 1;
      }
      else {
        return  -1;
      }
     
      
    
});
  }

  retentionModelExcludeCategoricalDataCheckboxChanged() {
    this.enableOrDisableRetentionCategoricalFields();
  }

  enableOrDisableRetentionCategoricalFields() {

    for (let vm of this.retentionFieldNames) {
      if (this.isCategoricalField(vm.item) == true) {
        vm.hidden = this.settings.retentionModelExcludeCategoricalData;
        vm.selected = this.settings.retentionModelExcludeCategoricalData;

      } else {
        vm.hidden = false;
      }

    }
    //sort the disabled to the bottom
    this.retentionFieldNames = this.retentionFieldNames.sort(x => {
      
       
        if (x.hidden == true ) {
          return 1;
        }
        else {
          return  -1;
        }
       
        
      
  });
  }


  isCategoricalField(fieldName: string) {

    return this._utilService.arrayContainsValue(this._mctradeService.GetEngaementModelCategoricalFieldNames(), fieldName);
  }
}

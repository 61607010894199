import { AuthService } from './../../auth/shared/auth.service';
import { LogService } from './../log/log.service';
import { HttpRequestOptions } from './../http/http-request-options.model';
import { ApiCacheOptions } from './api-cache-options.model';
import { UtilService } from './../util/util.service';
import { HttpRequestService } from './../http/http-request.service';
import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { saveAs } from 'file-saver';
import { NavigationService } from '../navigation/navigation.service';
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private _headers = new Array<KeyValue<string, any>>();
  private _apiDomain = '';
  private _apiBaseRoute = '';
  private _apiBaseUrl = '';
  private _requestedUrl = '';

  private get _apiUrlsSet(): boolean {
    return this._apiBaseUrl != null && this._apiBaseUrl !== '';
  }

  constructor(
    private _httpRequestService: HttpRequestService,
    private _utilService: UtilService,
    private _logService: LogService,
    private _authService: AuthService,
    private _navigationService: NavigationService
  ) { }

  public get(url: string, cacheOptions?: any, notifyOnError?: boolean): Promise<any> {
    if (!cacheOptions) {
      cacheOptions = this.getDefaultApiCacheOptionsForFrequentlyChangedData();
    }

    const fullUrl = this.getFullUrl(url);
    if (this.ensureValidAccessToken(fullUrl)) {
      const httpRequestOptions: HttpRequestOptions = this.getHttpRequestOptionsFromApiCacheOptions(cacheOptions);
      //httpRequestOptions.Headers.push(...this._headers);

      return this._httpRequestService.getRequest(fullUrl, httpRequestOptions);
    } else {
      return this._httpRequestService.get(fullUrl);
    }
  }

  public getNoCache(url: string, notifyOnError?: boolean): Promise<any> {

    var cacheOptions = this.getDefaultApiNoCacheOptions();

    const fullUrl = this.getFullUrl(url);
    if (this.ensureValidAccessToken(fullUrl)) {
      const httpRequestOptions: HttpRequestOptions = this.getHttpRequestOptionsFromApiCacheOptions(cacheOptions);
      //httpRequestOptions.Headers.push(...this._headers);

      return this._httpRequestService.get(fullUrl, httpRequestOptions);
    } else {
      return this._httpRequestService.get(fullUrl);
    }
  }

  
  public post(url: string, body: any, cacheOptions?: any, notifyOnError?: boolean): Promise<any> {

    if (!cacheOptions) {
      cacheOptions = this.getDefaultApiCacheOptionsForFrequentlyChangedData();
    }
    const fullUrl = this.getFullUrl(url);
    if (this.ensureValidAccessToken(fullUrl)) {
      const httpRequestOptions: HttpRequestOptions = this.getHttpRequestOptionsFromApiCacheOptions(cacheOptions);
      return this._httpRequestService.post(fullUrl, body, httpRequestOptions);
    }
    return this._httpRequestService.post(fullUrl, body);
  }

  public postNoPayload(url: string, cacheOptions?: any, notifyOnError?: boolean): Promise<any> {

    if (!cacheOptions) {
      cacheOptions = this.getDefaultApiCacheOptionsForFrequentlyChangedData();
    }
    const fullUrl = this.getFullUrl(url);
    if (this.ensureValidAccessToken(fullUrl)) {
      const httpRequestOptions: HttpRequestOptions = this.getHttpRequestOptionsFromApiCacheOptions(cacheOptions);
      return this._httpRequestService.post(fullUrl, null, httpRequestOptions);
    }
    return this._httpRequestService.post(fullUrl, null);
  }

  public postFormData(url: string, formData: FormData, cacheOptions?: any, notifyOnError?: boolean): Promise<any> {

    if (!cacheOptions) {
      cacheOptions = this.getDefaultApiCacheOptionsForFrequentlyChangedData();
    }
    const fullUrl = this.getFullUrl(url);
    if (this.ensureValidAccessToken(fullUrl)) {
      const httpRequestOptions: HttpRequestOptions = this.getHttpRequestOptionsFromApiCacheOptions(cacheOptions);
      return this._httpRequestService.post(fullUrl, formData, httpRequestOptions);
    }
    return this._httpRequestService.post(fullUrl, formData);
  }

  public put(url: string, body: any, cacheOptions?: any, notifyOnError?: boolean): Promise<any> {

    if (!cacheOptions) {
      cacheOptions = this.getDefaultApiCacheOptionsForFrequentlyChangedData();
    }
    const fullUrl = this.getFullUrl(url);
    if (this.ensureValidAccessToken(fullUrl)) {
      const httpRequestOptions: HttpRequestOptions = this.getHttpRequestOptionsFromApiCacheOptions(cacheOptions);
      return this._httpRequestService.put(fullUrl, body, httpRequestOptions);
    }
    return this._httpRequestService.put(fullUrl, body);
  }

  public delete(url: string, body: any, cacheOptions?: any, notifyOnError?: boolean): Promise<any> {

    if (!cacheOptions) {
      cacheOptions = this.getDefaultApiCacheOptionsForFrequentlyChangedData();
    }
    const fullUrl = this.getFullUrl(url);
    if (this.ensureValidAccessToken(fullUrl)) {
      const httpRequestOptions: HttpRequestOptions = this.getHttpRequestOptionsFromApiCacheOptions(cacheOptions);
      return this._httpRequestService.delete(fullUrl, body, httpRequestOptions);
    }
    return this._httpRequestService.delete(fullUrl, body);
  }

  public getFullUrl(url: string): string {
    this.ensureApiUrlsAreSet();
    let fullUrl = url;

    if (this._utilService.startsWith(url.toLowerCase(), '/')) {
      // assume rel path starting with /
      fullUrl = this._apiBaseUrl + url;
    } else {
      if (!this._utilService.startsWith(url.toLowerCase(), 'http')) {
        // assume rel path starting with no leading /
        fullUrl = this._apiBaseUrl + url;
      }
    }

    this._requestedUrl = fullUrl;
    this._logService.log('api url', fullUrl);
    return fullUrl;
  }

  public ensureApiUrlsAreSet() {
    this._apiDomain = environment.apiDomain;
    this._apiBaseRoute = environment.apiBaseRoute;
    this._apiBaseUrl = this._apiDomain + this._apiBaseRoute;
  }

  public ensureValidAccessToken(url: string): boolean {
    const valid = this.hasValidAccessToken(url);
    if (!valid) {
      this._logService.log('api requires authentication', this._requestedUrl);

      // //token is no goo so navigate to unauthorized page - this caused a timing issue
      // var urlSessionId = this._utilService.getQueryStringParamValue("sessionid", "string");
      // if (this._utilService.isNullEmptyOrWhitespace(urlSessionId)){
      //   //only navigate to /unauthenticated if the url doesn't have ?sessionId as its in the process of updating auth
      //   this._logService.log('Api Token Not Valid', 'api service navigating to unauthorized');
      //   this._navigationService.navigateToUnauthorized();
      // }


      // TODO have an event raised that says unauthenticated and have a component subscribe to it????
      // this._router.navigate(["/unauthenticated"]);
    } else {
      this._logService.log('api token is valid', this._requestedUrl);
    }
    return valid;
  }

  public hasValidAccessToken(url: string): boolean {
    let isValid = false;

    this._headers = new Array<KeyValue<string, any>>();

    this._headers.push({ key: 'Content-Type', value: 'application/json' });

    if (this.isApiRouteAnon(url)) {
      this._headers.push({ key: 'Authorization', value: '' });
      isValid = true;
    } else {

      var accessToken = this._authService.getUserSession().accessToken;

      if (this._utilService.isNullOrUndefined(accessToken)) {
        //no token found
        isValid = false;
      } else if (this._authService.tokenIsExpired(accessToken)) {
        //expired token
        isValid = false;
      } else {
        isValid = true;
      }
      var headerValue = 'Bearer ' + accessToken;
      this._headers.push({ key: 'Authorization', value: headerValue });
      //isValid = true;


      //if (this._tokenStorageService.hasValidUserTypeAccessToken() == true) {
      //  //access token is still good
      //  let accesToken: string = this._tokenStorageService.getUserTypeAccessToken();

      //  this._headers.push({ key: 'Authorization', value: `Bearer ${accesToken}` });
      //  //if (tenant) {
      //  //  this._headers.push({ key: 'x-tenant', value: tenant });
      //  //}
      //  isValid = true;
      //} else {
      //  //force user to re login
      //  isValid = false;
      //}
    }

    return isValid;
  }

  public isApiRouteAnon(url: string): boolean {
    let result = false;

    if (this._utilService.containsAnyCase(url.trim(), 'api/users/requestpasswordreset')) {
      result = true;
    }
    if (this._utilService.containsAnyCase(url.trim(), 'api/users/passwordreset')) {
      result = true;
    }
    if (this._utilService.containsAnyCase(url.trim(), 'api/test/')) {
      result = true;
    }

    return result;
  }

  public getDefaultApiCacheOptionsForFrequentlyChangedData(): ApiCacheOptions {
    // sliding cache for 30 seconds
    const cacheOptions = new ApiCacheOptions();
    cacheOptions.DisableCaching = false;
    cacheOptions.DeleteCache = false;
    cacheOptions.CacheSeconds = this._httpRequestService.getDefaultCacheSeconds();
    cacheOptions.DisableSlidingCache = false;
    return cacheOptions;
  }

  public getDefaultApiNoCacheOptions(): ApiCacheOptions {
    // no cache
    const cacheOptions = new ApiCacheOptions();
    cacheOptions.DisableCaching = true;
    cacheOptions.DeleteCache = true;
    cacheOptions.CacheSeconds = this._httpRequestService.getDefaultCacheSeconds();
    cacheOptions.DisableSlidingCache = true;
    return cacheOptions;
  }

  private getHttpRequestOptionsFromApiCacheOptions(apiCacheOptions: ApiCacheOptions): HttpRequestOptions {
    const httpRequestOptions = new HttpRequestOptions();
    httpRequestOptions.Headers = this._headers;
    httpRequestOptions.EnableCaching = !apiCacheOptions.DisableCaching;
    httpRequestOptions.CacheSeconds = apiCacheOptions.CacheSeconds;

    if (apiCacheOptions.DisableSlidingCache) {
      httpRequestOptions.SlidingCache = false;
      httpRequestOptions.SlidingCacheSeconds = 0;
    } else {
      httpRequestOptions.SlidingCache = true;
      httpRequestOptions.SlidingCacheSeconds = httpRequestOptions.CacheSeconds;
    }

    if (apiCacheOptions.DeleteCache) {
      httpRequestOptions.DeleteCache = true;
    }

    return httpRequestOptions;
  }

  public getApiDomain(): string {
    return this._apiDomain;
  }





  public downloadFile(url: string, cacheOptions?: any, fileName?: string): Promise<any> {

    if (!cacheOptions) {
      cacheOptions = this.getDefaultApiCacheOptionsForFrequentlyChangedData();
    }
    var fullUrl = this.getFullUrl(url);

    var httpRequestOptions: HttpRequestOptions = this.getHttpRequestOptionsFromApiCacheOptions(cacheOptions);

    url = this.getFullUrl(url);
    return this._httpRequestService.getBinaryFile(url, httpRequestOptions).then((data: any) => {


      var blob = new Blob([data], { type: 'application/octet-stream' });


      if (this._utilService.isNullEmptyOrWhitespace(fileName)) {
        fileName = "data.csv";
      }
      // Extract filename from header
      // var fileName = data.headers.get('content-disposition')
      //   .split(';')
      //   .find(n => n.includes('filename='))
      //   .replace('filename=', '')
      //   .trim()
      //   ;

      // Download the file
      saveAs(blob, fileName);



    });


  }
}

import { NavigationService } from '../../../shared/navigation/navigation.service';

import { Observable } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilService } from '../../util/util.service';
import { LogService } from '../../log/log.service';
import { AuthService } from '../../../auth/shared/auth.service';
import { CognitoJwt } from '../../../auth/shared/cognitoJwt.model';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _logService: LogService, private _navigationService: NavigationService, private _utilService: UtilService, private _authService:AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //let token: string = '';
    //let product = '';
    //let tenant = '';
    

    //if (this._utilService.hasQueryStringParam('token') == true && this._utilService.hasQueryStringParam('product') == true && this._utilService.hasQueryStringParam('tenant') == true) {
    //  product = this._utilService.getQueryStringParamValue('product', 'string');
    //  tenant = this._utilService.getQueryStringParamValue('tenant', 'string');
    //  token = this._utilService.getQueryStringParamValue('token', 'string');

      

    //  this._authService.validateAccessToken(product, tenant, token).then((data: CognitoJwt) => {
    //    if (data.isValid == false) {
    //      this._logService.log("Auth", "Token in not valid.");
    //      this._navigationService.navigateToUnauthorized();
    //    } else {
    //      //send to home
    //      this._navigationService.navigateToHome();
    //    }
    //  });
    //}
    //else {
    //  //get current access token
    //  this._logService.log("Auth", "Getting current token...");
    //  this._authService.validateStoredAccessToken().then((data: CognitoJwt) => {
    //    if (data.isValid == false) {
    //      this._logService.log("Auth", "Current token is NOT valid");
    //      this._navigationService.navigateToUnauthorized();
    //    } else {
    //      //allow the request
    //      this._logService.log("Auth", "Current token is valid");
    //    }
    //  });
    //}
    //let accessToken: string = this._tokenStorageService.getCurrentToken();
    //let tenant: string = this._tokenStorageService.getCurrentTenant();

    //if (request.url.toLocaleLowerCase().indexOf('idp') < 0 && request.url.toLocaleLowerCase().indexOf('login') < 0) {
    //  if (this._tokenStorageService.tokenIsExpired(accessToken) == true) {
    //    ////force user to login
    //    //setTimeout(() => {
    //    //  this._navigationService.navigateToLogin();
    //    //}, 1000);
        
    //  }
    //}
    //else {
    //  request = request.clone({
    //    setHeaders: {
    //      Authorization: 'Bearer ' + accessToken,
    //      'x-tenant': tenant,
    //    },
    //  });
    //}
   
    
    return next.handle(request);
  }
}

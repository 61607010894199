<h3 i18n>AI Assistant </h3>
<div style="width:99%">
    <div class="grid">
        <!-- <div class="col-12">

            <label i18n>Ask a question, request a task, or upload a file to review</label>
        </div> -->

        <div class="col-12">

            <p-dropdown i18n [options]="generationOptions" [(ngModel)]="selectedGenerationOption" optionLabel="name"
                optionValue="value" (ngModelChange)="generationOptionsChanged($event)"></p-dropdown>
        </div>

        <div class="col-12" [hidden]="this.selectedGenerationOption != 2">

            <div [hidden]="this.selectedGenerationOption != 2" class="grid">
                <div class="col-4">
                    <label [hidden]="this.selectedGenerationOption != 2" i18n>Image Type </label>
                </div>
                <div class="col-4">
                    <label [hidden]="this.selectedGenerationOption != 2" i18n>Image Size </label>
                </div>
                <div class="col-4">

                </div>

                <div class="col-4">
                    <p-dropdown #imageTypeDropDown i18n [hidden]="this.selectedGenerationOption != 2"
                    [options]="imageTypeOptions" [(ngModel)]="selectedImageTypeOption" (ngModelChange)="imageTypeOptionsChanged($event)" optionLabel="name"
                     ></p-dropdown>
                </div>
                <div class="col-4">
                    <p-dropdown #imageSizeDropDown i18n [hidden]="this.selectedGenerationOption != 2"
                    [options]="imageSizeOptions" [(ngModel)]="selectedImageSizeOption" (ngModelChange)="imageSizeOptionsChanged($event)" optionLabel="name"
                     ></p-dropdown>
                </div>
                <div class="col-4">

                </div>

            </div>
            
           
          

           
           
               

        </div>

        <div class="col-12" [hidden]="this.selectedGenerationOption != 1">

            <input #fileUpload type="file" [hidden]="this.selectedGenerationOption != 1" class="file-input"
                (change)="onFileSelected($event)" accept=".pdf,.jpeg,.jpg,.tiff,.png,.docx,.xlsx,.txt,.csv">

        </div>

       
        <div class="col-12">
         
            <p-progressBar mode="indeterminate" *ngIf="fileTextExtractionRunning"></p-progressBar>
        </div>
        <!-- <div class="col-8">
                   
        </div> -->

        <div class="col-12">

            <textarea #requestTextInput pInputTextarea [(ngModel)]="requestText" [rows]="15" style="width:100%; resize :vertical"
                autoResize="autoResize"></textarea>
        </div>



        <div class="col-12">

            <p-button [disabled]="fileTextExtractionRunning || selectedGenerationOption == 1 && uploadFile == null"
                (click)="askClick()" i18n-label label="Go"></p-button>
        </div>
        <div class="col-12">

            <p-progressBar mode="indeterminate" *ngIf="isWaitingForResponse"></p-progressBar>
        </div>

        <div class="col-12">
            <pre
                style="width:100%; overflow-x:auto; white-space: pre-wrap; word-wrap: break-word">{{responseText}}</pre>
        </div>
        <div class="col-12">
            <img [src]="imageSource" />
        </div>

        <div class="col-12">

            <p-button [hidden]="responseText == ''" (click)="copyTextClick()" i18n-label label="Copy text"></p-button>
        </div>
    </div>
</div>
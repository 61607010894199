import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser';


import { LocalStorageService } from '../../../../shared/storage/local-storage.service';
import { DashboardService } from '../../../../dashboard/shared/dashboard.service';

import { createEmbeddingContext, DashboardContentOptions, FrameOptions, EmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { AuthService } from '../../../../auth/shared/auth.service';
import { LogService } from '../../../../shared/log/log.service';
import { Observable } from 'rxjs';
import { UtilService } from '../../../../shared/util/util.service';


@Component({
  selector: 'mctrade-my-dashboard',
  templateUrl: './mctrade-my-dashboard.component.html',
  styleUrls: ['./mctrade-my-dashboard.component.css']
})
export class MctradeMyDashboardComponent implements OnInit {

 /* @Input() embeddingContext: EmbeddingContext;*/

  private _productId: string = "";
  private _tenantId: string = "";
  public embedUrls: Array<string> = new Array<string>();
  private _internalUrls = new Array<string>();

  public dashboardEmbedUrlRaw: string = "";
  public embedUrlPromises: Array<Promise<any>> = new Array<Promise<any>>();

  constructor(private _dashboardService: DashboardService, private _logService: LogService, private _utilService: UtilService, private _authService: AuthService, private _sanitizer: DomSanitizer, private _localStorageService: LocalStorageService, private _changeDetection: ChangeDetectorRef) {


  }

  ngOnInit() {

    this._tenantId = this._authService.getUserSession().tenantId;
    this._productId = this._authService.getUserSession().productId;
    this.loadQuicksightsElements();

  }

  loadQuicksightsElements = (): void => {

    

    this._dashboardService.GetTenantDashboardIds(this._tenantId, "my").then((data: Array<string>) => {

      this._internalUrls = [];
      for (let id of data) {

        var embedUrlPromise = this._dashboardService.getDashboardEmbedUrlByDashboardId(id).then((data: string) => {

          this.embedUrls.push(data);


        }, (err) => {
          this._logService.info(err);
        });

        this.embedUrlPromises.push(embedUrlPromise);
      }


      Promise.all(this.embedUrlPromises).then((data) => {

        this.embedQuicksightTools();

      });


    });

  }



  embedQuicksightTools = async () => {

    let i: number = 0;
    for (let url of this.embedUrls) {
     
      var dashboardContainerId = "my-dashboard-container-" + i.toString();
      var frameOptionsDashboard = this._dashboardService.getDashboardFrameOptions(dashboardContainerId, dashboardContainerId, url);
      var contentOptionsDashboard = this._dashboardService.getDashboardContentOptions(dashboardContainerId);
      var embeddedDashboardExperience = this._dashboardService.getEmbeddingContext().embedDashboard(frameOptionsDashboard, contentOptionsDashboard);

      i = i + 1;
    }
 };



}

export class AIRequestDto {
    productId: string;
    tenantId: string;
    requestQuestion: string;
    textContext: string;
    s3KeyForTextContext: string;
    isImageGenerationRequest: boolean;
    imageHeight : number;
    imageWidth: number;
    imageStylePreset: string;
}
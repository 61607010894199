 <div class="formgrid grid" style="width:100%">
    <div class="col-12">
        <b>Activity</b>
    </div>
    <div class="col-2">
        Data
    </div>
    <div class="col-3">
        Start Date
    </div>
    <div class="col-3">
        End Date
    </div>
    <div class="col-2">
        
    </div>
    <div class="col-2">
        
    </div>


    <div class="col-2">
        <p-dropdown [options]="dataEntities" [(ngModel)]="selectedDataEntity"></p-dropdown>
    </div>
    <div class="col-3">
        <p-calendar [(ngModel)]="ingestConfig.startDate"></p-calendar>
    </div>
    <div class="col-3">
        <p-calendar [(ngModel)]="ingestConfig.endDate"></p-calendar>
    </div>

    <div class="col-2">        
            <p-checkbox [(ngModel)]="ingestConfig.overwriteAll" label="Overwrite"></p-checkbox>        
    </div>
    <div class="col-2">
        <p-button (click)="ingestData()">Ingest</p-button>
    </div>
    


    <div class="col-12">

        <p-table [value]="activitySearchResult.result" styleClass="p-datatable-gridlines" responsiveLayout="scroll" [paginator]="true"
            [rows]="10" onPage="handlePaging()">
            <ng-template pTemplate="caption">
                Activity
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Sub Type</th>
                    <th>Descr</th>
                    <th>Success</th>
                    <th>Result</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td>{{item.activityDate| date}}</td>
                    <td>{{item.activityType}}</td>
                    <td>{{item.activitySubType}}</td>
                    <td>{{item.descr}}</td>
                    <td>{{item.success}}</td>
                    <td>{{item.result}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">

            </ng-template>

        </p-table>

    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { UtilService } from './../../shared/util/util.service';
import { AuthService } from '../shared/auth.service';

@Component({
  selector: 'logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {
  displayResponse: string;

  constructor(private _utilService: UtilService, private _authService: AuthService) {
    
  }
    ngOnInit(): void {
         
    }
    


}

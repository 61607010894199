export class NameValueNumberDto {
    public constructor() {
  
      this.name = "";
      this.value = 0;
     
    }
  
    public  name : string
    public  value : number
   
  
  }
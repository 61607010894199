import { Component } from '@angular/core';

@Component({
  selector: 'mctrade-dashboard-home',
  templateUrl: './mctrade-dashboard-home.component.html',
  styleUrl: './mctrade-dashboard-home.component.css'
})
export class MctradeDashboardHomeComponent {

}

import { HttpRequestService } from './../../shared/http/http-request.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LogService } from './../../shared/log/log.service';
import { LocalStorageService } from './../../shared/storage/local-storage.service';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { UtilService } from './../../shared/util/util.service';
import { WellKnownEndpoints } from './well-known-endpoints.model';
import { HttpRequestOptions } from './../../shared/http/http-request-options.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class IdentityProviderService {
  // LOOK HERE   https://github.com/aaronpk/pkce-vanilla-js/blob/master/index.html


  private _isInitialized: boolean = false;
  private _idpRootDomain = '';
  private _idpRootUrl = '';
  private _clientId: string = '';
  private _clientSecret: string = '';
  private _codeChallenge: string = '';
  private _codeVerifier: string = '';
  private _state: string = '';
  private _nonce: string = '';
  private _redirectUrl: string = '';
  private _postLoginUrl: string = '';
  private _responseType: string = '';
  private _scope: string = '';
  private _endpoints: WellKnownEndpoints = new WellKnownEndpoints();
  private _tenant: string = '';
  private _accessToken: string = '';
  private _idToken: string = '';
  private _localStorage_pkce_code_verifier_name: string = 'pkce_code_verifier';
  private _localStorage_pkce_state_name: string = 'pkce_state';
  private _localStorage_pkce_nonce_name: string = 'pkce_nonce';
  private _localStorage_pkce_redirecturl_name: string = 'pkce_redirecturl';


  constructor(
    private _httpClient: HttpClient,
    private _utilService: UtilService,
    private _localStorageService: LocalStorageService,
    private _logService: LogService,
    private _authService: AuthService,
    private _httpRequestService: HttpRequestService
  ) {}

  public initialize(): void {
    if (this.isInitialized() === false) {
      this._idpRootUrl = environment.idpBaseUrl;

      this._endpoints.issuer = this.getIDPRootUrl();

      this._endpoints.loginEndpoint = this.getIDPRootUrl() +'/login';
      this._endpoints.authorizationEndpoint = this.getIDPRootUrl() +'/connect/authorize';

      //this._endpoints.tokenEndpoint = this.getIDPRootUrl() + '/connect/token';
      this._endpoints.tokenEndpoint = this.getIDPRootUrl() + '/oauth2/token';

      this._endpoints.userInfoEndpoint = this.getIDPRootUrl() + '/connect/userinfo';
      this._endpoints.endSessionEndpoint = this.getIDPRootUrl() + '/connect/endsession';
      this._endpoints.checkSessionIframe = this.getIDPRootUrl() + '/connect/checksession';
      this._endpoints.revocationEndpoint = this.getIDPRootUrl() + '/connect/revocation';
      this._endpoints.introspectionEndpoint = this.getIDPRootUrl() + '/connect/introspect';

      this._clientId = environment.cognitoAppIdInsightsSpa; //environment.cognitoAppIdMcTrade; //'rab_private_client' //'a2z_events_private_client'; //'PersonifyCommunityTrustedApp'; //
      this._clientSecret = ''; //'c3ec7017-66a6-98b7-a5b3-de5ba554d434' //'080ff7ef-430a-c74d-1196-15ec311be81f'; // 'K996R395-5287-2H54-12UK-77J9M1X485V4'; //
      this._codeChallenge = '';
      this._state = '';
      this._nonce = '';
      this._scope = 'openid profile aws.cognito.signin.user.admin'; //'rab_api psfy_products profile openid';  //'a2z_events_api psfy_products profile token';
      this._redirectUrl = '';
      this._postLoginUrl = 'https://' + window.location.hostname + '/post-login';
      this._responseType = '';


      //https://mydomain.auth.us-east-1.amazoncognito.com/login?
      // response_type=code&
      // client_id=ad398u21ijw3s9w3939&
      // redirect_uri=https://YOUR_APP/redirect_uri&
      // state=STATE&
      // scope=openid+profile+aws.cognito.signin.user.admin

      this._isInitialized = true;
    }
  }

  public isInitialized(): boolean {
    return this._isInitialized;
  }
  public getIDPRootDomain(): string {
    return this._idpRootDomain;
  }
  public getIDPRootUrl(): string {
    if (this._idpRootUrl.indexOf('https://') == -1) {
      if (this._idpRootUrl.indexOf('http://') > -1) {
        this._idpRootUrl = this._idpRootUrl.replace('http://', '');
      }

      this._idpRootUrl = 'https://' + this._idpRootUrl;
    }
    return this._idpRootUrl;
  }

  public getApiBaseUrl(): string {
    let baseApiUrl = '';
    if (environment.apiDomain) {
      baseApiUrl = environment.apiDomain;
    } 
    return baseApiUrl;
  }

  

  public authorizeCodeFlowRequestCode(returnHint?: string): void {
    // send to post login route after login
    // this components will extract code and get access token and then send
    // to the page the user shoudl be on
    this._responseType = 'code';
    //having  resource grants and identity grants allows you to get back and access_token and an id_token
    // when posting to the token endpoitn after you get your authorization code back
    //this._scope = 'a2z_events_api psfy_products profile openid';
    this._postLoginUrl = 'https://' + window.location.host + '/post-login';

    if (returnHint) {

      this._postLoginUrl = this._postLoginUrl + '?returnHint=' + returnHint;
      

    } 

    
    this._redirectUrl = this._postLoginUrl;
    //store the redirectUrl so it can be used to post back to the IDP when we receive the code
    this._localStorageService.setString(this._localStorage_pkce_redirecturl_name, this._redirectUrl);

    // Create and store a new PKCE code_verifier (the plaintext random secret)
    this._codeVerifier = this.generateRandomString();
    // store it
    this._localStorageService.setString(this._localStorage_pkce_code_verifier_name, this._codeVerifier);

    // Create and store a random "state" value
    this._state = this.generateRandomString();
    // store it
    this._localStorageService.setString(this._localStorage_pkce_state_name, this._state);

    // Create and store a random "none" value
    this._nonce = this.generateRandomString();
    // store it
    this._localStorageService.setString(this._localStorage_pkce_nonce_name, this._nonce);

    

    // Hash and base64-urlencode the secret to use as the challenge
    this.pkceChallengeFromVerifier(this._codeVerifier).then((data: string) => {
      //const tenant = ''; //environment.tenant;
      //get the tenant from local storage
      let tenant = this._authService.getUserSession().tenantId;

      this._codeChallenge = data;

      //for cognito
      const url =
        this._endpoints.loginEndpoint +
        '?client_id=' +
        this._clientId +
        '&redirect_uri=' +
        encodeURIComponent(this._redirectUrl) +
        '&response_type=' +
        encodeURIComponent(this._responseType) +
        '&scope=' +
        encodeURIComponent(this._scope) +
        '&state=' +
        encodeURIComponent(this._state);
      

      //for psfy idp
      // const url =
      //   this._endpoints.authorizationEndpoint +
      //   '?client_id=' +
      //   this._clientId +
      //   '&prompt=login&redirect_uri=' +
      //   encodeURIComponent(this._redirectUrl) +
      //   '&response_type=' +
      //   encodeURIComponent(this._responseType) +
      //   '&scope=' +
      //   encodeURIComponent(this._scope) +
      //   '&nonce=' +
      //   encodeURIComponent(this._nonce) +
      //   '&state=' +
      //   encodeURIComponent(this._state) +
      //   '&code_challenge=' +
      //   encodeURIComponent(this._codeChallenge) +
      //   '&code_challenge_method=S256' +
      //   '&acr_values=tenant:' +
      //   encodeURIComponent(tenant);

      console.log(url);
      window.location.href = url;
    });
  }

  public hasAuthorizationCodeInUrl(): boolean {
    let result = false;
    const codeValue = this.extractAuthorizationCodeFromUrl();
    if (codeValue) {
      result = true;
    }
    return result;
  }

  public extractAuthorizationCodeFromUrl(): string {
    let code = '';
    if (window.location.hash) {
      //get from url hash
      code = this._utilService.getHashParamValue('code', 'string', window.location.hash);
    } else {
      //get from querystring
      code = this._utilService.getQueryStringParamValue('code', 'string', window.location.href);
    }
    return code;
  }

  public extractAccessTokenFromUrl(): string {
    if (window.location.hash) {
      return this._utilService.getHashParamValue('access_token', 'string', window.location.hash);
    }

    return null;
  }

  public extractStateFromUrl(): string {
    let state = '';
    if (window.location.hash) {
      state = this._utilService.getHashParamValue('state', 'string', window.location.hash);
    } else {
      state = this._utilService.getQueryStringParamValue('state', 'string', window.location.href);
    }
    return state;
  }

  public authorizationCodeFlowExchangeCodeForAccessToken(code: string): Promise<any> {
    let hdrs = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
    });

    //let hdrs = new HttpHeaders({
    //  "Content-Type": "application/x-www-form-urlencoded",
    //  "Accept": "application/json",
    //  "Authorization": "Basic " + btoa("yourclientid" + ':' + "yourclientsecret")
    //});

    // Verify state matches what we set at the beginning
    let storedState = this._localStorageService.getString(this._localStorage_pkce_state_name);
    let storedReturnUrl = this._localStorageService.getString(this._localStorage_pkce_redirecturl_name);

    let urlState = this.extractStateFromUrl();
    if (storedState != urlState) {
      let errorMessage = 'Authorization error.  Invalid state.';
      return Promise.reject(errorMessage);
    } else {
      let pckeCodeVerifier = this._localStorageService.getString(this._localStorage_pkce_code_verifier_name);

      let data =
        'code=' +
        code +
        '&code_verifier=' +
        pckeCodeVerifier +
        '&grant_type=authorization_code' +
        '&client_id=' +
        this._clientId +
        '&client_secret=' +
        this._clientSecret +
        '&redirect_uri=' +
        encodeURIComponent(storedReturnUrl);

      return this._httpClient.post(this._endpoints.tokenEndpoint, data, { headers: hdrs }).toPromise();
    }
  }

  public logout(returnUrl?: string): void {
    returnUrl = 'https://' + window.location.host + '/post-logout';

    //let idTokenHintUrlParam = '';
    //if (this._tokenStorageService.hasValidIdToken() === true) {
    //  //hasValidIdToken
    //  idTokenHintUrlParam = '&id_token_hint=' + this._tokenStorageService.getIdToken();
    //}
    //redirect to end session
    let url = this._endpoints.endSessionEndpoint + '?post_logout_redirect_uri=' + encodeURIComponent(returnUrl) //+ idTokenHintUrlParam;

    // clear out the user tokens
    this._authService.logout();
    //this._tokenStorageService.setUserAccessToken('');
    //this._tokenStorageService.setIdToken('');

    window.location.href = url;
  }

  // PKCE HELPER FUNCTIONS

  // Generate a secure random string using the browser crypto functions
  public generateRandomString() {
    var array = new Uint32Array(28);
    window.crypto.getRandomValues(array);
    return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join('');
  }

  // Calculate the SHA256 hash of the input text.
  // Returns a promise that resolves to an ArrayBuffer
  public sha256(plain) {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest('SHA-256', data);
  }

  // Base64-urlencodes the input string
  public base64urlencode(str) {
    // Convert the ArrayBuffer to string using Uint8 array to conver to what btoa accepts.
    // btoa accepts chars only within ascii 0-255 and base64 encodes them.
    // Then convert the base64 encoded to base64url encoded
    //   (replace + with -, replace / with _, trim trailing =)
    return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }

  // Return the base64-urlencoded sha256 hash for the PKCE challenge
  public async pkceChallengeFromVerifier(v): Promise<string> {
    let hashed = await this.sha256(v);
    return Promise.resolve(this.base64urlencode(hashed));
  }

  public sendPostRequest(url, params, success, error) {
    var request = new XMLHttpRequest();
    request.open('POST', url, true);
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    request.onload = function () {
      var body = {};
      try {
        body = JSON.parse(request.response);
      } catch (e) {}

      if (request.status == 200) {
        success(request, body);
      } else {
        error(request, body);
      }
    };
  }

  public getUserInfo(accessToken: string) {
    this.initialize();
    let options = new HttpRequestOptions();
    options.Headers.push({ key: 'Content-Type', value: 'application/x-www-form-urlencoded' });
    options.Headers.push({ key: 'Authorization', value: 'Bearer ' + accessToken });
    options.Headers.push({ key: 'Cache-Control', value: 'no-cache' });
    options.Headers.push({ key: 'Pragma', value: 'no-cache' });

    return this._httpRequestService.get(this._endpoints.userInfoEndpoint, options);
  }

  public clearPkceLocalStorage() {
    this._localStorageService.removeItem(this._localStorage_pkce_redirecturl_name);
    this._localStorageService.removeItem(this._localStorage_pkce_code_verifier_name);
    this._localStorageService.removeItem(this._localStorage_pkce_state_name);
    this._localStorageService.removeItem(this._localStorage_pkce_nonce_name);
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-mctrade-eventsignup-assistant',
  templateUrl: './mctrade-eventsignup-assistant.component.html',
  styleUrl: './mctrade-eventsignup-assistant.component.css'
})
export class MctradeEventsignupAssistantComponent {

}

<p-tabView>
  <p-tabPanel header="My Dashboard">
    <div *ngFor="let url of embedUrls; let i = index">
      <div id="{{ 'my-dashboard-container-' + i.toString() }}"></div>
    </div>
  </p-tabPanel>
  
</p-tabView>




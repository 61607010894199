<h2>Settings</h2>
<div class="grid">
    <div class="col-3">
      <h3>Ingestion As Of Date </h3>
    </div>
    <div class="col-3">
        <p-calendar [(ngModel)]="settings.ingestionAsOfDate" [showIcon]="true"  ></p-calendar>
    </div>
    <div class="col-6">
       
    </div>
     
    <div class="col-3">
      
    </div>
    <div class="col-3">
      <p-button type="button" label="Save Settings" class="p-button-primary" (click)="saveSettings()"></p-button>
    </div>
    <div class="col-6">
  
    </div>
  </div>
<admin-auth></admin-auth>
<h3 class="w-full" style="text-align: center;">{{selectedTenant.tenantId}}</h3>

<p-tabView #tabView1>
  <p-tabPanel #tabPanel1>
    <ng-template pTemplate="header">
      <span i18n>Tenant</span>
    </ng-template>
    <div class="grid">

      <div class="col-3">
        <label i18n for="product">Product Id</label>
      </div>
      <div class="col-9">
        <p-dropdown id="product" [disabled]="true" class="w-full" [options]="products"
          [(ngModel)]="selectedTenant.productId"></p-dropdown>
      </div>

      <div class="col-3">
        <label i18n for="tenant">Tenant Id</label>
      </div>
      <div class="col-9">
        <input [disabled]="true" id="tenant" class="w-full" type="text" pInputText
          [(ngModel)]="selectedTenant.tenantId">
      </div>
      <div class="col-3">
        <label i18n for="displayName">Display Name</label>
      </div>
      <div class="col-9">
        <input id="displayName" class="w-full" type="text" pInputText [(ngModel)]="selectedTenant.displayName">
      </div>

      <div class="col-3">
        <label i18n for="enableMcTradeMenuItem">Enable Data Insights Menu Item in MC Trade</label>
      </div>
      <div class="col-9">
        <p-checkbox [(ngModel)]="mcTradeMenuItemValue" [binary]="true"></p-checkbox>
      </div>

      <!-- <div class="col-6">
            <label i18n for="adminEmail">Admin Email</label>
          </div>
          <div class="col-6">
            <input id="adminEmail" class="w-full" type="text" pInputText [(ngModel)]="selectedTenant.adminEmail">
          </div>
          <div class="col-6">
            <label i18n for="adminPhone">Admin Phone</label>
          </div>
          <div class="col-6">
            <input id="adminPhone" class="w-full" type="text" pInputText [(ngModel)]="selectedTenant.adminPhone">
          </div>
          <div class="col-6">
            <label i18n for="adminFirstName">Admin First Name</label>
          </div>
          <div class="col-6">
            <input id="adminFirstName" class="w-full" type="text" pInputText [(ngModel)]="selectedTenant.adminFirstName">
          </div>
          <div class="col-6">
            <label i18n for="adminLastName">Admin Last Name</label>
          </div>
          <div class="col-6">
            <input id="adminLastName" class="w-full" type="text" pInputText [(ngModel)]="selectedTenant.adminLastName">
          </div> -->
      <div class="col-3">
        <label i18n for="inactive">Inactive</label>
      </div>
      <div class="col-9">
        <p-checkbox name="inactive" [(ngModel)]="selectedTenant.inactive" [binary]="true"></p-checkbox>
      </div>
      <!-- <div class="col-6">
          <label [hidden]="isNewTenantRequest == false" i18n for="notifyEmail">Notify Email</label>
        </div>
        <div class="col-6">
          <input id="notifyEmail" [hidden]="isNewTenantRequest == false" class="w-full" type="text" pInputText
            [(ngModel)]="selectedTenant.notifyEmail">
        </div> -->

      <div class="col-12">
        <span>
          <p-button id="btnSave" i18n-label label="Save" (click)="upsertTenant()" icon="pi pi-check-circle"
            class="w-auto"></p-button>
        </span>
        <span style="margin-left: 5px;">
          <p-button id="btnCancel" i18n-label label="Cancel" (click)="cancel()" icon="pi pi-ban"
            class="w-auto"></p-button>
        </span>

      </div>

    </div>
  </p-tabPanel>
  <p-tabPanel #tabPanel2>
    <ng-template pTemplate="header">
      <span i18n>Custom Datasets</span>
    </ng-template>
    <admin-tenant-dataset></admin-tenant-dataset>
  </p-tabPanel>
  <p-tabPanel #tabPanel3>
    <ng-template pTemplate="header">
      <span i18n>Actions</span>
    </ng-template>
    <div class="grid">

      <div class="col-12" i18n>
        <b>Model Builder</b>
      </div>

      <div class="col-6" i18n>
        
      </div>
      <div class="col-6">
        <p-button id="btnGenerateMLModels" i18n-label label="Generate Machine Learning Models"
          (click)="generateMlModels(selectedTenant)" icon="pi pi-bolt" class="w-auto"></p-button>
      </div>


      <div class="col-12">
        <hr />
      </div>

      <div class="col-12" i18n>
        <b>Data Ingestion</b>
      </div>

      <div class="col-3" i18n>
        Start Date
      </div>
      <div class="col-3" i18n>
        End Date
      </div>
      <div class="col-6">

      </div>
      

      <div class="col-3">
        <p-calendar [(ngModel)]="ingestDataStartDate" [minDate]="minDateValue" [maxDate]="maxDateValue"
          ></p-calendar>
      </div>
      <div class="col-3">
        <p-calendar [(ngModel)]="ingestDataEndDate" [minDate]="minDateValue" [maxDate]="maxDateValue"
          ></p-calendar>
      </div>
      <div class="col-6">
        <p-button id="btnIngestData" i18n-label label="Ingest Data" (click)="ingestMcTradeData()"
          icon="pi pi-cloud-download" class="w-auto"></p-button>
         
      </div>

      <div class="col-3">
        <div i18n>overwrite existing data</div>
      </div>
      <div class="col-3">
        <p-checkbox   [(ngModel)]="ingestionOverwriteAll" [binary]="true" ></p-checkbox>
      </div>
      
      <div class="col-6">
        <span i18n> (Last Ingestion Date {{lastIngestionDate| date: 'MM/dd/yyyy'}})</span>
      </div>

      <div class="col-3">
        <div i18n>exclude profiles</div>
      </div>
      <div class="col-3">
        <p-checkbox   [(ngModel)]="excludeProfiles" [binary]="true"></p-checkbox>
      </div>
      
      <div class="col-6">
        
      </div>

      <div class="col-3">
        <div i18n>exclude revenue</div>
      </div>
      <div class="col-3">
        <p-checkbox   [(ngModel)]="excludeRevenue" [binary]="true"></p-checkbox>
      </div>
      
      <div class="col-6">
         
      </div>

      <div class="col-3">
        <div i18n>exclude payments</div>
      </div>
      <div class="col-3">
        <p-checkbox   [(ngModel)]="excludePayments" [binary]="true"></p-checkbox>
      </div>
      
      <div class="col-6">
         
      </div>
      

      <div class="col-3">
        <div i18n>exclude event sign ups</div>
      </div>
      <div class="col-3">
        <p-checkbox   [(ngModel)]="excludeEventsignups" [binary]="true"></p-checkbox>
      </div>
      
      <div class="col-6">
         
      </div>

      <div class="col-3">
        <div i18n>exclude engagement</div>
      </div>
      <div class="col-3">
        <p-checkbox   [(ngModel)]="excludeEngagement" [binary]="true"></p-checkbox>
      </div>
      
      <div class="col-6">
         
      </div>

      <div class="col-3">
        <div i18n>exclude member activity</div>
      </div>
      <div class="col-3">
        <p-checkbox   [(ngModel)]="excludeMemberActivity" [binary]="true"></p-checkbox>
      </div>
      
      <div class="col-6">
         
      </div>

      


      

      <div class="col-12">
        <hr />
      </div>
    </div>

  </p-tabPanel>

  <p-tabPanel #tabPanel4>
    <ng-template pTemplate="header">
      <span i18n>Insights Builder</span>
    </ng-template>

    <insights-builder #insightsBuilderComponent [manualLoad]="true"></insights-builder>
  </p-tabPanel>

</p-tabView>
export class SearchResultDto<T> {
    public constructor() {
        this.orderBy = '';
        this.currentPageNumber = 1;
        this.pageSize = 10;
        this.totalCount = 0;
        this.totalPages = 0
        this.result = new Array<T>();
    }

    public currentPageNumber: number
    public totalCount: number
    public totalPages: number
    public pageSize: number
    public orderBy: string
    public result: Array<T>
}



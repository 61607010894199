
import { ModelFieldOptionsDto } from "./model-field-options.dto";

export class MctradeTenantSettingsDto {

    public constructor(){
        this.id = "";
        this.tenantId = "";
        this.productId = "";
        this.retentionModelExcludeCategoricalData = false;
        this.segmentationModelExcludeCategoricalData = false;
        this.retentionModelFieldOptions = new Array<ModelFieldOptionsDto>();
        this.segmentationModelFieldOptions = new Array<ModelFieldOptionsDto>();
        
    }
    id: string;
    tenantId: string;
    productId: string;
    ingestionAsOfDate: Date;
    retentionModelFieldOptions:Array<ModelFieldOptionsDto>;
    segmentationModelFieldOptions:Array<ModelFieldOptionsDto>;
    retentionModelExcludeCategoricalData: boolean;
    segmentationModelExcludeCategoricalData: boolean;
}
import { Component } from '@angular/core';

@Component({
  selector: 'app-mctrade-payment-assistant',
  templateUrl: './mctrade-payment-assistant.component.html',
  styleUrl: './mctrade-payment-assistant.component.css'
})
export class MctradePaymentAssistantComponent {

}

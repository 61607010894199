import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/shared/navigation/navigation.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { UtilService } from '../../../../shared/util/util.service';
import { AdminService } from '../../../../admin/shared/admin.service';
import { AuthService } from '../../../../auth/shared/auth.service'
import { UserSession } from 'src/app/auth/shared/user-session.model';
import { RabTenantDto } from '../../../../admin/shared/dto/rab-tenant-dto';
import { TenantDatasetDto } from '../../../../admin/shared/dto/tenant-dataset-dto';
import { NameValueNumberDto } from '../../../../shared/dto/name-value-number-dto';
@Component({
  selector: 'app-mctrade-custom-assistant',
  templateUrl: './mctrade-custom-assistant.component.html',
  styleUrl: './mctrade-custom-assistant.component.css'
})



export class MctradeCustomAssistantComponent implements OnInit {

  public customDatasets: Array<TenantDatasetDto> = new Array<TenantDatasetDto>();

  private _productId: string = "";
  private _tenantId: string = "";

  constructor(
    private _adminService: AdminService,
    private _navigationService: NavigationService,
    private _utilService: UtilService,
    private _authService: AuthService,
    private _notificationService: NotificationService
  ) {

  }

 ngOnInit() {

    this._tenantId = this._authService.getUserSession().tenantId;
    this._productId = this._authService.getUserSession().productId;

    

  }

 

}

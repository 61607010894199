<div class="grid">
    <div class="col-12">
        <label il18n>Ingest Data Going Back To: </label>
        <p-calendar [(ngModel)]="settings.ingestionAsOfDate" [minDate]="minDateValue" [maxDate]="maxDateValue" [readonlyInput]="true" ></p-calendar>
        <label il18n> (Date must be >= 1/1/2019) </label>
         
    </div>
   
    <div class="col-6">
        <h3 il18n>Retention Model Field Options</h3>
    </div>
    <div class="col-6">
        <h3 il18n>Segmentation Model Field Options</h3>
    </div>
    

     
    <div class="col-6">
        <p-table [value]="retentionFieldNames" styleClass="p-datatable-striped p-datatable-gridlines">
            <ng-template pTemplate="caption">
                <p-checkbox [(ngModel)]="settings.retentionModelExcludeCategoricalData" [binary]="true" (onChange)="retentionModelExcludeCategoricalDataCheckboxChanged()" il18n il18n-label
            label="Retention Model Exclude All Categorical Data"></p-checkbox>
    
            </ng-template>
            <ng-template pTemplate="header">

                <tr>
                    <th  i18n>Field Name (check fields to exclude from model)</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-vm>
                <tr>
                    <td><p-checkbox [disabled]="vm.hidden" [(ngModel)]="vm.selected" label="{{vm.item}}" [binary]="true" ></p-checkbox></td>
                </tr>
            </ng-template>

        </p-table>

    </div>
    <div class="col-6">
        <p-table [value]="segmentationFieldNames" styleClass="p-datatable-striped p-datatable-gridlines">
            <ng-template pTemplate="caption">
                <p-checkbox [(ngModel)]="settings.segmentationModelExcludeCategoricalData" [binary]="true" (onChange)="segmentationModelExcludeCategoricalDataCheckboxChanged()" il18n il18n-label
            label="Segmentation Model Exclude All Categorical Data"></p-checkbox>
    
            </ng-template>
            <ng-template pTemplate="header">

                <tr>
                    <th  i18n>Field Name (check fields to exclude from model)</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-vm>
                <tr>
                    <td><p-checkbox [disabled]="vm.hidden"  [(ngModel)]="vm.selected" label="{{vm.item}}" [binary]="true"></p-checkbox></td>
                </tr>
            </ng-template>

        </p-table>
    </div>

    <div class="col-12">
        <span><p-button styleClass="p-button-link" (click)="saveSettings()" i18n-label
            label="Save Settings"></p-button></span>
    </div>
    
</div>
import { UtilService } from './../../shared/util/util.service';
import { IdentityProviderService } from './../shared/identity-provider.service';
import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../shared/navigation/navigation.service';
import { AuthService } from '../shared/auth.service';


@Component({
  selector: 'app-post-login',
  templateUrl: './post-login.component.html',
  styleUrls: ['./post-login.component.less'],
})
export class PostLoginComponent implements OnInit {
  displayResponse: string;

  constructor(
    private _identityProviderService: IdentityProviderService,
    private _authService : AuthService,
    private _navigationService: NavigationService,
    private _utilService: UtilService
  ) {
    this._identityProviderService.initialize();
  }

  async ngOnInit() {
    if (this._identityProviderService.hasAuthorizationCodeInUrl() === true) {
      let code = this._identityProviderService.extractAuthorizationCodeFromUrl();
      this._identityProviderService.authorizationCodeFlowExchangeCodeForAccessToken(code).then(
        async (data: any) => {
          this.displayResponse = JSON.stringify(data);
          if (data.access_token) {

            var userSession = this._authService.getUserSession();

            userSession.accessToken = data.access_token;

            let decodedToken : any = this._authService.decodeToken(data.access_token);
            //let tenantsArray: Array<string> = decodedToken.psfy_tenants;
            userSession.userName = decodedToken.username;
            userSession.userId = decodedToken.sub;

            if (data.id_token) {
              let decodedIdToken : any = this._authService.decodeToken(data.id_token);
              userSession.userName = decodedIdToken["cognito:username"];
              userSession.userId = decodedIdToken["sub"];
               
             }

            this._authService.setAdminUserSession(userSession);

            var tenantValue = '';

            //if (this._utilService.isNotNullOrUndefined(decodedToken.psfy_products))
            //{
            //  if (Array.isArray(decodedToken.psfy_products) == false) {
            //    tenantValue = decodedToken.psfy_products.split("|")[1];
            //  }
            //  else {
            //    for (let tenant of decodedToken.psfy_products) {
            //      if (tenant.toLowerCase().includes('mc_trade') == true) {
            //        tenantValue = tenant;
            //        break; //use first found
            //      }
            //    }
            //  }
            //}
            //else if (this._utilService.isNotNullOrUndefined(decodedToken.psfy_tenants)) {
            //  if (Array.isArray(decodedToken.psfy_tenants) == false) {
            //    tenantValue = decodedToken.psfy_tenants.split("|")[1];
            //  }
            //  else {
            //    for (let tenant of decodedToken.psfy_tenants) {
            //      if (tenant.toLowerCase().includes('mc_trade') == true) {
            //        tenantValue = tenant.split("|")[1];
            //        break; //use first found
            //      }
            //    }
            //  }
            //}

            
           
            //this._tokenStorageService.setTenantId(tenantValue);
            
          }
          

          // clear out PKCE storage values since we can't re-use them after a successful exchange
          this._identityProviderService.clearPkceLocalStorage();
          this._navigationService.navigateToAdmin();
        },
        (err: any) => {
          this.displayResponse = JSON.stringify(err.message);
        }
      );
    }
  }
}

export class TenantDatasetDto {
    
        id: string;
        tenantId: string;
        productId: string;
        sql: string;
        name: string;
        descr: string;
        datasetType: number;
        dateChangedExpression: string;
        tranDateExpression: string
        changedBy: string;
        dateCreated: string;
        dateChanged: string;
        dataEntityNameResourceSafe: string;
        topicId: string;
    
}



export class RevenueItemTimeSeriesPredictionDto {
    public constructor() {
  
           this.revenueType = "";
           this.year = 0;
           this.month = 0;
           this.forecastedRevenue = new Array<number>();
           this.confidenceLowerBound = new Array<number>();
           this.confidenceUpperBound = new Array<number>();
    }
  
    
    public  revenueType : string
    public  year :  number
    public  month : number
    public  forecastedRevenue: Array<number>;
    public  confidenceLowerBound: Array<number>;
    public  confidenceUpperBound: Array<number>;
  }


import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { NotificationType, INotification } from './dto/notification.models';
 

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor() { }

  private notification$: Subject<INotification> = new BehaviorSubject(null);
  info(message: string, duration: number = null, showSpinner = false) {
    this.notify(message, NotificationType.Info, duration, showSpinner);
  }
  success(message: string, duration: number = null, showSpinner = false) {
    this.notify(message, NotificationType.Success, duration, showSpinner);
  }
  warning(message: string, duration: number = null, showSpinner = false) {
    this.notify(message, NotificationType.Warning, duration, showSpinner);
  }
  error(message: string, duration: number = null, showSpinner = false) {
    this.notify(message, NotificationType.Error, duration, showSpinner);
    
  }
  private notify(message: string, type: NotificationType, duration: number, showSpinner: boolean) {
    duration = !duration ? 3000 : duration;
    showSpinner = !showSpinner ? false : showSpinner;

    this.notification$.next({
      message: message,
      type: type,
      duration: duration,
      showSpinner: showSpinner,
    } as INotification);
  }
  get notification() {
    return this.notification$.asObservable();
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { LocalStorageService } from '../../shared/storage/local-storage.service';
import { InisghtsService } from '../shared/insights.service';
import { AuthService } from '../../auth/shared/auth.service';
import { LogService } from '../../shared/log/log.service';
import { UtilService } from '../../shared/util/util.service';
import { ActivityDto } from '../shared/dto/activity-dto';
import { McTradeService } from '../../products/mctrade/shared/mctrade.service';
import { IngestTenantDataDto } from '../shared/dto/ingest-tenant-data-dto';
import { SearchResultDto } from '../../shared/dto/search-result-dto';


@Component({
    selector: 'insights-activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {

    /* @Input() embeddingContext: EmbeddingContext;*/

    private _productId: string = "";
    private _tenantId: string = "";

    public activitySearchResult: SearchResultDto<ActivityDto> = new SearchResultDto<ActivityDto>();
    public ingestConfig: IngestTenantDataDto;
    public selectedDataEntity: string = "profile"
    public dataEntities: Array<string> = new Array<string>();

    constructor(private _insightsService: InisghtsService, private _mctradeService: McTradeService, private _logService: LogService, private _utilService: UtilService, private _authService: AuthService, private _localStorageService: LocalStorageService) {


    }

    ngOnInit() {

        this._tenantId = this._authService.getUserSession().tenantId;
        this._productId = this._authService.getUserSession().productId;
        this.ingestConfig = new IngestTenantDataDto();
        this.ingestConfig.productId = "";
        this.ingestConfig.tenantId = "";
        this.ingestConfig.startDate = new Date();
        this.ingestConfig.endDate = new Date();
        this.ingestConfig.overwriteAll = false;
        this.ingestConfig.excludeEntityNames = new Array<string>();

        this._insightsService.GetActivity(1).then((data: any) => {

            this.activitySearchResult = data;
        });

        this.dataEntities.push("profile");
        this.dataEntities.push("member");
        this.dataEntities.push("memberactivity");
        this.dataEntities.push("revenue");
        this.dataEntities.push("payment");
        this.dataEntities.push("eventsignup");
        this.dataEntities.push("eventattendee");
        this.dataEntities.push("engagement");
        this.dataEntities.push("contact");
        this.dataEntities.push("referral");
    }

    ingestData = (): void => {

        this.ingestConfig.excludeEntityNames = new Array<string>();
        for (let entity of this.dataEntities) {
            if (entity != this.selectedDataEntity) {
                this.ingestConfig.excludeEntityNames.push(entity);
            }
        }
        this._mctradeService.ingestTenantData(this.ingestConfig);
    }

    handlePaging = (pageData: any): void => {

        var foo = pageData.index;

    }

}
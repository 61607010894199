<div class="grid">
    <div class=col-6>
        <h3 i18n>Member Cluster Visualization</h3>
    </div>
    <div class=col-6>
        <h3 i18n>Member Data With Highest Cluster Impact </h3>
    </div>

    <div class=col-6>
        <div style="margin:5PX">
            <canvas id="scatterChart"></canvas>
        </div>

    </div>
    <div class=col-6>
        <p-table [value]="segmentationFeatureImportanceResults" styleClass="p-datatable-striped p-datatable-gridlines"
            responsiveLayout="scroll" [paginator]="true" [rows]="5">
            <ng-template pTemplate="header">

                <tr>
                    <th i18n>Feature Name</th>
                    <th><span style="float: right" i18n>Rank</span></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                <tr>
                    <td><span>{{item.featureName}}</span></td>
                    <td><span style="float: right">{{rowIndex + 1}}</span></td>
                </tr>
            </ng-template>

        </p-table>
    </div>


</div>


<div class="grid" style="width:100%">
    <p-table #dtSummary [value]="segmentationSavedSummaryResults" [columns]="summaryColumns"
        styleClass="p-datatable-striped p-datatable-gridlines" responsiveLayout="scroll">

        <ng-template pTemplate="caption">
            <span><p-button styleClass="p-button-link" (click)="dtSummary.exportCSV()" i18n-label
                    label="Download Data"></p-button></span>
            <!-- <span><p-button styleClass="p-button-link" (click)="exportSummaryToCsv()" i18n-label
                    label="Download Data"></p-button></span>
            <div class="flex">
                <button type="button" pButton pRipple icon="pi pi-file" (click)="dtSummary.exportCSV()" class="mr-2"
                    pTooltip="CSV" tooltipPosition="bottom"></button>
            </div> -->

        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th pSortableColumn="segmentId" i18n>Cluster Averages:</th>
                <th pSortableColumn="countOfMembers" i18n>count of members</th>
                <th *ngFor="let col of summaryColumnsDynamic" pSortableColumn="col.header">
                    {{col.header}}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-item>
            <tr>
                <td><span><p-button styleClass="p-button-link" (click)="viewSegmentationDetails(item.segmentId)"
                            i18n>{{'Cluster ' + item.segmentId.toString()}}</p-button></span></td>
                <td><span style="float: right">{{item.countOfMembers}}</span></td>
                <td *ngFor="let col of summaryColumnsDynamic">

                    <span *ngIf="col.number == true" style="float: right">{{rowData[col.field] | number :'1.1-1'}}
                    </span>
                    <span *ngIf="col.currency == true" style="float: right">{{rowData[col.field] | currency}} </span>
                    <span *ngIf="col.category == true" style="float: right">{{rowData[col.field]}} </span>

                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="summary">

        </ng-template>

        <!-- <ng-template pTemplate="caption">
            <span><p-button styleClass="p-button-link" (click)="exportSummaryToCsv()" i18n-label
                    label="Download Data"></p-button></span>

        </ng-template> 
        <ng-template pTemplate="header">

            <tr>
                <th pSortableColumn="segmentId" i18n>Cluster Averages:</th>
                <th pSortableColumn="countOfMembers" i18n>count of members</th>
                <th pSortableColumn="memberage" i18n>avg member age</th>
                <th pSortableColumn="predictedLabel" i18n>most predicted to re-join</th>
                <th pSortableColumn="countofeventsignups" i18n>count of event signups</th>
                <th pSortableColumn="countofeventattendees" i18n>count of event attendees</th>
                <th pSortableColumn="countofcontacts" i18n>count of contacts</th>
                <th pSortableColumn="countofinvoices" i18n>count of invoices</th>
                <th pSortableColumn="countofpayments" i18n>count of payments</th>
                <th pSortableColumn="sumofinvoices" i18n>sum of invoices</th>
                <th pSortableColumn="sumofpayments" i18n>sum of payments</th>
                <th pSortableColumn="sumofamountdue" i18n>sum of amount due</th>
                <th pSortableColumn="countofeventsignupsinpastyear" i18n>count of eventsignups in past year</th>
                <th pSortableColumn="countofeventsponsorships" i18n>count of event sponsorships</th>
                <th pSortableColumn="countofcopymecontacts" i18n>count of copyme contacts</th>
                <th pSortableColumn="countofemailsread" i18n>count of emails read</th>
                <th pSortableColumn="countofinvoicesdues" i18n>count of invoices dues</th>
                <th pSortableColumn="sumofinvoicesdues" i18n>sum of invoices dues</th>
                <th pSortableColumn="countofinvoicesnondues" i18n>count of invoices non dues</th>
                <th pSortableColumn="sumofinvoicesnondues" i18n>sum of invoices non dues</th>
                <th pSortableColumn="sumofpaymentscreditcard" i18n>count of payments credit card</th>
                <th pSortableColumn="sumofpaymentsach" i18n>count of payments ach</th>
                <th pSortableColumn="sumofpaymentsnotcreditcardorach" i18n>count of payments not credit card or ach</th>
                <th pSortableColumn="avgdaystopayinvoicesdues" i18n>avg days to pay invoices dues</th>
                <th pSortableColumn="invoicesoutstandingdues" i18n>invoices outstanding dues</th>
                <th pSortableColumn="invoicesoutstandingnondues" i18n>invoices outstanding non dues</th>
                <th pSortableColumn="countofportalloginspastyear" i18n>count of portal logins past year</th>
                <th pSortableColumn="countofportalloginsalltime" i18n>count of portal logins all time</th>
                <th pSortableColumn="benefitsused" i18n>benefits used</th>
                <th pSortableColumn="relatedindividuals" i18n>related individuals</th>
                <th pSortableColumn="relatedorganizations" i18n>related organizations</th>
                <th pSortableColumn="annualdues" i18n>annual dues</th>
                <th pSortableColumn="employeesft" i18n>employees ft</th>
                <th pSortableColumn="employeespt" i18n>employees pt</th>
            </tr>
        </ng-template>


        <ng-template pTemplate="body" let-item>
            <tr>
                <td><span><p-button styleClass="p-button-link" (click)="viewSegmentationDetails(item.segmentId)"
                            i18n>{{'Cluster ' + item.segmentId.toString()}}</p-button></span></td>
                 
                <td><span style="float: right">{{item.countOfMembers}}</span></td>
                <td><span style="float: right">{{item.memberage| number : '1.1-1'}}</span></td>
                <td><span style="float: right">{{item.predictedLabel}}</span></td>
                <td><span style="float: right">{{item.countofeventsignups| number : '1.1-1'}}</span></td>
                <td><span style="float: right">{{item.countofeventattendees| number : '1.1-1'}}</span></td>
                <td><span style="float: right">{{item.countofcontacts| number : '1.1-1'}}</span></td>
                <td><span style="float: right">{{item.countofinvoices| number : '1.1-1'}}</span></td>
                <td><span style="float: right">{{item.countofpayments| number : '1.1-1'}}</span></td>
                <td><span style="float: right">{{item.sumofinvoices| currency}}</span></td>
                <td><span style="float: right">{{item.sumofpayments| currency}}</span></td>
                <td><span style="float: right">{{item.sumofamountdue| currency}}</span></td>
                <td><span style="float: right">{{item.countofeventsignupsinpastyear| number : '1.1-1'}}</span></td>
                <td><span style="float: right">{{item.countofeventsponsorships| number : '1.1-1'}}</span></td>
                <td><span style="float: right">{{item.countofcopymecontacts| number : '1.1-1'}}</span></td>
                <td><span style="float: right">{{item.countofemailsread| number : '1.1-1'}}</span></td>
                <td><span style="float: right">{{item.countofinvoicesdues| number : '1.1-1'}}</span></td>
                <td><span style="float: right">{{item.sumofinvoicesdues| currency}}</span></td>
                <td><span style="float: right">{{item.countofinvoicesnondues| number : '1.1-1'}}</span></td>
                <td><span style="float: right">{{item.sumofinvoicesnondues| currency}}</span></td>
                <td><span style="float: right">{{item.countofpaymentscreditcard| currency}}</span></td>
                <td><span style="float: right">{{item.countofpaymentsach| currency}}</span></td>
                <td><span style="float: right">{{item.countofpaymentsnotcreditcardorach| currency}}</span></td>
                <td><span style="float: right">{{item.avgdaystopayinvoicesdues| number : '1.1-1'}}</span></td>
                <td><span style="float: right">{{item.invoicesoutstandingdues| currency}}</span></td>
                <td><span style="float: right">{{item.invoicesoutstandingnondues| currency}}</span></td>
                <td><span style="float: right">{{item.countofportalloginspastyear| number : '1.1-1'}}</span></td>
                <td><span style="float: right">{{item.countofportalloginsalltime| number : '1.1-1'}}</span></td>
                <td><span style="float: right">{{item.benefitsused| number : '1.1-1'}}</span></td>
                <td><span style="float: right">{{item.relatedindividuals| number : '1.1-1'}}</span></td>
                <td><span style="float: right">{{item.relatedorganizations| number : '1.1-1'}}</span></td>
                <td><span style="float: right">{{item.annualdues| currency}}</span></td>
                <td><span style="float: right">{{item.employeesft| number : '1.1-1'}}</span></td>
                <td><span style="float: right">{{item.employeespt| number : '1.1-1'}}</span></td>

            </tr>
        </ng-template> 
        <ng-template pTemplate="summary">

        </ng-template>  -->
    </p-table>

</div>
<!-- <div class="grid" style="width:100%">
    <div class="col-12" i18n>
        <h3>Member Cluster Visualization</h3>
    </div>

    <div class="col-12">
        <canvas id="scatterChart"></canvas>
    </div>
</div> -->



<div class="grid" style="width:100%" *ngIf="showSegmentationDetails">
    <h3 i18n>Cluster {{selectedSegmentId}} Details</h3>
    <p-table #dtDetails [value]="segmentationDetailsFiltered" [columns]="detailColumns"
        styleClass="p-datatable-striped p-datatable-gridlines" responsiveLayout="scroll" [scrollable]="true"
        scrollHeight="600px">

        <ng-template pTemplate="caption">
            <span><p-button styleClass="p-button-link" (click)="dtDetails.exportCSV()" i18n-label
                    label="Download Data"></p-button></span>
            <!-- <span><p-button styleClass="p-button-link" (click)="exportSummaryToCsv()" i18n-label
                    label="Download Data"></p-button></span> -->

        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th pSortableColumn="name" i18n>name</th>
                <th pSortableColumn="predictedLabel" i18n>predicted outcome</th>
                <th pSortableColumn="probability" i18n>probability</th>
                <th *ngFor="let col of detailColumnsDynamic" pSortableColumn="col.header">
                    {{col.header}}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-item>
            <tr>
                <td><span>{{item.name}} ({{item.profileid}})</span></td>
                <td><span>{{item.descr }}</span></td>
                <td><span style="float: right">{{item.probability | percent: '0.2'}}</span></td>
                <td *ngFor="let col of detailColumnsDynamic">

                    {{rowData[col.field] }}

                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="summary">

        </ng-template>

        <!-- <ng-template pTemplate="caption">
            <span> <p-button styleClass="p-button-link" (click)="exportDetailsToCsv()" i18n-label
                    label="Download Data"></p-button></span>
        </ng-template>

        <ng-template pTemplate="header">

            <tr>
                <th pSortableColumn="name" i18n>name</th>
                <th pSortableColumn="membershiplevel" i18n>membership level</th>
                <th pSortableColumn="memberage" i18n>member age</th>
                <th pSortableColumn="predictedLabel" i18n>predicted outcome</th>
                <th pSortableColumn="probability" i18n>probability</th>
                <th pSortableColumn="countofeventsignups" i18n> count of event signups</th>
                <th pSortableColumn="countofeventattendees" i18n> count of event attendees</th>
                <th pSortableColumn="countofcontacts" i18n>count of contacts</th>
                <th pSortableColumn="countofinvoices" i18n>count of invoices</th>
                <th pSortableColumn="countofpayments" i18n>count of payments</th>
                <th pSortableColumn="sumofinvoices" i18n>sum of invoices</th>
                <th pSortableColumn="sumofpayments" i18n>sum of payments</th>
                <th pSortableColumn="sumofamountdue" i18n>sum of amountdue</th>
                <th pSortableColumn="countofeventsignupsinpastyear" i18n>count of eventsignups in past year</th>
                <th pSortableColumn="countofeventsponsorships" i18n>count of event sponsorships</th>
                <th pSortableColumn="countofcopymecontacts" i18n>count of copyme contacts</th>
                <th pSortableColumn="countofemailsread" i18n>count of emails read</th>
                <th pSortableColumn="countofinvoicesdues" i18n>count of invoices dues</th>
                <th pSortableColumn="sumofinvoicesdues" i18n>sum of invoices dues</th>
                <th pSortableColumn="countofinvoicesnondues" i18n>count of invoices non dues</th>
                <th pSortableColumn="sumofinvoicesnondues" i18n>sum of invoices non dues</th>
                <th pSortableColumn="countofpaymentscreditcard" i18n>count of payments credit card</th>
                <th pSortableColumn="countofpaymentsach" i18n>count of payments ach</th>
                <th pSortableColumn="countofpaymentsnotcreditcardorach" i18n>count of payments not credit card or ach
                </th>
                <th pSortableColumn="avgdaystopayinvoicesdues" i18n>avg days to pay invoices dues</th>
                <th pSortableColumn="invoicesoutstandingdues" i18n>invoices outstanding dues</th>
                <th pSortableColumn="invoicesoutstandingnondues" i18n>invoices outstanding non dues</th>
                <th pSortableColumn="countofportalloginspastyear" i18n>count of portal logins past year</th>
                <th pSortableColumn="countofportalloginsalltime" i18n>count of portal logins all time</th>
                <th pSortableColumn="benefitsused" i18n>benefits used</th>
                <th pSortableColumn="relatedindividuals" i18n>related individuals</th>
                <th pSortableColumn="relatedorganizations" i18n>related organizations</th>
                <th pSortableColumn="annualdues" i18n>annual dues</th>
                <th pSortableColumn="naicscode" i18n>naics code</th>
                <th pSortableColumn="siccode" i18n>sic code</th>
                <th pSortableColumn="primarylistingcategory" i18n>primary listing category</th>
                <th pSortableColumn="primarylistingsubcategory" i18n>primary listing sub category</th>
                <th pSortableColumn="website" i18n>website</th>
                <th pSortableColumn=">membersince" i18n>member since</th>
                <th pSortableColumn="membersource" i18n>member source</th>
                <th pSortableColumn="soldbyusername" i18n>soldby username</th>
                <th pSortableColumn="customers" i18n>customers</th>
                <th pSortableColumn="customerstatus" i18n>customer status</th>
                <th pSortableColumn="lastactivitytype" i18n>last activity type</th>
                <th pSortableColumn="lastactivitydate" i18n>last activity date</th>
                <th pSortableColumn="city" i18n>city</th>
                <th pSortableColumn="state" i18n>state</th>
                <th pSortableColumn="zip" i18n>zip</th>
                <th pSortableColumn="county" i18n>county</th>
                <th pSortableColumn="country" i18n>country</th>
                <th pSortableColumn="member" i18n>member</th>
                <th pSortableColumn="maincontactmember" i18n>main contact member</th>
                <th pSortableColumn="employeesft" i18n>employees ft</th>
                <th pSortableColumn="employeespt" i18n>employees pt</th>
                <th pSortableColumn="donotemail" i18n>do not email</th>

                <th pSortableColumn="activearbamount" i18n>active arb amount</th>
                <th pSortableColumn="totallifetimearbcount" i18n>lifetime arb count</th>
                <th pSortableColumn="committeeparticipation" i18n>committee participation</th>
                <th pSortableColumn="nonduesrevenueinlastyearamember" i18n>non dues revenue last year a member</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>

                <td><span>{{item.name}} ({{item.profileid}})</span></td>
                <td><span>{{item.membershiplevel}}</span></td>
                <td><span style="float: right">{{item.memberage| number : '1.1-1'}}</span></td>
                <td><span>{{item.descr }}</span></td>
                <td><span style="float: right">{{item.probability | percent: '0.2'}}</span></td>
                <td><span style="float: right">{{item.countofeventsignups}}</span></td>
                <td><span style="float: right">{{item.countofeventattendees}}</span></td>
                <td><span style="float: right">{{item.countofcontacts}}</span></td>
                <td><span style="float: right">{{item.countofinvoices}}</span></td>
                <td><span style="float: right">{{item.countofpayments}}</span></td>
                <td><span style="float: right">{{item.sumofinvoices| currency}}</span></td>
                <td><span style="float: right">{{item.sumofpayments| currency}}</span></td>
                <td><span style="float: right">{{item.sumofamountdue| currency}}</span></td>
                <td><span style="float: right">{{item.countofeventsignupsinpastyear}}</span></td>
                <td><span style="float: right">{{item.countofeventsponsorships}}</span></td>
                <td><span style="float: right">{{item.countofcopymecontacts}}</span></td>
                <td><span style="float: right">{{item.countofemailsread}}</span></td>
                <td><span style="float: right">{{item.countofinvoicesdues}}</span></td>
                <td><span style="float: right">{{item.sumofinvoicesdues| currency}}</span></td>
                <td><span style="float: right">{{item.countofinvoicesnondues}}</span></td>
                <td><span style="float: right">{{item.sumofinvoicesnondues| currency}}</span></td>
                <td><span style="float: right">{{item.countofpaymentscreditcard| currency}}</span></td>
                <td><span style="float: right">{{item.countofpaymentsach| currency}}</span></td>
                <td><span style="float: right">{{item.countofpaymentsnotcreditcardorach| currency}}</span></td>
                <td><span style="float: right">{{item.avgdaystopayinvoicesdues}}</span></td>
                <td><span style="float: right">{{item.invoicesoutstandingdues| currency}}</span></td>
                <td><span style="float: right">{{item.invoicesoutstandingnondues| currency}}</span></td>
                <td><span style="float: right">{{item.countofportalloginspastyear}}</span></td>
                <td><span style="float: right">{{item.countofportalloginsalltime}}</span></td>
                <td><span style="float: right">{{item.benefitsused}}</span></td>
                <td><span style="float: right">{{item.relatedindividuals}}</span></td>
                <td><span style="float: right">{{item.relatedorganizations}}</span></td>
                <td><span style="float: right">{{item.annualdues| currency}}</span></td>
                <td><span>{{item.naicscode}}</span></td>
                <td><span>{{item.siccode}}</span></td>
                <td><span>{{item.primarylistingcategory}}</span></td>
                <td><span>{{item.primarylistingsubcategory}}</span></td>
                <td><span>{{item.website}}</span></td>
                <td><span>{{item.membersince | date:'MM/dd/yyyy'}}</span></td>
                <td><span>{{item.membersource}}</span></td>
                <td><span>{{item.soldbyusername}}</span></td>
                <td><span style="float: right">{{item.customers}}</span></td>
                <td><span>{{item.customerstatus}}</span></td>
                <td><span>{{item.lastactivitytype}}</span></td>
                <td><span>{{item.lastactivitydate| date :'MM/dd/yyyy'}}</span></td>
                <td><span>{{item.city}}</span></td>
                <td><span>{{item.state}}</span></td>
                <td><span>{{item.zip}}</span></td>
                <td><span>{{item.county}}</span></td>
                <td><span>{{item.country}}</span></td>
                <td><span>{{item.member}}</span></td>
                <td><span>{{item.maincontactmember}}</span></td>
                <td><span style="float: right">{{item.employeesft}}</span></td>
                <td><span style="float: right">{{item.employeespt}}</span></td>
                <td><span>{{item.donotemail}}</span></td>
                <td><span style="float: right">{{item.activearbamount| currency}}</span></td>
                <td><span style="float: right">{{item.totallifetimearbcount}}</span></td>
                <td><span style="float: right">{{item.committeeparticipation}}</span></td>
                <td><span style="float: right">{{item.nonduesrevenueinlastyearamember| currency}}</span></td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">

        </ng-template> -->
    </p-table>
</div>
import { Injectable } from '@angular/core';
import { ApiService } from '../../shared/api/api.service';
import { DemographicsDto } from './dto/demographics-dto';
import { BenchmarkDto } from './dto/benchmark-dto';
import { MemberActivityDto } from './dto/member-activity-dto';
import { RevenueDto } from './dto/revenue-dto';
import { createEmbeddingContext, DashboardContentOptions, FrameOptions, EmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { AuthService } from '../../auth/shared/auth.service';
import { UtilService } from '../../shared/util/util.service';
import { LOCALE_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private _embeddingContext: EmbeddingContext;

  private _tenantId: string = "";
  private _productId: string = "";

  public constructor(private _apiService: ApiService, private _authService: AuthService, private _utilService:UtilService) {
   
   
    this._tenantId = this._authService.getUserSession().tenantId;
    this._productId = this._authService.getUserSession().productId.replace("_", "");

    this.intializeEmbeddingContext();

  }

  public getDashboardFrameOptions = (dashboardName: string, containerId: string, embedUrl: string): FrameOptions => {

    var frameOptions = {
      url: embedUrl, //'<YOUR_EMBED_URL>',
      container: '#' + containerId,
      withIframePlaceholder: true,
      //height: "700px",
      //width: "300px",
      resizeHeightOnSizeChangedEvent: true,
      //onChange: (changeEvent, metadata) => {
      //  switch (changeEvent.eventName) {
      //    case 'FRAME_MOUNTED': {
      //      console.log(dashboardName + " Dashboard - Do something when the experience frame is mounted.");
      //      break;
      //    }
      //    case 'FRAME_LOADED': {
      //      console.log(dashboardName + " Dashboard - Do something when the experience frame is loaded.");
      //      break;
      //    }
      //  }
      //},
    };

    return frameOptions;
  }

  public getDashboardContentOptions = (dashboardName: string): DashboardContentOptions => {


    var contentOptions = {
      locale: $localize.locale,    
      toolbarOptions: {
        export: true,
        undoRedo: true,
        reset: true
      },
      attributionOptions: {
        overlayContent: false,
      },
      onMessage: async (messageEvent, experienceMetadata) => {
        switch (messageEvent.eventName) {
          //case 'CONTENT_LOADED': {
          //  console.log(dashboardName + " Dashboard - All visuals are loaded. The title of the document:", messageEvent.message.title);
          //  break;
          //}
          case 'ERROR_OCCURRED': {
            console.log(dashboardName + " Dashboard - Error occurred while rendering the experience. Error code:", messageEvent.message.errorCode);
            break;
          }
          //case 'PARAMETERS_CHANGED': {
          //  console.log(dashboardName + " Dashboard - Parameters changed. Changed parameters:", messageEvent.message.changedParameters);
          //  break;
          //}
          //case 'SELECTED_SHEET_CHANGED': {
          //  console.log(dashboardName + " Dashboard - Selected sheet changed. Selected sheet:", messageEvent.message.selectedSheet);
          //  break;
          //}
          //case 'SIZE_CHANGED': {
          //  console.log(dashboardName + " Dashboard - Size changed. New dimensions:", messageEvent.message);
          //  break;
          //}
          case 'MODAL_OPENED': {
            window.scrollTo({
              top: 0 // iframe top position
            });
            break;
          }
        }
      },
    };

    return contentOptions;
  }

  
  public getDashboardEmbedUrl = (dashboardEntity: string): Promise<string> => {
    //must not cache so we don't get session reuse erors if users navigate back to the route within the cache timeframe
    //api/v1/rab/benchmarking/qs/dashboard/url/{dashboardName}/{tenantId}
    return this._apiService.getNoCache('api/v1/rab/data-analysis/' + this._productId + '/' + this._tenantId + '/qs/dashboard/' + dashboardEntity + '/url');

  };

  public getDashboardEmbedUrlByDashboardId = (dashboardId: string): Promise<string> => {
    //must not cache so we don't get session reuse erors if users navigate back to the route within the cache timeframe
    return this._apiService.getNoCache('api/v1/insights/' + this._productId + '/' + this._tenantId + '/data-analysis/qs/dashboard/id/' + dashboardId + '/url');
  }

  private intializeEmbeddingContext = async () => {

    this._embeddingContext = await createEmbeddingContext({
      //onChange: (changeEvent, metadata) => {
      //  //this._logService.info('Membership dashboard context received a change');
      //  console.log('Context received a change', changeEvent, metadata);
      //},
    });
  }
  public getEmbeddingContext = (): EmbeddingContext => {

    return this._embeddingContext;
  }

  public getWebConsoleEmbedUrl = (): Promise<string> => {

    //must not cache so we don't get session reuse erors if users navigate back to the route within the cache timeframe
    //topic/{topicId}/web-console/url
    return this.getWebConsoleEmbedUrlFromProductIdAndTenantId(this._productId, this._tenantId);


  };

  public getWebConsoleEmbedUrlFromProductIdAndTenantId = (productId: string, tenantId: string): Promise<string> => {

    //must not cache so we don't get session reuse erors if users navigate back to the route within the cache timeframe
    //topic/{topicId}/web-console/url
    return this._apiService.getNoCache('api/v1/insights/' + productId.replace("_", "") + '/' + tenantId + '/data-analysis/qs/web-console/url');


  };

  public getQSearchEmbedUrl = (): Promise<string> => {
    //must not cache so we don't get session reuse erors if users navigate back to the route within the cache timeframe
    //topic/{topicId}/web-console/url
    return this._apiService.getNoCache('api/v1/insights/' + this._productId + '/' + this._tenantId + '/data-analysis/qs/q-search/url');

  };

  public getQSearchEmbedUrlFromTopicType = (topicType: string): Promise<string> => {

    //api/v1/rab/data-analysis/qs/mc_trade/{tenantId}/q-search/topicType/{topicType}/url
    //must not cache so we don't get session reuse erors if users navigate back to the route within the cache timeframe
    //topic/{topicId}/web-console/url
    return this._apiService.getNoCache('api/v1/insights/' + this._productId + '/' + this._tenantId + '/data-analysis/qs/q-search/topicType/' + topicType + '/url');

  }

  public getQSearchEmbedUrlFromTopicId = (topicId: string): Promise<string> => {

    return this._apiService.getNoCache('api/v1/insights/' + this._productId + '/' + this._tenantId + '/data-analysis/qs/q-search/topic/' + topicId + '/url');

  }

  public GetTenantDashboardIds = (tenantId: string, dashboardType : string): Promise<Array<string>> => {

    var url = 'api/v1/insights/' + this._productId + '/' + this._tenantId + '/data-analysis/qs/dashboards/' + dashboardType + '/ids';
   
    return this._apiService.getNoCache(url);
   
  }

  
}

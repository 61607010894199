import { IdentityProviderService } from './../shared/identity-provider.service';
import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'admin-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
})
export class LoginComponent implements OnInit {
  isLoggedIn = false;
  dusplayResponse: string;
  constructor(private _identityProviderService: IdentityProviderService) {
    this._identityProviderService.initialize();
  }

  ngOnInit(): void {

    
    var returnHint = 'admin';    
    this._identityProviderService.authorizeCodeFlowRequestCode(returnHint);

  }
}

import { Injectable } from '@angular/core';
import { UtilService } from '../util/util.service';
import { PerishableDataDto } from './perishable-data.dto';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  public constructor(private _utilService: UtilService) {
    if (!localStorage) {
      throw new Error('local storage not supported.');
    }
  }

  public setObject(key: string, value: any): void {
    let jsonData: string = JSON.stringify(value);
    localStorage.setItem(key, jsonData);
  }

  public getObject(key: string, defaultValue = {}): any {
    let jsonData: string = localStorage.getItem(key);
    try {
      return JSON.parse(jsonData);
    } catch {
      return defaultValue;
    }
  }

  public setPerishableObject<T>(key: string, value: T, expires: Date): void {
    const perishableObject = { expires: expires, value: value } as PerishableDataDto<T>;
    this.setObject(key, perishableObject);
  }

  public getPerishableObject<T>(key: string): T {
    const perishableData = this.getObject(key) as PerishableDataDto<T>;
    let returnValue: T = null;
    if (new Date(perishableData?.expires) > new Date()) {
      returnValue = perishableData.value;
    }
    return returnValue;
  }

  public setString(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  public getString(key: string): string {
    return localStorage.getItem(key) || '';
  }

  public setDate(key: string, value: Date): void {
    let jsonData: string = JSON.stringify(value);
    localStorage.setItem(key, jsonData);
  }

  public getDate(key: string): Date {
    let jsonData: string = localStorage.getItem(key);
    let dte: Date = this._utilService.parseJsonDate(jsonData);
    return dte || new Date(1900, 1, 1);
  }

  public setNumber(key: string, value: number): void {
    localStorage.setItem(key, value.toString());
  }

  public getNumber(key: string): number {
    let stringValue: string = localStorage.getItem(key);
    let numberValue: number = parseFloat(stringValue);
    return numberValue || 0;
  }

  public setBoolean(key: string, value: boolean): void {
    localStorage.setItem(key, value.toString());
  }

  public getBoolean(key: string): boolean {
    let stringValue: string = localStorage.getItem(key);
    let boolValue: boolean = false;
    if (stringValue != null && stringValue != undefined) {
      if (stringValue.toLocaleLowerCase().trim() == 'true') {
        boolValue = true;
      }
    }

    return boolValue || false;
  }

  public clearAll(): void {
    localStorage.clear();
  }

  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  public hasItem(key: string): boolean {
    let item = localStorage.getItem(key);
    if (item) {
      return true;
    } else {
      return false;
    }
  }
}

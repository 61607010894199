import { Component, ElementRef, ViewChild } from '@angular/core';
import { LogService } from 'src/app/shared/log/log.service';
import { UtilService } from 'src/app/shared/util/util.service';
import { AuthService } from 'src/app/auth/shared/auth.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { InisghtsService } from 'src/app/insights/shared/insights.service';
import { FileUpload } from 'primeng/fileupload';
import { ApiService } from 'src/app/shared/api/api.service';
import { AIRequestDto } from '../../shared/dto/ai-request-dto';
import { AiRequestFileUploadUrlResponseDto } from '../../shared/dto/ai-request-file-upload-response-dto';
import { AiRequestFileUploadUrlDto } from '../../shared/dto/ai-request-file-upload-url-dto';
import { AIRequestFileContextDto } from '../../shared/dto/ai-request-file-context-dto';
import { NameValueNumberDto } from 'src/app/shared/dto/name-value-number-dto';
import { NameValueValueNumberDto } from 'src/app/shared/dto/name-value-value-number-dto';
import { NameValueValueValueNumberDto } from 'src/app/shared/dto/name-value-value-value-number-dto';
import { Dropdown } from 'primeng/dropdown';

@Component({
  selector: 'ai-request',
  templateUrl: './ai-request.component.html',
  styleUrl: './ai-request.component.css'
})
export class AiRequestComponent {


  private _productId: string = "";
  private _tenantId: string = "";
  private intervalId: NodeJS.Timeout;
  private _fileUploadUrlData: AiRequestFileUploadUrlResponseDto = new AiRequestFileUploadUrlResponseDto();


  public requestText: string = "";
  public responseText: string = "";
  public fileName: string = "";
  public uploadFile: File;
  public fileTextExtractionRunning: boolean = false;
  public fileTextExtractionComplete: boolean = false;
  public imageSource: string = "";
  public isImageRequest: boolean = false;
  public generationOptions: Array<NameValueNumberDto> = new Array<NameValueNumberDto>();
  public selectedGenerationOption: number = 0;
  public selectedImageSizeOption: NameValueValueValueNumberDto = <NameValueValueValueNumberDto>{ name: "1024 x 1024", value1: 0, value2: 1024, value3: 1024};
  public imageSizeOptions: Array<NameValueValueValueNumberDto> = new Array<NameValueValueValueNumberDto>();
  public imageTypeOptions: Array<NameValueNumberDto> = new Array<NameValueNumberDto>();
  public selectedImageTypeOption: NameValueNumberDto = <NameValueNumberDto>{ name: "photographic", value: 0 };

  public isWaitingForResponse: boolean = false;
  @ViewChild("fileUpload") fileUpload: FileUpload;
  @ViewChild('requestTextInput') requestTextInput: ElementRef;



  constructor(private _logService: LogService, private _utilService: UtilService, private _authService: AuthService, private _notificationService: NotificationService, private _insightsService: InisghtsService, private _apiService: ApiService) {


  }
  ngOnInit() {

    this.generationOptions.push(<NameValueNumberDto>{ name: "Ask a question or request a task", value: 0 });
    this.generationOptions.push(<NameValueNumberDto>{ name: "Ask a question about an uploaded file", value: 1 });
    //remove for v1
    //this.generationOptions.push(<NameValueNumberDto>{ name: "Generate an image", value: 2 });


    //The value must be one of 1024x1024, 1152x896, 1216x832, 1344x768, 1536x640, 640x1536, 768x1344, 832x1216, 896x1152.
    this.imageSizeOptions.push(<NameValueValueValueNumberDto>{ name: "1024 x 1024", value1: 0, value2: 1024, value3: 1024});
    this.imageSizeOptions.push(<NameValueValueValueNumberDto>{ name: "1152 x 896", value1: 1, value2: 1152, value3: 896});
    this.imageSizeOptions.push(<NameValueValueValueNumberDto>{ name: "1216 x 832", value1: 2, value2: 1216, value3: 832});
    this.imageSizeOptions.push(<NameValueValueValueNumberDto>{ name: "1344 x 768", value1: 3, value2: 1344, value3: 768});
    this.imageSizeOptions.push(<NameValueValueValueNumberDto>{ name: "1536 x 640", value1: 4, value2: 1536, value3: 640});
    this.imageSizeOptions.push(<NameValueValueValueNumberDto>{ name: "640 x 1536", value1: 5, value2: 640, value3: 1536});
    this.imageSizeOptions.push(<NameValueValueValueNumberDto>{ name: "768 x 1344", value1: 6, value2: 768, value3: 1344});
    this.imageSizeOptions.push(<NameValueValueValueNumberDto>{ name: "832 x 1216", value1: 7, value2: 832, value3: 1216});
    this.imageSizeOptions.push(<NameValueValueValueNumberDto>{ name: "896 x 1152", value1: 8, value2: 896, value3: 1152});

    //3d-model, analog-film, anime, cinematic, comic-book, digital-art, enhance, fantasy-art, isometric, line-art, low-poly, modeling-compound, neon-punk, origami, photographic, pixel-art, tile-texture.
    this.imageTypeOptions.push(<NameValueNumberDto>{ name: "photographic", value: 0,});
    this.imageTypeOptions.push(<NameValueNumberDto>{ name: "3d-model", value: 1 });
    this.imageTypeOptions.push(<NameValueNumberDto>{ name: "anime", value: 2 });
    this.imageTypeOptions.push(<NameValueNumberDto>{ name: "cinematic", value: 3 });
    this.imageTypeOptions.push(<NameValueNumberDto>{ name: "comic-book", value: 4 });
    this.imageTypeOptions.push(<NameValueNumberDto>{ name: "digital-art", value: 5 });
    this.imageTypeOptions.push(<NameValueNumberDto>{ name: "enhance", value: 6 });
    this.imageTypeOptions.push(<NameValueNumberDto>{ name: "fantasy-art", value: 7 });
    this.imageTypeOptions.push(<NameValueNumberDto>{ name: "isometric", value: 8 });
    this.imageTypeOptions.push(<NameValueNumberDto>{ name: "line-art", value: 9 });
    this.imageTypeOptions.push(<NameValueNumberDto>{ name: "low-poly", value: 10 });
    this.imageTypeOptions.push(<NameValueNumberDto>{ name: "modeling-compound", value: 11 });
    this.imageTypeOptions.push(<NameValueNumberDto>{ name: "neon-punk", value: 12 });
    this.imageTypeOptions.push(<NameValueNumberDto>{ name: "origami", value: 13 });
    this.imageTypeOptions.push(<NameValueNumberDto>{ name: "pixel-art", value: 14 });
    this.imageTypeOptions.push(<NameValueNumberDto>{ name: "tile-texture", value: 15 });





    this._tenantId = this._authService.getUserSession().tenantId;
    this._productId = this._authService.getUserSession().productId;

  }

  ngAfterViewInit() {
    this.requestTextInput.nativeElement.focus();
  }

  askClick() {
    this.responseText = "";

    //get self signed s3 file upload url
    //for backend work
    //https://docs.aws.amazon.com/AmazonS3/latest/userguide/example_s3_Scenario_PresignedUrl_section.html

    //texttract and bedrock ideas
    //https://aws.amazon.com/blogs/dotnet/document-bot-on-amazon-bedrock-with-net-windows-forms/

    //for file upload front end work
    //https://aws.amazon.com/blogs/developer/generate-presigned-url-modular-aws-sdk-javascript/


    if (this.requestText) {

      var aiRequest = new AIRequestDto();
      aiRequest.productId = this._productId;
      aiRequest.tenantId = this._tenantId;
      aiRequest.requestQuestion = this.requestText;
      aiRequest.s3KeyForTextContext = this._fileUploadUrlData.s3OutputKey; // this.output3Key;
      aiRequest.isImageGenerationRequest = this.isImageRequest;
      //height – (Optional) Height of the image to generate, in pixels, in an increment divible by 64.
      //The value must be one of 1024x1024, 1152x896, 1216x832, 1344x768, 1536x640, 640x1536, 768x1344, 832x1216, 896x1152.
      //width – (Optional)Width of the image to generate, in pixels, in an increment divible by 64.
      //The value must be one of 1024x1024, 1152x896, 1216x832, 1344x768, 1536x640, 640x1536, 768x1344, 832x1216, 896x1152.
      aiRequest.imageWidth = this.selectedImageSizeOption.value2;
      aiRequest.imageHeight = this.selectedImageSizeOption.value3;
      aiRequest.imageStylePreset = this.selectedImageTypeOption.name;
      this.isWaitingForResponse = true;
      this._insightsService.GetAiResponse(aiRequest).then((data: string) => {

        this.isWaitingForResponse = false;
        if (this.isImageRequest == true) {
          this.imageSource = data;
        } else {
          this.responseText = data;
        }
      }, () => {

        this.isWaitingForResponse = false;

      })
    }

  }

  onFileSelected(event) {

    this.uploadFile = event.target.files[0];
    this.isImageRequest = false;

    if (this.requestText == "") {
      this.requestText = "Summarize this document";
    }
    this.runTextExtraction();

  }

  runTextExtraction() {
    if (this.uploadFile) {

      this.fileTextExtractionRunning = true;
      this.fileName = this.uploadFile.name;


      this._insightsService.GetFileUploadUrlData(this.uploadFile).then((data: AiRequestFileUploadUrlResponseDto) => {
        this._fileUploadUrlData = data;


        this._insightsService.BeginFileWordExtraction(this._fileUploadUrlData).then((data: AiRequestFileUploadUrlResponseDto) => {
          //this just kicks it off
        });


        //poll to see when it is finished
        this.intervalId = setInterval(() => {
          var pollingPayload = new AIRequestFileContextDto();
          pollingPayload.productId = this._productId;
          pollingPayload.tenantId = this._tenantId;
          pollingPayload.s3Key = this._fileUploadUrlData.s3Key; //the original file key not output key
          this._insightsService.AiRequestExtractFileWordsComplete(pollingPayload).then((data: boolean) => {

            this.fileTextExtractionComplete = data
            if (data == true) {

              this.fileTextExtractionRunning = false;
              clearInterval(this.intervalId);


            }
          }, (x) => {
            this.fileTextExtractionRunning = false;
            clearInterval(this.intervalId);
          });
        }, 3000);

      })







    }
  }

  copyTextClick() {
    navigator.clipboard.writeText(this.responseText);

  }

  imageSizeOptionsChanged(event) {

    //this.selectedImageSizeOption = this.imageSizeOptions[event];
  }

  imageTypeOptionsChanged(event) {

    //this.selectedImageTypeOption = this.imageTypeOptions[event];
  }

  generationOptionsChanged(event) {

    if (this.selectedGenerationOption == 2) {
      this.isImageRequest = true;
      if (this.requestText == "") {
        this.requestText = "Create an image of";
      }
    } else {
      this.isImageRequest = false;
    }

  }


}


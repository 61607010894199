import { UtilService } from './../shared/util/util.service';
import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../shared/navigation/navigation.service';
import { AuthService } from '../auth/shared/auth.service';


@Component({
  selector: 'management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.css'],
})
export class ManagementComponent implements OnInit {

 

  constructor(
    private _navigationService: NavigationService,
    private _authService: AuthService,
    private _utilService: UtilService
  ) {

  }

  ngOnInit() {

    

  }

 

}

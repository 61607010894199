export class ActivityDto {

    public constructor() {

        this.activityId = "";
        this.activityType = "";
        this.activitySubType = "";
        this.activityDate = new Date();
        this.tenantId = "";
        this.productId = "";
        this.result = "";
        this.descr = "";
        this.success = false;
        this.applyDate = new Date();
    }
    activityId: string;
    activityType: string;
    activitySubType: string;
    activityDate: Date;
    tenantId: string;
    productId: string;
    result: string;
    descr: string;
    success: boolean;
    applyDate: Date;
}
<p-tabView>
    <p-tabPanel >
      <ng-template pTemplate = "header">
        <i class="pi pi-user-minus" style="margin-right: 10px;"></i>
        <span i18n>Membership Retention Analysis</span>
    </ng-template>
        <mctrade-membership-predictive-ml (ngxVisibility)="membershipPredictiveVisibilityChanged($event)"></mctrade-membership-predictive-ml>
      </p-tabPanel>
      <p-tabPanel >
        <ng-template pTemplate = "header">
          <i class="pi pi-users" style="margin-right: 10px;"></i>
          <span i18n>Membership Segmentation</span>
      </ng-template>
        <mctrade-membership-segmentation-ml (ngxVisibility)="membershipSegmentationVisibilityChanged($event)"></mctrade-membership-segmentation-ml>
      </p-tabPanel>
    <p-tabPanel >
      <ng-template pTemplate = "header">
        <i class="pi pi-chart-line" style="margin-right: 10px;"></i>
        <span i18n>Revenue Forecasting</span>
    </ng-template>
      <mctrade-revenue-predictive-ml></mctrade-revenue-predictive-ml> 
    </p-tabPanel>
  </p-tabView>

import { Injectable } from '@angular/core';
import { LocalStorageService } from '../storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  private _verboseLogging: boolean = true;
 

  public constructor() {
    
  }

  public log = (title: string, entry: string): void => {
    if (this._verboseLogging == true) {
      console.log(title, entry);
    }
  };

  public info = (entry: string): void => {
    if (this._verboseLogging == true) {
      console.log(entry);
    }
  };

 
  public logObject = (obj: any, message?: any): void => {
    if (this._verboseLogging == true) {
      console.log(message, obj);
    }
  };

  public error = (entry: string): void => {
    console.error(entry);
  };

  public enableVerboseLogging = (): void => {
     
    this._verboseLogging = true;
  };

  public disableVerboseLogging = (): void => {
     
    this._verboseLogging = false;
  };

  public VerboseLogging = (): boolean => {
    return this._verboseLogging;
  };
}

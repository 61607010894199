<div class="grid">

    <admin-auth></admin-auth>
    
    <div class="col-12" i18n>
        <b>Api Invoker</b>
    </div>

    <div class="col-12" i18n>
        <input id="url" class="w-full" type="text" pInputText
        [(ngModel)]="apiUrl">
    </div>
    <div class="col-12">
        <p-button id="btnGenerateMLModels" i18n-label label="Invoke Api Url"
            (click)="invoke()" icon="pi pi-bolt" class="w-auto"></p-button>
    </div>


    <div class="col-12">
        <hr />
    </div>

    <div class="col-12">
        <pre
                style="width:100%; overflow-x:auto; white-space: pre-wrap; word-wrap: break-word">{{apiOutput}}
            </pre>
    </div>

</div>
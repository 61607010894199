export class CognitoJwt {

  public isValid: boolean;
  public iss: string;
  public auth_time: number;
  public exp: number;
  public iat: number;
  public sub: string;
  public version: number;
  public token_use: string;
  public scope: string;
  public client_id: string;
  public jti: string;
  public constructor() {
    this.isValid = false;
  }
}



export class IngestTenantDataDto {

    public constructor(){

        this.productId = "";
        this.tenantId = "";
        this.startDate = new Date();
        this.endDate = new Date();
        this.overwriteAll = false;
        this.excludeEntityNames = new Array<string>();
    
    }
    productId: string;
    tenantId: string;
    overwriteAll: boolean;
    startDate: Date;
    endDate: Date;
    excludeEntityNames:  Array<string>;
}
import { UtilService } from './../shared/util/util.service';
import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../shared/navigation/navigation.service';
import { AuthService } from '../auth/shared/auth.service';
import { createEmbeddingContext, DashboardContentOptions, FrameOptions } from 'amazon-quicksight-embedding-sdk';


@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {

  public embeddingContext: any;

  constructor(
    private _navigationService: NavigationService,
    private _authService: AuthService,
    private _utilService: UtilService
  ) {
    
  }

  ngOnInit() {

    this._navigationService.navigateToDashboard();
        
  }

  
 
}

import { UtilService } from './shared/util/util.service';
import { Component, OnInit } from '@angular/core';
import { NavigationService } from './shared/navigation/navigation.service';
import { LogService } from './shared/log/log.service';
import { url } from 'inspector';


@Component({
  selector: 'not-found',
  templateUrl: './notfound.component.html'
})
export class NotFoundComponent implements OnInit {

  public embeddingContext: any;

  constructor(
    private _navigationService: NavigationService,
    private _utilService: UtilService,
    private _logService: LogService
  ) {
    
  }

  ngOnInit() {

   this._logService.log("Not Found", window.location.href);
    
  }

}
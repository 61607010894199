<p-tabView>
    <p-tabPanel #tabPanel1>
        <ng-template pTemplate = "header">
            <!-- <i class="pi pi-users" style="margin-right: 10px;"></i> -->
            <img [hidden]="tabPanel1.selected == false" src="/assets/images/db_membership_on.svg" />
            <img [hidden]="tabPanel1.selected == true" src="/assets/images/db_membership.svg" />
            <span i18n>Membership Dashboard</span>
        </ng-template>
        <mctrade-membership-dashboard></mctrade-membership-dashboard>
    </p-tabPanel>
    <p-tabPanel #tabPanel2 >
        <ng-template pTemplate = "header">
            <!-- <i class="pi pi-dollar" style="margin-right: 10px;"></i> -->
            <img [hidden]="tabPanel2.selected == false" src="/assets/images/db_revenue_on.svg" />
            <img [hidden]="tabPanel2.selected == true" src="/assets/images/db_revenue.svg" />
            <span i18n>Revenue Dashboard</span>
        </ng-template>
        <mctrade-revenue-dashboard></mctrade-revenue-dashboard>
    </p-tabPanel>
    <p-tabPanel #tabPanel3 >
        <ng-template pTemplate = "header">
            <!-- <i class="pi pi-calendar" style="margin-right: 10px;"></i> -->
            <img [hidden]="tabPanel3.selected == false" src="/assets/images/db_events_on.svg" />
            <img [hidden]="tabPanel3.selected == true" src="/assets/images/db_events.svg" />
            <span i18n>Events Dashboard</span>
        </ng-template>
        <mctrade-event-dashboard></mctrade-event-dashboard>
    </p-tabPanel>
</p-tabView>
<div class="container">
  <header>

    <!-- <div class="grid" style="width:100%">
      <div class="col-4">
        <div style="float:left;padding-left:2.21px">
          <img style="height: 58px; width:auto" src="assets/images/data_insights_logo_on_white.png" />
        </div>
      </div>
      <div class="col-4">
        <div text-center>
          <strong>{{currentUserSession.displayName | uppercase}} </strong>

        </div>
      </div>
      <div class="col-4">
        <div style="float: right;padding-right: 12.21px">
          <button pButton type="button" label="Logout" class="p-button-link" (click)="logout()"></button>
        </div>
      </div>

    </div> -->

    <div class="grid" style="width:100%">
      <div class="col-4">
        <div class="text-left">
          <div class="m-2">
            <img style="height: 58px; width:auto" src="assets/images/data_insights_logo_on_white.png" />
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="text-center">
          <div class="m-5">
            <span class="font-bold " *ngIf="hideNavigation == false"> {{currentUserSession.displayName | uppercase}}</span>
            <span class="font-bold " *ngIf="hideNavigation == true"> {{'Admin' | uppercase}}</span>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="text-right">
          <div class="m-5">
            <span>
              <button pButton type="button" i18n-label label="Logout" class="p-button-text" (click)="logout()"></button>
              <!-- <button pButton type="button" i18n-title title="Settings" label=" " icon="pi pi-cog" iconPos="right"
                class="p-button-text" (click)="navigateToSettings()"></button> -->

            </span>


          </div>
        </div>

      </div>
    </div>


  </header>

  <main role="main">

    <div class="workspace-container">
      <router-outlet></router-outlet>
    </div>


    <nav class="left-nav-container" >

      <div class="grid" style="row-gap: 10px;" *ngIf="hideNavigation == false">

        <div class="col-1">
          <!-- empty -->
        </div>
        <div class="col-2" (click)="navSelectDashboard()">
          <img [class.nav-item-not-selected-image-dashboard]="NavDashboardSelected == false"
            [class.nav-item-selected-image-dashboard]="NavDashboardSelected == true" alt="Dashboards"
            style="margin-right: 6px" />
        </div>
        <div class="col-9" (click)="navSelectDashboard()">
          <span [class.nav-item-not-selected-text]="NavDashboardSelected == false"
            [class.nav-item-selected-text]="NavDashboardSelected == true" i18n>
            Dashboards
          </span>
        </div>

        <div class="col-1">
          <!-- empty -->
        </div>
        <div class="col-2" (click)="navSelectAiDataAnalyst()">
          <img [class.nav-item-not-selected-image-data-analyst]="NavAiDataAnalystSelected == false"
            [class.nav-item-selected-image-data-analyst]="NavAiDataAnalystSelected == true" alt="AI Data Analyst"
            style="margin-right: 6px" />
        </div>
        <div class="col-9" (click)="navSelectAiDataAnalyst()">
          <span [class.nav-item-not-selected-text]="NavAiDataAnalystSelected == false"
            [class.nav-item-selected-text]="NavAiDataAnalystSelected == true" i18n>
            AI Data Analyst
          </span>
        </div>


        <div class="col-1">
          <!-- empty -->
        </div>
        <div class="col-2" (click)="navSelectPredictiveAnalysis()">
          <img [class.nav-item-not-selected-image-predictive-analysis]="NavPredictiveAnalysisSelected == false"
            [class.nav-item-selected-image-predictive-analysis]="NavPredictiveAnalysisSelected == true"
            alt="Predictive Analysis" style="margin-right: 6px" />
        </div>
        <div class="col-9" (click)="navSelectPredictiveAnalysis()">
          <span [class.nav-item-not-selected-text]="NavPredictiveAnalysisSelected == false"
            [class.nav-item-selected-text]="NavPredictiveAnalysisSelected == true" i18n>
            Predictive Analysis
          </span>
        </div>



        <div class="col-1">
          <!-- empty -->
        </div>
        <div class="col-2" (click)="navSelectAiAssistant()">
          <img [class.nav-item-not-selected-image-ai]="NavAiAssistantSelected == false"
            [class.nav-item-selected-image-ai]="NavAiAssistantSelected == true" alt="Ai Assistant"
            style="margin-right: 6px" />
        </div>
        <div class="col-9" (click)="navSelectAiAssistant()">
          <span [class.nav-item-not-selected-text]="NavAiAssistantSelected == false"
            [class.nav-item-selected-text]="NavAiAssistantSelected == true" i18n>
            AI Assistant
          </span>
        </div>

        <div class="col-1">
          <!-- empty -->
        </div>
        <div class="col-2" (click)="navSelectSettings()">
          <img [class.nav-item-not-selected-image-settings]="NavSettingsSelected == false"
            [class.nav-item-selected-image-settings]="NavSettingsSelected == true" alt="Settings"
            style="margin-right: 6px" />
        </div>
        <div class="col-9" (click)="navSelectSettings()">
          <span [class.nav-item-not-selected-text]="NavSettingsSelected == false"
            [class.nav-item-selected-text]="NavSettingsSelected == true" i18n>
            Settings
          </span>
        </div>


        <div class="col-1" *ngIf="enableInsightsBuilder">
          <!-- empty -->
        </div>
        <div class="col-2" (click)="navSelectInsightsBuilder()" *ngIf="enableInsightsBuilder">
          <img [class.nav-item-not-selected-image-insights-builder]="NavInsightsBuilderSelected == false"
            [class.nav-item-selected-image-insights-builder]="NavInsightsBuilderSelected == true"
            alt="Predictive Analysis" style="margin-right: 6px" />
        </div>
        <div class="col-9" (click)="navSelectInsightsBuilder()" *ngIf="enableInsightsBuilder">
          <span [class.nav-item-not-selected-text]="NavInsightsBuilderSelected == false"
            [class.nav-item-selected-text]="NavInsightsBuilderSelected == true" i18n>
            Insights Builder
          </span>
        </div>



        


      </div>

      <!-- <div class="nav-item-container">


        <button pButton type="button" i18n-label label="AI Assistant" class="p-button-link"
          (click)="navigateToQDashboard()">
          <img alt="Ai Assistant" src="assets/images/assistant.svg" style="margin-right: 6px" />
        </button>

        <div class="nav-item-on" i18n>
          <img alt="Ai Assistant" src="assets/images/assistant.svg" style="margin-right: 6px" />
          AI Assistant
        </div>

      </div>

      <div class="nav-item-container">


        <button pButton type="button" i18n-label label="Membership" class="p-button-link"
          (click)="navigateToMembershipDashboard()">
          <img alt="Dashboard" src="assets/images/membership.svg" style="margin-right: 6px" />
        </button>

        <div class="nav-item-off" i18n>
          <img alt="Dashboards" src="assets/images/dashboard.svg" style="margin-right: 6px" />
          Dashboards
        </div>

      </div>
      <div class="nav-item-container">


        <button pButton type="button" i18n-label label="Revenue" class="p-button-link"
          (click)="navigateToRevenueDashboard()">
          <img alt="Dashboard" src="assets/images/revenue.svg" style="margin-right: 6px" />
        </button>

        <div class="nav-item-off" i18n>
          <img alt="Predictive Analysis" src="assets/images/insights.svg" style="margin-right: 6px" />
          Predictive Analysis
        </div>


      </div> -->



      <!-- <div class="nav-item-container">


        <button pButton type="button" i18n-label label="Events" class="p-button-link"
          (click)="navigateToEventsDashboard()">
          <img alt="Dashboard" src="assets/images/events.svg" style="margin-right: 6px" />
        </button>

      </div> -->

      <!-- <div class="nav-item-container">

        <button pButton type="button" label="My Dashboard" class="p-button-link" (click)="navigateToMyDashboard()">
          <img alt="Dashboard" src="assets/images/my-dashboard.svg" style="margin-right: 6px" />
        </button>

      </div> -->


      <!-- <div class="nav-item-container ">

        <button pButton type="button" label="Insights Builder" class="p-button-link"
          (click)="navigateToInsightsBuilder()">
          <img alt="Insights Builder" src="assets/images/insights-builder.svg" style="margin-right: 6px" />
        </button>

      </div> -->

    </nav>

  </main>

  <footer>
    <div class="footer-item-container">

      <!-- <div class="progress-spinner" *ngIf="routerIsLoading || showNotificationSpinner"> -->
      <div class="progress-spinner" *ngIf="showNotificationSpinner">
        <p-progressSpinner></p-progressSpinner>
      </div>
      <div class="one-line-div">
        <!-- <p-progressBar mode="indeterminate" *ngIf="notificationMessage != ''"  [style]="{ height: '4px', width: '190px' }"></p-progressBar>       -->
        <!-- <p-progressSpinner styleClass="w-1rem h-1rem" *ngIf="notificationMessage != ''"></p-progressSpinner> -->
        <span *ngIf="notificationMessage != ''"> {{notificationMessage}}</span>
        <span i18n style="float: right">Last Data Update {{AsOfDate | date :'MM/dd/yyyy'}}</span>

      </div>

      <!-- <div class="flex-row" *ngIf="notificationMessage != ''">
        <p-progressBar mode="indeterminate" *ngIf="notificationMessage != ''" ></p-progressBar>
        <i class="pi pi-bell"></i>
        {{notificationMessage}}
        Test
      </div> -->

    </div>
  </footer>
</div>
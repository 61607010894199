export interface INotification {
  message: string,
  type: NotificationType,
  duration: number,
  showSpinner: boolean
}
export enum NotificationType {
  Success = 0,
  Warning = 1,
  Error = 2,
  Info = 3
}

export class RabTenantDto {
  public constructor() {
    this.tenantId = '';
    this.productId = '';
    this.adminEmail = '';
    this.adminPhone = '';
    this.adminFirstName = '';
    this.adminLastName = '';
    this.displayName = '';
    this.inactive = false;
    this.notifyEmail = '';
    this.changedBy = '';
  }

  tenantId: string;
  productId: string;
  adminEmail: string;
  adminPhone: string;
  adminFirstName: string;
  adminLastName: string;
  displayName: string;
  inactive: boolean;
  notifyEmail: string;
  changedBy: string;
}

import { KeyValue } from '@angular/common';

export class HttpRequestOptions {
  public constructor() {
    this.Url = '';
    this.EnableCaching = false;
    this.SlidingCache = false;
    this.CacheSeconds = 0;
    this.SlidingCacheSeconds = 0;
    this.Headers = new Array<KeyValue<any, any>>();
    this.DeleteCache = false;
  }
  public Url: string;
  public EnableCaching: boolean;
  public SlidingCache: boolean;
  public CacheSeconds: number;
  public SlidingCacheSeconds: number;
  public DeleteCache: boolean;
  public Headers: Array<KeyValue<any, any>>;
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilService } from './../../shared/util/util.service';
import { AdminService } from './../shared/admin.service';

import { NavigationService } from '../../shared/navigation/navigation.service';
import { UserSession } from '../../auth/shared/user-session.model';
import { AuthService } from '../../auth/shared/auth.service';
import { NotificationService } from '../../shared/notification/notification.service';


@Component({
  selector: 'admin-auth',
  templateUrl: './admin-auth.component.html',
  styleUrl: './admin-auth.component.css'
})
export class AdminAuthComponent {

  public currentUserSession: UserSession = new UserSession

  constructor(
    private _adminService: AdminService,
    private _navigationService: NavigationService,
    private _utilService: UtilService,
    private _authService: AuthService,
    private _notificationService: NotificationService
  ) {

  }


  ngOnInit() {


    this.currentUserSession = this._authService.getUserSession();
    if (this.currentUserSession.adminUser == true && this._authService.tokenIsExpired(this.currentUserSession.accessToken) == false) {
      //do nothing 
    } else {
      this._navigationService.navigateToAdminLogin();
    }


  }

  

}

export class CachedHttpRequest {
  public Url: string;
  public InProgress: boolean;
  public Completed: boolean;
  public EnableCaching: boolean;
  public SlidingCache: boolean;
  public ExpirationDate: Date;
  public SlidingCacheSeconds: number;
  public PrimaryRequest: boolean;
  public ResponsePromise?: Promise<any>;
  public ResponseData?: any;

  public constructor() {
    this.Url = '';
    this.InProgress = false;
    this.Completed = false;
    this.EnableCaching = false;
    this.SlidingCache = false;
    this.ExpirationDate = new Date();
    this.SlidingCacheSeconds = 0;
    this.PrimaryRequest = false;
  }
}

<div
    style="margin-top: 15px; align-self: stretch; height: 349px; padding-left: 12px; padding-right: 12px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 42px; display: flex">
    <div
        style="width: 100%; height: 100%; padding-left: 2px; padding-right: 10px; justify-content: flex-start; align-items: center; gap: 10px; display: inline-flex">
        <div style="width: 24px; height: 24px; position: relative">
            <div style="width: 24px; height: 24px; left: 0px; top: 0px; position: absolute"></div>
            <div style="width: 22px; height: 22px; left: 1px; top: 1px; position: absolute"><img
                    src="assets/images/query-stats-on.svg" /></div>
        </div>
        <div style="color: #10364D; font-size: 15px; font-family: Roboto; font-weight: 700; letter-spacing: 0.15px; word-wrap: break-word"
            i18n>AI Data Analyst</div>
    </div>
    <div style="align-self: stretch; color: #495057; font-size: 16px; font-family: Roboto; font-weight: 700; word-wrap: break-word"
        i18n>
        Select the type of data you would like the AI Data Analyst to use</div>
    <div
        style="align-self: stretch; height: 288px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 12px; display: flex">
        <div
            style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 12px; display: inline-flex">
            <div [routerLink]="'/mctrade/ai-data-analyst/profile'"
                style="cursor: pointer; flex: 1 1 0; align-self: stretch; padding: 24px; background: white; border-radius: 8px; border: 1px #E6E6E6 solid; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 12px; display: inline-flex">
                <div style="justify-content: flex-start; align-items: center; gap: 12px; display: inline-flex">
                    <div style="width: 40px; height: 40px; position: relative">
                        <div
                            style="width: 40px; height: 40px; left: 0px; top: 0px; position: absolute; background: #D9D9D9">
                            <img src="assets/images/id_card.svg" />
                        </div>

                    </div>
                    <div style="color: #495057; font-size: 16px; font-family: Roboto; font-weight: 700; word-wrap: break-word"
                        i18n>Profiles</div>
                </div>
                <div style="align-self: stretch; color: #495057; font-size: 16px; font-family: Roboto; font-weight: 400; word-wrap: break-word"
                    i18n>Ask about membership and profile data, including status, member since data, membership level,
                    primary listing category, and geographic information.</div>
            </div>
            <div [routerLink]="'/mctrade/ai-data-analyst/revenue'"
                style="cursor: pointer; flex: 1 1 0; align-self: stretch; padding: 24px; background: white; border-radius: 8px; border: 1px #E6E6E6 solid; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 12px; display: inline-flex">
                <div style="justify-content: flex-start; align-items: center; gap: 12px; display: inline-flex">
                    <div style="width: 40px; height: 40px; position: relative">
                        <div
                            style="width: 40px; height: 40px; left: 0px; top: 0px; position: absolute; background: #D9D9D9">
                            <img src="assets/images/price_change.svg" />
                        </div>

                    </div>
                    <div style="color: #495057; font-size: 16px; font-family: Roboto; font-weight: 700; word-wrap: break-word"
                        i18n>Revenue</div>
                </div>
                <div style="align-self: stretch; color: #495057; font-size: 16px; font-family: Roboto; font-weight: 400; word-wrap: break-word"
                    i18n>Ask about invoice-related financial data, including any open or paid invoices.</div>
            </div>
        </div>
        <div
            style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 12px; display: inline-flex">
            <div [routerLink]="'/mctrade/ai-data-analyst/payment'"
                style="cursor: pointer; flex: 1 1 0; align-self: stretch; padding: 24px; background: white; border-radius: 8px; border: 1px #E6E6E6 solid; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 12px; display: inline-flex">
                <div style="justify-content: flex-start; align-items: center; gap: 12px; display: inline-flex">
                    <div style="width: 40px; height: 40px; position: relative">
                        <div
                            style="width: 40px; height: 40px; left: 0px; top: 0px; position: absolute; background: #D9D9D9">
                            <img src="assets/images/payments.svg" />
                        </div>

                    </div>
                    <div style="color: #495057; font-size: 16px; font-family: Roboto; font-weight: 700; word-wrap: break-word"
                        i18n>Payments</div>
                </div>
                <div style="align-self: stretch; color: #495057; font-size: 16px; font-family: Roboto; font-weight: 400; word-wrap: break-word"
                    i18n>Ask about financial data relating to payments, including revenue and invoice data on paid
                    invoices as well as payment details.</div>
            </div>
            <div [routerLink]="'/mctrade/ai-data-analyst/eventsignup'"
                style="cursor: pointer; flex: 1 1 0; align-self: stretch; padding: 24px; background: white; border-radius: 8px; border: 1px #E6E6E6 solid; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 12px; display: inline-flex">
                <div style="justify-content: flex-start; align-items: center; gap: 12px; display: inline-flex">
                    <div style="width: 40px; height: 40px; position: relative">
                        <div
                            style="width: 40px; height: 40px; left: 0px; top: 0px; position: absolute; background: #D9D9D9">
                            <img src="assets/images/local_activity.svg" />
                        </div>

                    </div>
                    <div style="color: #495057; font-size: 16px; font-family: Roboto; font-weight: 700; word-wrap: break-word"
                        i18n>Event registrations</div>
                </div>
                <div style="align-self: stretch; color: #495057; font-size: 16px; font-family: Roboto; font-weight: 400; word-wrap: break-word"
                    i18n>Ask about event sign-up data and related registration and event configuration data.</div>
            </div>
        </div>



    </div>


    <div class="grid" style="width:100%" *ngIf="customDatasetsColumn1.length > 0">
        <div style="align-self: stretch; color: #495057; font-size: 16px; font-family: Roboto; font-weight: 700; word-wrap: break-word"
        i18n>
        Custom Topics</div>
    </div>

    <div class="grid" style="width:100%" *ngIf="customDatasetsColumn1.length > 0">
        <div class="col-6"   >
            <div *ngFor="let customDataset of customDatasetsColumn1; let i = index"  style="width:100%; margin-top: 10px">
                <div [routerLink]="['/mctrade/ai-data-analyst/custom']" [queryParams]="{topicId: customDataset.topicId}"
                    style="width:100%; cursor: pointer; flex: 1 1 0; align-self: stretch; padding: 24px; background: white; border-radius: 8px; border: 1px #E6E6E6 solid; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 12px; display: inline-flex">
                    <div style="justify-content: flex-start; align-items: center; gap: 12px; display: inline-flex">
                        <!-- <div style="width: 40px; height: 40px; position: relative">
                        <div
                            style="width: 40px; height: 40px; left: 0px; top: 0px; position: absolute; background: #D9D9D9">
                            <img src="assets/images/id_card.svg" />
                        </div>

                    </div> -->
                        <div style="color: #495057; font-size: 16px; font-family: Roboto; font-weight: 700; word-wrap: break-word"
                            i18n>{{customDataset.name}}</div>
                    </div>
                    <div style="align-self: stretch; color: #495057; font-size: 16px; font-family: Roboto; font-weight: 400; word-wrap: break-word"
                        i18n>{{customDataset.descr}}</div>
                </div>

            </div>
        </div>
        <div class="col-6" >
            <div *ngFor="let customDataset of customDatasetsColumn2; let i = index" style="width:100%; margin-top: 10px">
                <div [routerLink]="['/mctrade/ai-data-analyst/custom']" [queryParams]="{topicId: customDataset.topicId}"
                    style="width:100%; cursor: pointer; flex: 1 1 0; align-self: stretch; padding: 24px; background: white; border-radius: 8px; border: 1px #E6E6E6 solid; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 12px; display: inline-flex">
                    <div style="justify-content: flex-start; align-items: center; gap: 12px; display: inline-flex">
                        <!-- <div style="width: 40px; height: 40px; position: relative">
                        <div
                            style="width: 40px; height: 40px; left: 0px; top: 0px; position: absolute; background: #D9D9D9">
                            <img src="assets/images/id_card.svg" />
                        </div>

                    </div> -->
                        <div style="color: #495057; font-size: 16px; font-family: Roboto; font-weight: 700; word-wrap: break-word"
                            i18n>{{customDataset.name}}</div>
                    </div>
                    <div style="align-self: stretch; color: #495057; font-size: 16px; font-family: Roboto; font-weight: 400; word-wrap: break-word"
                        i18n>{{customDataset.descr}}</div>
                </div>

            </div>
        </div>


    </div>


</div>
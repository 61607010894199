import { UtilService } from '../../../shared/util/util.service';
import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../shared/navigation/navigation.service';
import { AuthService } from '../../../auth/shared/auth.service';
import { McTradeService } from '../shared/mctrade.service';
import { MctradeTenantSettingsDto } from '../shared/dto/mctrade-tenant-settings-dto';


@Component({
  selector: 'mctrade-management',
  templateUrl: './mctrade-management.component.html',
  styleUrls: ['./mctrade-management.component.css'],
})
export class MctradeManagementComponent implements OnInit {

 public settings : MctradeTenantSettingsDto = new MctradeTenantSettingsDto();

  constructor(
    private _navigationService: NavigationService,
    private _authService: AuthService,
    private _utilService: UtilService,
    private _mctradeService: McTradeService
  ) {

  }

  ngOnInit() {

    this._mctradeService.GetTenantSettings().then((data:MctradeTenantSettingsDto) =>{

        this.settings = data;
        this.settings.ingestionAsOfDate = new Date(data.ingestionAsOfDate);
    });

  }

  saveSettings(){

    this._mctradeService.UpsertTenantSettings(this.settings);
  }

}
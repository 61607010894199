export class RetentionPredictionResultsDto {
  public constructor() {

    this.profileId = 0;
    this.name = "";
    this.annualDues = 0;
    this.membershipLevel = '';
    this.probability = 0;
    this.predictedLabel = false;
    this.descr = '';
    this.predictedclusterId = 0;
    this.distances = new Array<number>();
  }

  public profileId: number
  public name: string
  public annualDues: number
  public membershipLevel: string
  public probability: number
  public predictedLabel: boolean
  public descr: string

  public predictedclusterId: number;
  public distances: Array<number>;

}
import { Component } from '@angular/core';

@Component({
  selector: 'app-mctrade-revenue-assistant',
  templateUrl: './mctrade-revenue-assistant.component.html',
  styleUrl: './mctrade-revenue-assistant.component.css'
})
export class MctradeRevenueAssistantComponent {

}

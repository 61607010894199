import { LogService } from './../log/log.service';
import { UtilService } from './../util/util.service';
import { CachedHttpRequest } from './cacthed-http-request.model';
import { HttpRequestOptions } from './http-request-options.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { KeyValue } from '@angular/common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpRequestService {
  private _defaultCacheSeconds = 30;
  private _cachedRequests = new Array<CachedHttpRequest>();
  private _instanceId: number;

  constructor(private _http: HttpClient, private _utilService: UtilService, private _logService: LogService) {
    this._instanceId = Math.round(Math.random() * 1000);
  }

  public get(url: string, requestOptions?: HttpRequestOptions) {
    if (!requestOptions) {
      requestOptions = this.getDefaultRequestOptions();
    }
    
    const headers = this.getHttpHeadersFromNameValues(requestOptions.Headers);
    return this._http.get(url, { headers }).toPromise();
  }

  public post(url: string, body: any, requestOptions?: HttpRequestOptions): Promise<any>{

    if(!requestOptions){
      requestOptions = this.getDefaultRequestOptions();
    }
    
    const headers = this.getHttpHeadersFromNameValues(requestOptions.Headers);
    return this._http.post(url, body,{headers}).toPromise();
  }

  public postFormData(url: string, formData: FormData, requestOptions?: HttpRequestOptions): Promise<any>{

    if(!requestOptions){
      requestOptions = this.getDefaultRequestOptions();
    }
    
    const headers = this.getHttpHeadersFromNameValues(requestOptions.Headers);
    return this._http.post(url, formData,{headers}).toPromise();
  }

  public put(url: string, body: any, requestOptions?: HttpRequestOptions) {

    if (!requestOptions) {
      requestOptions = this.getDefaultRequestOptions();
    }

    const headers = this.getHttpHeadersFromNameValues(requestOptions.Headers);
    return this._http.put(url, body, { headers }).toPromise();
  }

  public delete(url: string, body: any, requestOptions?: HttpRequestOptions) {

    if (!requestOptions) {
      requestOptions = this.getDefaultRequestOptions();
    }

    const headers = this.getHttpHeadersFromNameValues(requestOptions.Headers);
    return this._http.delete(url, { headers } ).toPromise();
  }

  public getRequest(url: string, requestOptions: HttpRequestOptions): Promise<any> {
    this.pruneRequests();
    this._logService.log(
      'Http Request Service Instance Id ' + this._instanceId.toString(),
      'Requests Being Tracked ' + this._cachedRequests.length.toString()
    );

    let cachedRequest = this.getCachedRequest(url);
    if (requestOptions.DeleteCache) {
      if (cachedRequest != null) {
        // change it to be expired
        cachedRequest.ExpirationDate = new Date(1900, 1, 1);

        cachedRequest = null;
      }
    }

    if (cachedRequest != null) {
      this._logService.log('http request has existing cached data.  That data is now being returned.', url);
      if (requestOptions.SlidingCache) {
        this._logService.log(
          'http request has a sliding cached version.  The expiration will slide forward ' +
            cachedRequest.SlidingCacheSeconds.toString() +
            ' seconds.',
          url
        );
        cachedRequest.ExpirationDate = new Date(new Date().getTime() + cachedRequest.SlidingCacheSeconds * 1000);
      } else {
        this._logService.log('http request has a non-sliding cached version.', url);
      }
      return Promise.resolve(cachedRequest.ResponseData);
      // if sliding cache increment the cache expires
    } else {
      const requestInProgress = this.getRequestInProgress(url);
      if (requestInProgress != null) {
        // this._logService.log('http request already in progress.  Request will be queued until the in progress request responds.', url);
        return requestInProgress.ResponsePromise;
      } else {
        const newRequest = new CachedHttpRequest();
        if (requestOptions.EnableCaching) {
          newRequest.EnableCaching = true;

          if (requestOptions.CacheSeconds > 0) {
            newRequest.ExpirationDate = new Date(new Date().getTime() + requestOptions.CacheSeconds * 1000);
          } else {
            newRequest.ExpirationDate = new Date(new Date().getTime() + this._defaultCacheSeconds * 1000);
          }

          if (requestOptions.SlidingCache) {
            newRequest.SlidingCache = true;
            if (requestOptions.SlidingCacheSeconds > 0) {
              newRequest.SlidingCacheSeconds = requestOptions.SlidingCacheSeconds;
            } else {
              newRequest.SlidingCacheSeconds = this._defaultCacheSeconds;
            }
          }
        } else {
          this._logService.log('http request being made will NOT be cached', url);
        }
        newRequest.InProgress = true;
        newRequest.Url = url;
        newRequest.PrimaryRequest = true;

        const hdrs = this.getHttpHeadersFromNameValues(requestOptions.Headers);

        this._logService.log('LIVE http request being made to remote resource', url);
        const responsePromise = this._http
          .get(url, { headers: hdrs })
          .toPromise()
          .then(
            (res: Response) => {
              const data = res; //this.extractData(res);
              for (const request of this._cachedRequests) {
                if (request.Url === url) {
                  request.InProgress = false;
                  if (request.PrimaryRequest && request.EnableCaching) {
                    // set cached data on the primary request only not any queued requests
                    this._logService.log('http request will be cached until ' + request.ExpirationDate.toString(), url);
                    request.ResponseData = data;
                  }
                  request.Completed = true;

                  this._logService.log('http queued request is about to be fulfilled', url);
                }
              }
              this._logService.log('http request returned data', url);
              return Promise.resolve(data);
            },
            (errRes: HttpErrorResponse) => {
              for (const cachedRequest of this._cachedRequests) {
                if (cachedRequest.Url === url) {
                  cachedRequest.InProgress = false;
                  cachedRequest.Completed = true;
                }
              }
              let errorUrl = '';
              if (url) {
                errorUrl = url;
              }

              if (errRes?.error) {
                //this is a standard api error payload
                this._logService.log('api request returned error', errorUrl + ' ' + JSON.stringify(errRes.error));
                return Promise.reject(errRes.error);
              } else if (errRes?.message) {
                this._logService.log('api request returned error', errorUrl + ' ' + errRes.message + ' ' + JSON.stringify(errRes));
                return Promise.reject(errRes);
              } else {
                var errorObj = 
                {
                  "statusCode" : errRes.status,
                  "message":errRes.message
                };
                this._logService.log('api request returned error', errorUrl + ' ' + JSON.stringify(errRes));
                return Promise.reject(errorObj);
              }

              
            }
          );

        newRequest.ResponsePromise = responsePromise;
        this._cachedRequests.splice(0, 0, newRequest);
        return responsePromise;
      }
    }
  }


 //downloading files
 public getBinaryFile(url: string, requestOptions: HttpRequestOptions): Promise<ArrayBuffer> {

  if (!requestOptions) {
    requestOptions = this.getDefaultRequestOptions();
  }

  var headers = this.getHttpHeadersFromNameValues(requestOptions.Headers);
  var options = { 
    headers: headers, 
    responseType: 'arraybuffer' 
  };
    return this._http.get(url, { 
      headers: headers, 
      responseType: 'arraybuffer' 
    }).toPromise();
}





  public getDefaultRequestOptions(): HttpRequestOptions {
    const reqOptions = new HttpRequestOptions();
    reqOptions.Headers = new Array<KeyValue<string, any>>();

    return reqOptions;
  }

  private getHttpHeadersFromNameValues(nameValues: Array<KeyValue<string, any>>): HttpHeaders {
    let currentHeaders = new HttpHeaders();
    for (const nv of nameValues) {
      currentHeaders = currentHeaders.set(nv.key, nv.value);
    }

    return currentHeaders;
  }

  public getDefaultCacheSeconds(): number {
    return this._defaultCacheSeconds;
  }

  public removeCachedRequest(request: CachedHttpRequest) {
    const index = this._cachedRequests.findIndex((x) => x.Url === request.Url);

    if (index > -1) {
      this._cachedRequests.splice(index, 1);
    }
    this._logService.log('http request item has been removed from the tracking list.', request.Url);
  }

  private pruneRequests(): Promise<void> {
    return new Promise<void>(() => {
      for (let request of this._cachedRequests) {
        if (request.Completed == true) {
          request.InProgress = false;
        }
        if (request.Completed && request.EnableCaching === false) {
          this.removeCachedRequest(request);
          this._logService.log('http request has been pruned because its completed and does not require caching.', request.Url);
        }

        if (request.Completed && request.EnableCaching) {
          if (this.isCachedRequestExpired(request)) {
            this.removeCachedRequest(request);
            this._logService.log('http request has been pruned because its cache expired.', request.Url);
          }
        }
      }
    });
  }

  private getCachedRequest(url: string): CachedHttpRequest {
    let cachedRequest: CachedHttpRequest;

    for (let request of this._cachedRequests) {
      if (
        request.Completed &&
        request.PrimaryRequest &&
        this._utilService.toLowerTrim(request.Url) === this._utilService.toLowerTrim(url)
      ) {
        if (request.EnableCaching) {
          if (!this.isCachedRequestExpired(request)) {
            cachedRequest = request;
            break;
          } else {
            this._logService.log('http request has a cached item but it has expired.', url);
            this.pruneRequests();
            break;
          }
        }
      }
    }

    return cachedRequest;
  }

  private isCachedRequestExpired(request: CachedHttpRequest): boolean {
    const currentDate = new Date();

    return !(currentDate <= request.ExpirationDate);
  }

  private getRequestInProgress(url: string): CachedHttpRequest {
    let requestInProgress: CachedHttpRequest = null;
    for (const request of this._cachedRequests) {
      if (request.InProgress && request.Completed && this._utilService.toLowerTrim(request.Url) === this._utilService.toLowerTrim(url)) {
        requestInProgress = request;
        break;
      }
    }

    return requestInProgress;
  }

  private extractData(res: Response) {
    // data comes in as json
    const data: any = res;
    if ((res?.status ?? 500) === 204) {
      // this is a no content response
    } else {
      this._utilService.convertJsonUtcServerDateStringsToDates(data);
    }

    return data;
  }
}

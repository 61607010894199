import { Component } from '@angular/core';
import { InisghtsService } from 'src/app/insights/shared/insights.service';

@Component({
  selector: 'app-mctrade-predictive-analysis-home',
  templateUrl: './mctrade-predictive-analysis-home.component.html',
  styleUrl: './mctrade-predictive-analysis-home.component.css'
})
export class MctradePredictiveAnalysisHomeComponent {

constructor(private _insightsService: InisghtsService){
  
}
  
  membershipPredictiveVisibilityChanged = ($event) => {

    if($event == true){

      //record an activity
      this._insightsService.CreateActivityWithSuccessResult("ML_Profile_Retention_Screen_Viewed", "");
    }
  }
  membershipSegmentationVisibilityChanged = ($event) => {

    if($event == true){

      //record an activity
      this._insightsService.CreateActivityWithSuccessResult("ML_Member_Segmentation_Screen_Viewed", "");
    }
  }

}

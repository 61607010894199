import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from './../../shared/util/util.service';
import { AdminService } from './../shared/admin.service';
import { NavigationService } from '../../shared/navigation/navigation.service';
import { RabTenantDto } from './../shared/dto/rab-tenant-dto';
import { DataView } from 'primeng/dataview';
import { CreateTenantConfigDto } from './../shared/dto/create-new-rab-tenant-dto';
import { SearchResultDto } from '../../shared/dto/search-result-dto';
import { UserSession } from '../../auth/shared/user-session.model';
import { AuthService } from '../../auth/shared/auth.service';
import { NotificationService } from '../../shared/notification/notification.service';
import { TabView } from 'primeng/tabview';
import { Dialog } from 'primeng/dialog';
import { InsightsBuilderComponent } from 'src/app/insights-builder/insights-builder.component';
import { IngestTenantDataDto } from '../shared/dto/ingest-tenant-data-dto';
import { InisghtsService } from 'src/app/insights/shared/insights.service';
import { ActivityDto } from 'src/app/insights/shared/dto/activity-dto';
import { McTradeService } from 'src/app/products/mctrade/shared/mctrade.service';
import { MctradeTenantSettingsDto } from 'src/app/products/mctrade/shared/dto/mctrade-tenant-settings-dto';

@Component({
  selector: 'admin-tenant-edit',
  templateUrl: './admin-tenant-edit.component.html',
  styleUrl: './admin-tenant-edit.component.css'
})
export class AdminTenantEditComponent {


  @ViewChild("tabView1") tabView1: TabView;
  @ViewChild("editDatasetDialog") editDialog: Dialog;
  @ViewChild("insightsBuilderComponent") insightsBuilderComponent: InsightsBuilderComponent;

  public displayResponse: string;
  public tenants: Array<RabTenantDto> = new Array<RabTenantDto>();
  public selectedTenant: RabTenantDto = new RabTenantDto();
  public products: Array<string> = new Array<string>('', 'mc_trade');
  public selectedProduct: string = 'mc_trade'

  public mcTradeMenuItemValue: boolean = false;
  public mcTradeMenuItemOriginalValue: boolean = false;
  public currentUserSession: UserSession = new UserSession;

  public ingestDataStartDate: Date = new Date();
  public ingestDataEndDate: Date = new Date();

  public minDateValue: Date = new Date(2019, 0, 1);
  public maxDateValue: Date = new Date();

  public lastIngestionDate: Date = new Date();
  public ingestionAsOfDate: Date = new Date();


  public excludeProfiles: boolean = false;
  public excludeRevenue: boolean = false;
  public excludePayments: boolean = false;
  public excludeEngagement: boolean = false;
  public excludeMemberActivity: boolean = false;
  public excludeEventsignups: boolean = false;

  public ingestionOverwriteAll : boolean = true;

  constructor(
    private _adminService: AdminService,
    private _navigationService: NavigationService,
    private _utilService: UtilService,
    private _authService: AuthService,
    private _notificationService: NotificationService,
    private _route: ActivatedRoute,
    private _insightsService: InisghtsService,
    private _mcTradeService: McTradeService
  ) {

  }

  ngOnInit(): void {
    var tenantId = this._route.snapshot.paramMap.get('id');

    if (tenantId != null) {

      this._adminService.getTenant(tenantId).then((data: RabTenantDto) => {

        this.selectedTenant = data;
        this._adminService.selectTenant(this.selectedTenant);

        this.insightsBuilderComponent.loadQuicksightsElementsByProductIdAndTenantId(this.selectedTenant.productId, this.selectedTenant.tenantId);
      });


      this._adminService.GetDataInsightsMenuItemInMcTradeValue(tenantId).then((data: boolean) => {

        this.mcTradeMenuItemValue = data;
        this.mcTradeMenuItemOriginalValue = data;
      });

      this._insightsService.GetLastSuccessfullDataIngestionActivities(tenantId, 5).then((data: Array<ActivityDto>) => {

        if(data.length > 0){
          this.lastIngestionDate = data[0].activityDate;
        }
       
      })

      this._mcTradeService.GetTenantSettingsFromTenantId(tenantId).then((data: MctradeTenantSettingsDto) => {

        this.ingestionAsOfDate = data.ingestionAsOfDate;
        this.ingestDataStartDate = data.ingestionAsOfDate;
        this.minDateValue = data.ingestionAsOfDate;;
      });


    }

  }

  upsertTenant() {

    this.selectedTenant.changedBy = this._authService.getUserSession().userName;

    if (this.mcTradeMenuItemValue != this.mcTradeMenuItemOriginalValue) {
      this._adminService.enableOrDisableDataInsightsMenuItemInMcTrade(this.selectedTenant.tenantId, this.mcTradeMenuItemValue).then(() => {

        this.mcTradeMenuItemOriginalValue = this.mcTradeMenuItemValue;
      });
    }


    this._adminService.updateTenant(this.selectedTenant);

    this._navigationService.navigateToAdmin();


  }


  cancel() {

    this._navigationService.navigateToAdmin();
  }

  generateMlModels(tenant: RabTenantDto) {

    this._adminService.GenerateMCTradeMLModels(tenant.tenantId);
    this._notificationService.info("Generating ML Models...");
    this.tabView1.activeIndex = 0;

  }


  ingestMcTradeData() {

    var ingestConfig = new IngestTenantDataDto();
    ingestConfig.tenantId = this.selectedTenant.tenantId;
    ingestConfig.productId = this.selectedTenant.productId;
    ingestConfig.overwriteAll = this.ingestionOverwriteAll;
    ingestConfig.startDate = this.ingestDataStartDate;
    ingestConfig.endDate = this.ingestDataEndDate;
    ingestConfig.excludeEntityNames = [];
    if(this.excludeProfiles == true){
      ingestConfig.excludeEntityNames.push("profile");
    }
    if(this.excludeRevenue == true){
      ingestConfig.excludeEntityNames.push("revenue");
    }
    if(this.excludePayments == true){
      ingestConfig.excludeEntityNames.push("payment");
    }
    if(this.excludeEventsignups == true){
      ingestConfig.excludeEntityNames.push("eventsignup");
    }
    if(this.excludeEngagement == true){
      ingestConfig.excludeEntityNames.push("engagement");
    }
    if(this.excludeMemberActivity == true){
      ingestConfig.excludeEntityNames.push("memberactivity");
    }
   
    this._adminService.IngestMcTradeData(ingestConfig);
  }



}

import { SearchDto } from '../../../../shared/dto/search-dto';

export class ProfileSearchDto extends SearchDto {
    public constructor() {

        super();

        this.keyword = '';
        this.profileIds = new Array<number>();
        this.member = false;

    }

    public profileIds: Array<number>
    public keyword: string
    public member: boolean

}
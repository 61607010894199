import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser';


import { LocalStorageService } from '../../../../shared/storage/local-storage.service';
import { DashboardService } from '../../../../dashboard/shared/dashboard.service';

import { createEmbeddingContext, DashboardContentOptions, FrameOptions, EmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { AuthService } from '../../../../auth/shared/auth.service';
import { LogService } from '../../../../shared/log/log.service';
import { UtilService } from '../../../../shared/util/util.service';
import { InisghtsService } from 'src/app/insights/shared/insights.service';

@Component({
  selector: 'mctrade-membership-dashboard',
  templateUrl: './mctrade-membership-dashboard.component.html',
  styleUrls: ['./mctrade-membership-dashboard.component.css']
})
export class MctradeMembershipDashboardComponent implements OnInit {


  private _productId: string = "";
  private _tenantId: string = "";
  public embedUrls: Array<string> = new Array<string>();
  private _internalUrls = new Array<string>();

  public dashboardEmbedUrlRaw: string = "";

  //@Input() embeddingContext: EmbeddingContext;

  public embedUrlPromises: Array<Promise<any>> = new Array<Promise<any>>();

  constructor(private _dashboardService: DashboardService, private _insightsService: InisghtsService, private _logService: LogService, private _utilService: UtilService, private _authService: AuthService, private _sanitizer: DomSanitizer, private _localStorageService: LocalStorageService, private _changeDetection: ChangeDetectorRef) {


  }

  ngOnInit(){

    this._tenantId = this._authService.getUserSession().tenantId;
    this._productId = this._authService.getUserSession().productId;

    

    this.loadQuicksightsElements();

  }

  loadQuicksightsElements = (): void => {

    setTimeout(() => {
      var membershipDashboardId = "membership_" + this._tenantId + "_" + this._productId; //"membership_jondevqanhcoc_mc_trade"; //
      var embedUrlPromise = this._dashboardService.getDashboardEmbedUrlByDashboardId(membershipDashboardId).then((data: string) => {

        var url = data;

        var dashboardContainerId = "membership-dashboard-container";
        var frameOptionsDashboard = this._dashboardService.getDashboardFrameOptions("Membership", dashboardContainerId, url);
        var contentOptionsDashboard = this._dashboardService.getDashboardContentOptions("Membership");
        var embeddedDashboardExperience = this._dashboardService.getEmbeddingContext().embedDashboard(frameOptionsDashboard, contentOptionsDashboard);
        
      }, (err) => {
        this._logService.info(err);
      });
    }, 1000);
    

   

  }


}

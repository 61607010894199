export class SearchDto {
    public constructor() {
  
     
      this.orderBy = '';
      this.pageNumber = 1;
      this.pageSize = 10;
     
    }
  
    public  pageNumber : number
    public  pageSize : number
    public  orderBy : string
  
  }
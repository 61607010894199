import { Component } from '@angular/core';

@Component({
  selector: 'app-mctrade-profile-assistant',
  templateUrl: './mctrade-profile-assistant.component.html',
  styleUrl: './mctrade-profile-assistant.component.css'
})
export class MctradeProfileAssistantComponent {

}

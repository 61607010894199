<div class="grid">
  <div class=col-6>
    <h3 i18n>Data With The Highest Retention Impact </h3>
  </div>
  <div class=col-6>

  </div>

  <div class=col-6>
    <p-table [value]="retentionFeatureImportanceResults" styleClass="p-datatable-striped p-datatable-gridlines"
      responsiveLayout="scroll" [paginator]="true" [rows]="5">
      <ng-template pTemplate="header">

        <tr>
          <th i18n>Feature Name</th>
          <th><span style="float: right" i18n>Rank</span></th>

        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
        <tr>
          <td><span>{{item.feature}}</span></td>
          <td><span style="float: right">{{rowIndex + 1}}</span></td>
        </tr>
      </ng-template>

    </p-table>
  </div>
  <div class=col-6>


  </div>


</div>

<div class="grid" style="width:100%">

  <div class="col-12">

    <p-table [value]="profileRetentionPredictionSavedResults" styleClass="p-datatable-striped p-datatable-gridlines"
      [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10,25,50,100]" responsiveLayout="scroll">

      <ng-template pTemplate="caption">
        <div class="grid" style="width:100%">
          <div class="col-6">
            <p-button styleClass="p-button-link" (click)="exportToCsv()" i18n-label label="Download Data"></p-button>
          </div>
          <div class="col-6">

            <div style="float: right">
              <p-button styleClass="p-button-link" (click)="getProfileRetentionInputDataSaved()" i18n-label
                label="Download Source Data Used in Analysis"></p-button>
            </div>
          </div>
        </div>



      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="white-space: nowrap" pSortableColumn="name" i18n>Name<p-columnFilter type="text" field="name"
              display="menu"></p-columnFilter></th>
          <th style="white-space: nowrap" pSortableColumn="profileId" i18n>ProfileId<p-columnFilter type="text"
              field="profileId" display="menu"></p-columnFilter></th>
          <th style="white-space: nowrap" pSortableColumn="annualDues" i18n>Annual Dues<p-columnFilter type="text"
              field="annualDues" display="menu"></p-columnFilter></th>
          <th style="white-space: nowrap" pSortableColumn="membershipLevel" i18n>Membership Level<p-columnFilter
              type="text" field="membershipLevel" display="menu"></p-columnFilter></th>
          <th style="white-space: nowrap" pSortableColumn="descr" i18n>Predicted Outcome<p-columnFilter type="text"
              field="descr" display="menu"></p-columnFilter></th>
          <th style="white-space: nowrap" pSortableColumn="probability" i18n>Outcome %<p-columnFilter type="text"
              field="probability" display="menu"></p-columnFilter></th>
          <th style="white-space: nowrap" pSortableColumn="predictedclusterId" i18n>Cluster Id<p-columnFilter
              type="text" field="predictedclusterId" display="menu"></p-columnFilter></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td>{{item.name}}</td>
          <td>{{item.profileId}}</td>
          <td><span style="float: right">{{item.annualDues| currency}}</span></td>
          <td>{{item.membershipLevel}}</td>
          <td>{{item.descr }}</td>
          <td>{{item.probability | percent: '0.2'}}</td>
          <td>{{item.predictedclusterId }}</td>
        </tr>
      </ng-template>
      <!-- <ng-template pTemplate="summary">

      </ng-template> -->
    </p-table>

  </div>

  <!-- <div class="col-3">
    <label>ProfileIds (1,2,3)</label>
  </div>
  <div class="col-6">
    <input id="inputProfileIds" class="w-full" type="text" pInputText [(ngModel)]="profileIdsCsv">
  </div>
  <div class="col-3">
    <p-button id="btnSearch" label="Search Profiles" (click)="searchProfiles()" icon="pi pi-search"
      [disabled]="searchingProfiles == true" class="w-auto"></p-button><p-progressSpinner styleClass="w-2rem h-2rem"
      *ngIf="searchingProfiles == true"></p-progressSpinner>
  </div> -->


  <div class="col-12" *ngIf="profiles.length > 0">

    <p-table id="profileSearchResults" [value]="profiles" styleClass="p-datatable-striped p-datatable-gridlines"
      responsiveLayout="scroll">
      <ng-template pTemplate="caption">
        <div class="grid" style="width:100%">
          <div class="col-6">
            <h4>Profiles</h4>
          </div>
          <div class="col-6">
            <div style="float: right">
              <p-button styleClass="p-button-link" (click)="getProfileRetentionInputDataSearched()"
                label="Download Source Data"></p-button>
            </div>

          </div>
        </div>

      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <!-- <th>Select</th> -->
          <th>ProfileId</th>
          <th>Name</th>
          <th>Annual Dues</th>
          <th>Membership Level</th>
          <th></th>

        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr>
          <!-- <td><p-checkbox [value]="data.selected"></p-checkbox></td> -->
          <td>{{data.item.profileId}}</td>
          <td>{{data.item.name}}</td>
          <td><span style="float: right">{{data.item.annualDues| currency}}</span></td>
          <td>{{data.item.membershipLevel}}</td>
          <td>
            <label
              *ngIf="data.selected == false && data.item.probability > 0">{{data.item.descr}}{{data.item.probability
              | percent: '0.2'}}</label>
            <p-button *ngIf="data.selected == false && data.item.probability <= 0" id="btnProfilePredict" pButton
              pRipple label="Predict" (click)="predictRetention(data)" icon="pi pi-sun"></p-button>
            <p-progressBar mode="indeterminate" *ngIf="data.selected == true"
              [style]="{ height: '4px' }"></p-progressBar>
          </td>

        </tr>
      </ng-template>
      <ng-template pTemplate="summary">

      </ng-template>
    </p-table>

  </div>

  <div class="col-12">
    <div #scrollTarget></div>
  </div>














</div>
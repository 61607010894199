import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/api/api.service';
import { AuthService } from '../../../auth/shared/auth.service';
import { UtilService } from '../../../shared/util/util.service';
import { ProfileSummaryDto } from '../shared/dto/profile-summary-dto';
import { ProfileSearchDto } from '../shared/dto/profile-search-dto';
import { RetentionPredictionResultsDto } from '../shared/dto/retention-prediction-results-dto';
import { RetentionPredictionSearchDto } from '../shared/dto/retention-predition-search-dto';
import { IngestTenantDataDto } from '../../../insights/shared/dto/ingest-tenant-data-dto';
import { AssociationInfoDto } from '../shared/dto/association-info-dto';
import { RetentionPredictionInputDto } from '../shared/dto/retention-prediction-input-dto';
import { RevenueItemTimeSeriesPredictionDto } from '../shared/dto/revenueitem-timeseries-prediction-dto';
import { MctradeTenantSettingsDto } from '../shared/dto/mctrade-tenant-settings-dto';
import { SegmentationPredictionDto } from '../shared/dto/segmentation-prediction-dto';
import { SegmentationSummaryDto } from '../shared/dto/segmentation-summary-dto';
import { SegmentationFeatureImportanceResultDto } from './dto/segmentation-feature-importance-dto';
import { RetentionFeatureImportanceResultDto } from './dto/retention-feature-importance-dto';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
 

@Injectable({
    providedIn: 'root'
})
export class McTradeService {


    private _tenantId: string = "";
    private _productId: string = "";

    public constructor(private _apiService: ApiService, private _authService: AuthService, private _utilService: UtilService, private _http: HttpClient) {


        this._tenantId = this._authService.getUserSession().tenantId;
        this._productId = this._authService.getUserSession().productId;



    }

    public getAssociationInfoAssociationInfoDto = (tenantId: string): Promise<AssociationInfoDto> => {

        var url = `api/v1/insights/mctrade/${tenantId}/data/associationinfo`;
        return this._apiService.get(url);
    }

    public searchProfiles = (search: ProfileSearchDto): Promise<Array<ProfileSummaryDto>> => {

        var url = `api/v1/insights/mctrade/${this._tenantId}/data/profiles`;
        url = this._utilService.appendObjectPropertyValuesToUrlQuerystring(url, search);
        return this._apiService.get(url);

    }

    public ingestTenantData = (ingestConfig: IngestTenantDataDto): void => {

        var url = `api/v1/insights/mctrade/${this._tenantId}/ingestion/ingest`;
        this._apiService.post(url, ingestConfig);

    }


    public getProfileRetentionPredictions = (profileIds: Array<number>): Promise<Array<RetentionPredictionResultsDto>> => {

        var profilidcsv = this._utilService.listToCsv(profileIds);
        var url = `api/v1/insights/ml/mctrade/${this._tenantId}/prediction/retention?profileids=${profilidcsv}`;

        return this._apiService.get(url);

    }

    public GetSavedProfileRetentionPredictions = (search: RetentionPredictionSearchDto): Promise<Array<RetentionPredictionResultsDto>> => {


        //var url = `api/v1/insights/ml/mctrade/${this._tenantId}/prediction/retention/results`;
        var url = `api/v1/insights/ml/mctrade/${this._tenantId}/retention`;
        url = this._utilService.appendObjectPropertyValuesToUrlQuerystring(url, search);

        return this._apiService.get(url);

    }

    public getProfileRetentionPredictionInputData = (profileIds: Array<number>): Promise<Array<RetentionPredictionInputDto>> => {

        var profilidcsv = this._utilService.listToCsv(profileIds);
        var url = `api/v1/insights/ml/mctrade/${this._tenantId}/prediction/retention/input?LimitToLikelyDropOutcomes=true&profileids=${profilidcsv}`;

        return this._apiService.get(url);

    }

    public downloadProfileRetentionPredictionInputDataAsCsv = (profileIds: Array<number>): void => {

        var profilidcsv = this._utilService.listToCsv(profileIds);


        var csvIds = this._utilService.listToCsv(profileIds);
        //var url = "/api/v1/insights/ml/mctrade/" + this._tenantId + "/prediction/retention/input/csv?&profileids=" + csvIds
        var url = "/api/v1/insights/ml/mctrade/" + this._tenantId + "/prediction/retention/input/csv";

        this._apiService.downloadFile(url);

    }



    public GetRevenuePredictions = (lastYearWithData: number, lastMonthWithData: number): Promise<Array<RevenueItemTimeSeriesPredictionDto>> => {


        var url = "api/v1/insights/ml/mctrade/" + this._tenantId + "/prediction/revenue/" + lastYearWithData.toString() + "/" + lastMonthWithData.toString();

        return this._apiService.get(url);

    }

    public GetSavedRevenuePredictions = (lastYearWithData: number, lastMonthWithData: number): Promise<Array<RevenueItemTimeSeriesPredictionDto>> => {


        var url = "api/v1/insights/ml/mctrade/" + this._tenantId + "/prediction/revenue/" + lastYearWithData.toString() + "/" + lastMonthWithData.toString() + "/results";

        return this._apiService.get(url);

    }

    
    public GetSavedSegmentationPredictions = (): Promise<Array<SegmentationPredictionDto>> => {


        //var url = "api/v1/insights/ml/mctrade/" + this._tenantId + "/prediction/segmentation/results";
        var url = "api/v1/insights/ml/mctrade/" + this._tenantId + "/segmentation";

        return this._apiService.get(url);

    }

    public GetSavedSegmentationPredictionsFromSelfSignedUrl = (): Promise<Array<SegmentationPredictionDto>> => {

        var url = "api/v1/insights/ml/mctrade/" + this._tenantId + "/segmentation/url";

        //this returns a self signed s3 url because the payload may be too big for lambda to stream
        //so get i from s3
        return this._apiService.get(url).then((url:string)=>{

            var s3headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            return  this._http.get(url, { headers: s3headers }).toPromise().then((data: any) =>{

                return  <SegmentationPredictionDto[]> data;
            });
             
        });

    }
    

    public GetSavedSegmentationSummary = () : Promise<Array<SegmentationSummaryDto>> => {

        //var url = "api/v1/insights/ml/mctrade/" + this._tenantId + "/prediction/segmentation/results/summary";
        var url = "api/v1/insights/ml/mctrade/" + this._tenantId + "/segmentation/summary";
        return this._apiService.get(url);
    }

    public GetSavedSegmentationFeatureImportance = () : Promise<Array<SegmentationFeatureImportanceResultDto>> => {

       
        var url = "api/v1/insights/ml/mctrade/" + this._tenantId + "/segmentation/featureimportance";
        return this._apiService.get(url);
    }

    public GetSavedRetentionFeatureImportance = () : Promise<Array<RetentionFeatureImportanceResultDto>> => {

       
        var url = "api/v1/insights/ml/mctrade/" + this._tenantId + "/retention/featureimportance";
        return this._apiService.get(url);
    }

    public GetEngaementModelCategoricalFieldNames = (): Array<string> =>{

        return ["primarylistingcategory","primarylistingsubcategory","membershiplevel","customerstatus","naicscode","siccode","membersource","soldbyusername","city","state","zip","county","country"]

    }


    public GetTenantSettings = (): Promise<MctradeTenantSettingsDto> => {

        return this._apiService.get("api/v1/insights/mctrade/" + this._tenantId + "/settings").then((data:MctradeTenantSettingsDto) =>{
            data.ingestionAsOfDate = new Date(data.ingestionAsOfDate)            
            return Promise.resolve(data);
        });

        
        
    }

    public GetTenantSettingsFromTenantId = (tenantId: string): Promise<MctradeTenantSettingsDto> => {

        return this._apiService.get("api/v1/insights/mctrade/" + tenantId + "/settings").then((data:any) =>{
            data.ingestionAsOfDate = new Date(data.ingestionAsOfDate)            
            return Promise.resolve(data);
            return Promise.resolve(data);
        });

        
        
    }

    public GetTenantDataQuery = (tenantId: string, sql:string): Promise<any> => {

        return this._apiService.get("api/v1/insights/mctrade/" + tenantId + "/data/query?sql=" + encodeURIComponent(sql));        
        
    }

    public UpsertTenantSettings = (settings: MctradeTenantSettingsDto): void => {


        this._apiService.put("api/v1/insights/mctrade/" + this._tenantId + "/settings", settings);
    }

    public GetSegmentationFeatureColumnNames = (): Promise<Array<string>> =>{
        //return this._apiService.get("api/v1/insights/ml/mctrade/" + this._tenantId + "/segmentation/featurecolumnnames");
        return this._apiService.get("api/v1/insights/ml/mctrade/segmentation/featurecolumnnames");
    }

    public GetSegmentationFeatureColumnNamesCategorical = (): Promise<Array<string>> =>{
        return this._apiService.get("api/v1/insights/ml/mctrade/" + this._tenantId + "/segmentation/featurecolumnnames/categorical");
    }

    public GetSegmentationFeatureColumnNamesNumerical = (): Promise<Array<string>> =>{
        return this._apiService.get("api/v1/insights/ml/mctrade/" + this._tenantId + "/segmentation/featurecolumnnames/numerical");
    }

    public GetRetentionFeatureColumnNames = (): Promise<Array<string>> =>{
        //return this._apiService.get("api/v1/insights/ml/mctrade/" + this._tenantId + "/retention/featurecolumnnames");
        return this._apiService.get("api/v1/insights/ml/mctrade/retention/featurecolumnnames");
    }
    public GetRetentionFeatureColumnNamesCategorical = (): Promise<Array<string>> =>{
        return this._apiService.get("api/v1/insights/ml/mctrade/" + this._tenantId + "/retention/featurecolumnnames/categorical");
    }
    public GetRetentionFeatureColumnNamesNumerical = (): Promise<Array<string>> =>{
        return this._apiService.get("api/v1/insights/ml/mctrade/" + this._tenantId + "/retention/featurecolumnnames/numerical");
    }

    
}

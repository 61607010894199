<div class="container">

  <admin-auth></admin-auth>

  <div class="text-center mb-5">

    <div class="text-900 text-3xl font-medium mb-3">Admin Tenant Management</div>
  </div>

  <div class="grid">
    <div class="col-6">
      <p-button id="btnNew" i18n-label label="Deploy New Tenant" (click)="newTenant()" icon="pi pi-plus-circle"
        class="w-auto"></p-button>
    </div>
    <div class="col-6">

    </div>

  </div>

  <p-dialog #editDialog [style]="{width: '95%'}" [(visible)]="showDialog" [modal]="true" [keepInViewport]="true"
    [resizable]="true" [maximizable]="true">
    <ng-template pTemplate="header">

    </ng-template>

    <p-tabView #tabView1>
      <p-tabPanel #tabPanel1>
        <ng-template pTemplate="header">
          <span i18n>Tenant</span>
        </ng-template>
        <div class="grid">

          <div class="col-3">
            <label i18n for="product">Product Id</label>
          </div>
          <div class="col-9">
            <p-dropdown id="product" [disabled]="isNewTenantRequest == false" class="w-full" [options]="products"
              [(ngModel)]="selectedTenant.productId"></p-dropdown>
          </div>

          <div class="col-3">
            <label i18n for="tenant">Tenant Id</label>
          </div>
          <div class="col-9">
            <input [disabled]="isNewTenantRequest == false" id="tenant" class="w-full" type="text" pInputText
              [(ngModel)]="selectedTenant.tenantId">
          </div>
          <div class="col-3">
            <label i18n for="displayName">Display Name</label>
          </div>
          <div class="col-9">
            <input id="displayName" class="w-full" type="text" pInputText [(ngModel)]="selectedTenant.displayName">
          </div>


          <!-- <div class="col-6">
              <label i18n for="adminEmail">Admin Email</label>
            </div>
            <div class="col-6">
              <input id="adminEmail" class="w-full" type="text" pInputText [(ngModel)]="selectedTenant.adminEmail">
            </div>
            <div class="col-6">
              <label i18n for="adminPhone">Admin Phone</label>
            </div>
            <div class="col-6">
              <input id="adminPhone" class="w-full" type="text" pInputText [(ngModel)]="selectedTenant.adminPhone">
            </div>
            <div class="col-6">
              <label i18n for="adminFirstName">Admin First Name</label>
            </div>
            <div class="col-6">
              <input id="adminFirstName" class="w-full" type="text" pInputText [(ngModel)]="selectedTenant.adminFirstName">
            </div>
            <div class="col-6">
              <label i18n for="adminLastName">Admin Last Name</label>
            </div>
            <div class="col-6">
              <input id="adminLastName" class="w-full" type="text" pInputText [(ngModel)]="selectedTenant.adminLastName">
            </div> -->
          <div class="col-6">
            <label i18n for="inactive">Inactive</label>
          </div>
          <div class="col-6">
            <p-checkbox name="inactive" [(ngModel)]="selectedTenant.inactive"></p-checkbox>
          </div>
          <div class="col-6">
            <label [hidden]="isNewTenantRequest == false" i18n for="notifyEmail">Notify Email</label>
          </div>
          <div class="col-6">
            <input id="notifyEmail" [hidden]="isNewTenantRequest == false" class="w-full" type="text" pInputText
              [(ngModel)]="selectedTenant.notifyEmail">
          </div>

          <div class="col-12">
            <span>
              <p-button id="btnSave" i18n-label label="Deploy New Tenant" (click)="deployNewTenant()"
                icon="pi pi-check-circle" class="w-auto"></p-button>
            </span>
            <span style="margin-left: 5px;">
              <p-button id="btnCancel" i18n-label label="Cancel" (click)="cancel()" icon="pi pi-ban"
                class="w-auto"></p-button>
            </span>

          </div>



        </div>
      </p-tabPanel>
      <!-- <p-tabPanel #tabPanel2>
        <ng-template pTemplate="header">
          <span i18n>Custom Datasets</span>
        </ng-template>
        <admin-tenant-dataset [tenant]="selectedTenant"></admin-tenant-dataset>
      </p-tabPanel>
      <p-tabPanel #tabPanel3>
        <ng-template pTemplate="header">
          <span i18n>Actions</span>
        </ng-template>
        <p-button id="btnGenerateMLModels" i18n-label label="Generate Machine Learning Models"
          (click)="generateMlModels(selectedTenant)" icon="pi pi-bolt" class="w-auto"></p-button>
      </p-tabPanel>
      <p-tabPanel #tabPanel4>
        <ng-template pTemplate="header">
          <span i18n>Insights Builder</span>
        </ng-template>      
  
        <insights-builder></insights-builder>
      </p-tabPanel>   -->

    </p-tabView>



    <ng-template pTemplate="footer">



    </ng-template>
  </p-dialog>



  <div>
    <p-table #dt1 [value]="tenants" styleClass="p-datatable-gridlines" responsiveLayout="scroll"
      [globalFilterFields]="['tenantId']">
      <ng-template pTemplate="caption">

      </ng-template>
      <ng-template pTemplate="header">
        <tr>

          <th pSortableColumn="tenantId">
            <div i18n >
              
              Tenant   
              <p-sortIcon field="tenantId"></p-sortIcon>
              <p-columnFilter type="text" field="tenantId" display="menu"></p-columnFilter>
             
            </div>
          </th>
          <th pSortableColumn="displayName">
            <div i18n >
              Display Name
              <p-sortIcon field="displayName"></p-sortIcon>
              <p-columnFilter type="text" field="displayName" display="menu"></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="productId">
            <div i18n >
              Product
              <p-sortIcon field="productId"></p-sortIcon>
              <p-columnFilter type="text" field="productId" display="menu"></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="dateCreated" >
            <div i18n >
              Date Created
              <p-sortIcon field="dateCreated"></p-sortIcon>
              <p-columnFilter type="text" field="dateCreated" display="menu"></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="inactive">
            
            <div i18n >
              Inactive
              <p-sortIcon field="inactive"></p-sortIcon>
              <p-columnFilter type="text" field="inactive" display="menu"></p-columnFilter>
            </div>

          </th>
          <th></th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>

          <td><p-button styleClass="p-button-link" (click)="actAsTenant(item)" [label]="item.tenantId"></p-button></td>
          <td>{{item.displayName}}</td>
          <td>{{item.productId}}</td>
          <td>{{item.dateCreated |date: 'yyyy-MM-dd'}}</td>          
          <td>{{item.inactive}}</td>
          <td><p-button styleClass="p-button-link" type="button" i18n-label label="edit" class="p-button-text"
              (click)="selectTenant(item)"></p-button></td>
          <td> <p-button id="btnDelete" styleClass="p-button-link" i18n-label label="delete"
              (click)="deleteTenant(item)"></p-button></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">

      </ng-template>
    </p-table>

  </div>


</div>
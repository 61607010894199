import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { LocalStorageService } from '../../../shared/storage/local-storage.service';
import { DashboardService } from '../../../dashboard/shared/dashboard.service';

import { createEmbeddingContext, DashboardContentOptions, FrameOptions, EmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { AuthService } from '../../../auth/shared/auth.service';
import { LogService } from '../../../shared/log/log.service';
import { UtilService } from '../../../shared/util/util.service';
import { NotificationService } from '../../../shared/notification/notification.service';

import { AdminService } from '../../../admin/shared/admin.service';
import { RabTenantDto } from '../../../admin/shared/dto/rab-tenant-dto';
import { TenantDatasetDto } from '../../../admin/shared/dto/tenant-dataset-dto';
import { NameValueNumberDto } from '../../../shared/dto/name-value-number-dto';


@Component({
  selector: 'q-dashboard',
  templateUrl: './q-dashboard-mctrade.component.html',
  styleUrls: ['./q-dashboard-mctrade.component.css']
})
export class QDashboardMctradeComponent implements OnInit {


  public productId: string = "";
  public tenantId: string = "";

  public hideCards: boolean = false;
  public showProfileAiAssistant: boolean = false;
  public showRevenueAiAssistant: boolean = false;
  public showPaymentAiAssistant: boolean = false;
  public showEventSignUpAiAssistant: boolean = false;

  public customDatasetsColumn1: Array<TenantDatasetDto> = new Array<TenantDatasetDto>();
  public customDatasetsColumn2: Array<TenantDatasetDto> = new Array<TenantDatasetDto>();
  public customDatasets: Array<TenantDatasetDto> = new Array<TenantDatasetDto>();

  constructor(private route: ActivatedRoute, private _dashboardService: DashboardService, private _logService: LogService, private _utilService: UtilService, private _authService: AuthService, private _notificationService: NotificationService, private _adminService: AdminService) {


  }

  ngOnInit() {

    this.tenantId = this._authService.getUserSession().tenantId;
    this.productId = this._authService.getUserSession().productId;


    this.getDatasets();
  }

  ngAfterViewInit() {

    if (this.route.params["showTopics"] != null) {

      this.showTopicSelector();
    }

  }

  showTopicSelector() {

    this.hideCards = false;
    this.showProfileAiAssistant = false;
    this.showRevenueAiAssistant = false;
    this.showPaymentAiAssistant = false;
    this.showEventSignUpAiAssistant = false;
  }

  useProfileAiAssistant() {

    this._notificationService.info("Loading Profile AI Data...", 2000, false);

    this.hideCards = true;
    this.showProfileAiAssistant = true;
    this.showRevenueAiAssistant = false;
    this.showPaymentAiAssistant = false;
    this.showEventSignUpAiAssistant = false;
  }

  useRevenueAiAssistant() {

    this._notificationService.info("Loading Revenue AI Data...", 2000, false);

    this.hideCards = true;
    this.showProfileAiAssistant = false;
    this.showRevenueAiAssistant = true;
    this.showPaymentAiAssistant = false;
    this.showEventSignUpAiAssistant = false;
  }

  usePaymentAiAssistant() {

    this._notificationService.info("Loading Payment AI Data...", 2000, false);

    this.hideCards = true;
    this.showProfileAiAssistant = false;
    this.showRevenueAiAssistant = false;
    this.showPaymentAiAssistant = true;
    this.showEventSignUpAiAssistant = false;
  }

  useEventSignUpAiAssistant() {

    this._notificationService.info("Loading Event Sign-Up AI Data...", 2000, false);

    this.hideCards = true;
    this.showProfileAiAssistant = false;
    this.showRevenueAiAssistant = false;
    this.showPaymentAiAssistant = false;
    this.showEventSignUpAiAssistant = true;
  }

  getDatasets() {
    this._adminService.getDatasets(this.productId, this.tenantId).then((data: Array<TenantDatasetDto>) => {
      let i = 0;
      for(let dataset of data){

        this.customDatasets.push(dataset);
        if(i % 2 == 0){
          this.customDatasetsColumn1.push(dataset);
        }else{
          this.customDatasetsColumn2.push(dataset);
        }
            i = i +1; 
      }
    });
  }

}

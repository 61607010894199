import { Injectable } from '@angular/core';
import { ApiService } from '../../shared/api/api.service';
import { ActivityDto } from './dto/activity-dto';
import { SessionDto } from './dto/session-dto';
import { AuthService } from '../../auth/shared/auth.service';
import { UtilService } from '../../shared/util/util.service';
import { ActivitySearchDto } from "./dto/activity-search-dto";
import { SearchResultDto } from 'src/app/shared/dto/search-result-dto';
import { HttpRequestService } from 'src/app/shared/http/http-request.service';
import { AIRequestFileContextDto } from './dto/ai-request-file-context-dto';
import { AIRequestDto } from './dto/ai-request-dto';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpRequestOptions } from 'src/app/shared/http/http-request-options.model';
import { AiRequestFileUploadUrlDto } from './dto/ai-request-file-upload-url-dto';
import { AiRequestFileUploadUrlResponseDto } from './dto/ai-request-file-upload-response-dto';

@Injectable({
  providedIn: 'root'
})
export class InisghtsService {



  private _tenantId: string = "";
  private _productId: string = "";
  private _uploadedFile: File = null;
private _fileUploadUrlData: AiRequestFileUploadUrlResponseDto = new AiRequestFileUploadUrlResponseDto();

  public constructor(private _apiService: ApiService, private _authService: AuthService, private _utilService: UtilService, private _http: HttpClient, private _httpRequestService: HttpRequestService) {


    this._tenantId = this._authService.getUserSession().tenantId;
    this._productId = this._authService.getUserSession().productId;



  }

  public GetValidSession = (id: string): Promise<SessionDto> => {
    var url = "api/v1/insights/common/session/valid/" + id;
    return this._apiService.get(url);
  }


  public GetActivity = (pageNumber: number): Promise<Array<ActivityDto>> => {
    var url = "api/v1/insights/common/activity?productid=" + this._productId + "&tenantid=" + this._tenantId + "&pageNumber=" + pageNumber.toString();
    return this._apiService.get(url);
  }

  public CreateActivity = (activityType: string): Promise<void> => {


    var activity = new ActivityDto();
    activity.activityType = activityType;
    activity.productId = this._productId;
    activity.tenantId = this._tenantId;
    var url = "api/v1/insights/common/activity";
    return this._apiService.post(url, activity);
  }

  public SearchActivity = (search: ActivitySearchDto): Promise<SearchResultDto<ActivityDto>> => {

    var url = "api/v1/insights/common/activity";
    url = this._utilService.appendObjectPropertyValuesToUrlQuerystring(url, search);

    return this._apiService.get(url);
  }

  public GetLastSuccessfullDataIngestionActivities = (tenantId: string, count: number): Promise<Array<ActivityDto>> => {
    var url = "api/v1/insights/common/activity/" + tenantId + "/lastsuccessfuldataingestion?count=" + count.toString();
    return this._apiService.get(url);
  }

  public CreateActivityWithSuccessResult = (activityType: string, result: string): Promise<void> => {


    var activity = new ActivityDto();
    activity.activityType = activityType;
    activity.productId = this._productId;
    activity.tenantId = this._tenantId;
    activity.success = true;
    activity.result = result;
    var url = "api/v1/insights/common/activity";
    return this._apiService.post(url, activity);
  }

  public CreateActivityWithFailureResult = (activityType: string, result: string): Promise<void> => {


    var activity = new ActivityDto();
    activity.activityType = activityType;
    activity.productId = this._productId;
    activity.tenantId = this._tenantId;
    activity.success = false;
    activity.result = result;
    var url = "api/v1/insights/common/activity";
    return this._apiService.post(url, activity);
  }

  // public GetAiRequestTextResponse = (request: string): Promise<string> => {
  //   var url = "api/v1/insights/common/ai/request?requestText=" + request;
  //   return this._apiService.get(url, request);
  // }

  public GetAiResponse = (request: AIRequestDto): Promise<string> => {
    request.productId = this._productId;
    request.tenantId = this._tenantId;
    var url = "api/v1/insights/common/ai/request/" + this._productId + "/" + this._tenantId;
    return this._apiService.post(url, request);
  }

  

  public BeginFileWordExtraction = (fileUploadUrlData: AiRequestFileUploadUrlResponseDto) : Promise<any> =>{

    this._fileUploadUrlData = fileUploadUrlData;
    var s3headers = new HttpHeaders({ 'Content-Type': fileUploadUrlData.contentType });
      return this._http.put(fileUploadUrlData.uploadUrl, this._uploadedFile, { headers: s3headers }).toPromise().then(() => {
        //success

        var extractFilePayload = new AIRequestFileContextDto();
        extractFilePayload.productId = this._productId;
        extractFilePayload.tenantId = this._tenantId;
        extractFilePayload.s3Key =  this._fileUploadUrlData.s3Key;
        //this returns the s3output key of where the text traction output will be when its complete
        var url = "api/v1/insights/common/ai/extractfilewords/" + this._productId + "/" + this._tenantId;
        return this._apiService.post(url, extractFilePayload).then(()=>{

          return Promise.resolve(this._fileUploadUrlData);

        });

      }, (x) => {
        //error
        return Promise.reject(x);
      });
  }

  public AiRequestExtractFileWordsComplete = (payload: AIRequestFileContextDto): Promise<boolean> => {

    var url = "api/v1/insights/common/ai/extractfilewords/" + this._productId + "/" + this._tenantId + "/complete";
    return this._apiService.post(url, payload);
  }

  // public AiRequestGetExtractedFileWordsS3Key = (originalS3Key: string): Promise<string> => {

  //   var request = new AIRequestFileContextDto();
  //   request.productId = this._productId;
  //   request.tenantId = this._tenantId;
  //   request.s3Key = originalS3Key;
  //   var url = "api/v1/insights/common/ai/extractedfilewords/" + this._productId + "/" + this._tenantId + "/key";
  //   return this._apiService.post(url, request);
  // }

  // public AiRequestGetExtractedFileWords = (originalS3Key: string): Promise<string> => {

  //   var request = new AIRequestFileContextDto();
  //   request.productId = this._productId;
  //   request.tenantId = this._tenantId;
  //   request.s3Key = originalS3Key;
  //   var url = "api/v1/insights/common/ai/extractfilewords/" + this._productId + "/" + this._tenantId + "/result";
  //   return this._apiService.post(url, request);
  // }

  public GetFileUploadUrlData = (file: File): Promise<AiRequestFileUploadUrlResponseDto> => {
    
    this._uploadedFile = file;
    //get the direct s3 upload
    var fileName = file.name;
    
    var fileSplit =  fileName.split(".");
    var fileType = fileSplit[fileSplit.length - 1];
    var request = new AiRequestFileUploadUrlDto();
    request.productId = this._productId;
    request.tenantId = this._tenantId;
    request.fileNameWithExtension = fileName;
    request.contentType =  this.GetContentType(fileName)
    var url = "api/v1/insights/common/ai/request/" + this._productId + "/" + this._tenantId + "/fileuploadurldata";
    return this._apiService.post(url, request).then((data:AiRequestFileUploadUrlResponseDto) =>{
      this._fileUploadUrlData = data;
      return Promise.resolve(this._fileUploadUrlData);
    });

  }



 public  GetContentType(fileName: string) : string
 {
  var fileSplit =  fileName.split(".");
  var contentType = fileSplit[fileSplit.length - 1].toLowerCase().trim();
  contentType = "application/" + contentType;
  return contentType
 }

}
export class SelectableItemDto {
  public constructor(item? :any) {


    this.selected = false;

    if(item != null){
       this.item = item;
    }
  }

  public selected: boolean
  public item: any

}
import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth/shared/auth.service';
import { ApiService } from 'src/app/shared/api/api.service';
import { UtilService } from 'src/app/shared/util/util.service';

@Component({
  selector: 'admin-api-invoke',
  templateUrl: './admin-api-invoke.component.html',
  styleUrl: './admin-api-invoke.component.css'
})
export class AdminApiInvokeComponent {


  public apiUrl: string;
  public apiOutput: string;

  public constructor(private _apiService: ApiService, private _authService: AuthService, private _utilService: UtilService) {


     



  }
  ngOnInit(): void {
    this._apiService.ensureApiUrlsAreSet();
     this.apiUrl = this._apiService.getApiDomain() + "/api/v1/insights/common/health-check";
  }

  invoke(): void {
    this._apiService.get(this.apiUrl).then((data: any) =>{

      if(data != null){
        this.apiOutput = JSON.stringify(data);
      }
    }, (err: any) =>{
      if(err != null){
        this.apiOutput = JSON.stringify(err);
      }
    });
  }
}

import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser';


import { LocalStorageService } from '../../../../shared/storage/local-storage.service';
import { DashboardService } from '../../../../dashboard/shared/dashboard.service';

import { createEmbeddingContext, DashboardContentOptions, FrameOptions, EmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { AuthService } from '../../../../auth/shared/auth.service';
import { LogService } from '../../../../shared/log/log.service';
import { UtilService } from '../../../../shared/util/util.service';
import { McTradeService } from '../../shared/mctrade.service';
import { SegmentationPredictionDto } from '../../shared/dto/segmentation-prediction-dto';
import { SelectableItemDto } from 'src/app/shared/dto/selectable-item-dto';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { ApiService } from '../../../../shared/api/api.service';
import { InisghtsService } from 'src/app/insights/shared/insights.service';
import { SegmentationSummaryDto } from '../../shared/dto/segmentation-summary-dto';
import { CsvService } from 'src/app/shared/csv/csv.service';
import { SegmentationPointDto } from '../../shared/dto/segmentation-point-dto';
import { Chart } from 'chart.js';
import { SegmentationFeatureImportanceResultDto } from '../../shared/dto/segmentation-feature-importance-dto';


@Component({
  selector: 'mctrade-membership-segmentation-ml',
  templateUrl: './mctrade-membership-segmentation-ml.component.html',
  styleUrls: ['./mctrade-membership-segmentation-ml.component.css']
})
export class MembershipSegmentationMlComponent implements OnInit {


  public segmentationSavedSummaryResults: Array<SegmentationSummaryDto> = new Array<SegmentationSummaryDto>();
  public segmentationDetails: Array<SegmentationPredictionDto> = new Array<SegmentationPredictionDto>();
  public segmentationDetailsFiltered: Array<SegmentationPredictionDto> = new Array<SegmentationPredictionDto>();

  public summaryColumns: any[];
  public detailColumns: any[];
  public summaryColumnsDynamic: any[];
  public detailColumnsDynamic: any[];

  private _tenantId: string = "";
  private _productId: string = "";
  public showSegmentationDetails: boolean = false;
  public selectedSegmentId = 0;
  public clusterChartData = [];
  public clusterChartOptions = {};
  public scatterChart: Chart;
  public chartJsConfig = {
    type: 'scatter',
    data: [],
    options: {
      scales: {
        x: {
          type: 'linear',
          position: 'bottom'
        }
      }
    }
  };

  public segmentationFeatureImportanceResults: Array<SegmentationFeatureImportanceResultDto> = new Array<SegmentationFeatureImportanceResultDto>();

  @ViewChild('scrollTarget')
  scrollTargetRef: ElementRef;

  constructor(private _dashboardService: DashboardService, private _insightsService: InisghtsService, private _logService: LogService, private _authService: AuthService, private _utilService: UtilService, private _notifyService: NotificationService, private _mcTradeDataService: McTradeService, private _apiService: ApiService, private _csvService: CsvService) {


  }
  ngOnInit(): void {


    this._tenantId = this._authService.getUserSession().tenantId;
    this._productId = this._authService.getUserSession().productId;

    this._mcTradeDataService.GetSavedSegmentationFeatureImportance().then((data: Array<SegmentationFeatureImportanceResultDto>) => {

      this.segmentationFeatureImportanceResults = new Array<SegmentationFeatureImportanceResultDto>();

      var itemsAdded = 0;
      for (let item of data) {
        item.featureName = item.featureName.split("_")[0];

        if (this._utilService.arrayContainsEntry(this.segmentationFeatureImportanceResults, "featureName", item.featureName) == false) {
          this.segmentationFeatureImportanceResults.push(item);
          itemsAdded = itemsAdded + 1
        }

        // if(itemsAdded >= 5) {
        //   break;
        // }
      }


      this.summaryColumns = [];
      this.detailColumns = [];

       //add static columns needed in the collection for exports
       this.summaryColumns.push({
        field: "segmentId",
        header: "segmentId",
        currency: false,
        number: false,
        category: false,
        isDynamic: false,
      });
      this.summaryColumns.push({
        field: "countOfMembers",
        header: "countOfMembers",
        currency: false,
        number: true,
        category: false,
        isDynamic: false,
      });

       

      this.detailColumns.push({
        field: "name",
        header: "name",
        currency: false,
        number: false,
        category: false,
        isDynamic: false,
      });
      this.detailColumns.push({
        field: "profileid",
        header: "profileid",
        currency: false,
        number: false,
        category: false,
        isDynamic: false,
      });
      this.detailColumns.push({
        field: "descr",
        header: "descr",
        currency: false,
        number: false,
        category: false,
        isDynamic: false,
      });
      this.detailColumns.push({
        field: "probability",
        header: "probability",
        currency: false,
        number: true,
        category: false,
        isDynamic: false,
      });

      //add dynamic columns
      for (let feature of this.segmentationFeatureImportanceResults) {

        var colSummary = {
          field: feature.featureName,
          header: feature.featureName,
          currency: false,
          number: false,
          category: false,
          isDynamic: true,
        }

        var colDetail = {
          field: feature.featureName,
          header: feature.featureName,
          currency: false,
          number: false,
          category: false,
          isDynamic: true,
        }

        if (this.isCurrency(feature.featureName)) {
          colSummary.currency = true;
          colDetail.currency = true;
        }
        else if (this.isNumber(feature.featureName)) {
          colSummary.number = true;
          colDetail.number = true;
        }
        else {
          colSummary.category = true;
          colDetail.category = true;
        }
        this.summaryColumns.push(colSummary);
        this.detailColumns.push(colDetail);



      }

     


      this.summaryColumnsDynamic = this.summaryColumns.filter(item => item.isDynamic == true);
      this.detailColumnsDynamic = this.detailColumns.filter(item => item.isDynamic == true);

    });

    var promise1 = this._mcTradeDataService.GetSavedSegmentationPredictionsFromSelfSignedUrl().then((data: Array<SegmentationPredictionDto>) => {
      this.segmentationDetails = new Array<SegmentationPredictionDto>();

      for (let item of data) {
        if (item.predictedLabel == false) {
          item.descr = "Predicted Drop"
        } else {
          item.descr = "Predicted Join"
        }
      }

      this.segmentationDetails = data;

    });

    var promise2 = this._mcTradeDataService.GetSavedSegmentationSummary().then((data: Array<SegmentationSummaryDto>) => {
      this.segmentationSavedSummaryResults = new Array<SegmentationSummaryDto>();
      this.segmentationSavedSummaryResults = data;
      this.segmentationSavedSummaryResults = this.segmentationSavedSummaryResults.sort((n1, n2) => {
        if (n1.segmentId > n2.segmentId) {
          return 1;
        }

        if (n1.segmentId < n2.segmentId) {
          return -1;
        }

        return 0;
      });






    });


    var promises = [];
    promises.push(promise1);
    promises.push(promise2);
    Promise.all(promises).then(() => {

      //loop thru the segments to get the x an y points to plot
      this.populateScatterChart();

    });



  }

  populateScatterChart() {
    var chartDatasets = [];
    var chartLabels = [];
    for (let segment of this.segmentationSavedSummaryResults) {

      var chartDataset = {
        data: [],
        label: "Cluster " + segment.segmentId.toString()
      }



      var segmentDetails = this.segmentationDetails.filter(item => segment.segmentId == item.predictedclusterId);

      for (var segmentDetail of segmentDetails) {


        if (segmentDetail.visualizedatapoint.x != null && segmentDetail.visualizedatapoint.y != null) {
          //NOTE: display is not standard we are adding it so we can access it via our callback in the tooltip options
          chartDataset.data.push({ x: segmentDetail.visualizedatapoint.x, y: segmentDetail.visualizedatapoint.y, display: segmentDetail.name + " (" + segmentDetail.profileid.toString() + ")" })
        }


      }


      chartDatasets.push(chartDataset);
      chartLabels.push(segmentDetail.profileid.toString())

    }



    var ctx = document.getElementById("scatterChart") as HTMLCanvasElement;
    this.scatterChart = new Chart(ctx,
      {
        type: 'scatter',
        data: {
          datasets: chartDatasets
          //labels: chartLabels
        },
        options: {
          plugins: {
            tooltip: {
              callbacks: {
                label: (context) => {
                  return context.raw["display"];
                }
              }
            }
          },
          scales: {
            x: {
              display: false
            },
            y: {
              display: false
            }
          },

        }

      }
    );
  }

  viewSegmentationDetails(segmentId: number) {


    this.showSegmentationDetails = true;
    this.selectedSegmentId = segmentId;
    this.segmentationDetailsFiltered = this.segmentationDetails.filter(item => segmentId == item.predictedclusterId)
    this.segmentationDetailsFiltered = this.segmentationDetailsFiltered.sort((n1, n2) => {
      if (n1.predictedclusterId > n2.predictedclusterId) {
        return 1;
      }

      if (n1.predictedclusterId < n2.predictedclusterId) {
        return -1;
      }

      return 0;
    });


  }

  exportSummaryToCsv() {
    this._csvService.exportToCsv("cluster_summary", this.segmentationSavedSummaryResults)

  }

  exportDetailsToCsv() {

    this._csvService.exportToCsv("cluster_" + this.selectedSegmentId.toString(), this.segmentationDetailsFiltered)
  }

  isNumeric(value: string) {
    return this._utilService.isNumeric(value);
  }

  isCurrency(field: string) {
    var bol = false;
    var fieldNames = ["annualdues", "invoicesoutstandingdues", "nonduesrevenueinlastyearamember", "invoicesoutstandingnondues", "activearbamount", "nonduesrevenueinlastyearamember"]
    if (this._utilService.arrayContainsValue(fieldNames, field) == true) {
      bol = true;
    } else if (this._utilService.containsAnyCase(field, "sum")) {
      bol = true;
    }

    return bol;
  }

  isNumber(field: string) {
    var bol = false;
    var fieldNames = ["memberage", "relatedindividuals", "relatedorganizations", "employeesft", "employeespt", "customers", "avgdaystopayinvoicesdues", "benefitsused", "committeeparticipation"]
    if (this._utilService.arrayContainsValue(fieldNames, field)) {
      bol = true;
    } else if (this._utilService.containsAnyCase(field, "count")) {
      bol = true;
    }

    return bol;
  }
}

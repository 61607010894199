export class WellKnownEndpoints {
  public issuer: string;
  public loginEndpoint: string;
  public authorizationEndpoint: string;
  public tokenEndpoint: string;
  public userInfoEndpoint: string;
  public endSessionEndpoint: string;
  public checkSessionIframe: string;
  public revocationEndpoint: string;
  public introspectionEndpoint: string;

  public constructor() {
    this.issuer = '';
    this.loginEndpoint = '';
    this.authorizationEndpoint = '';
    this.tokenEndpoint = '';
    this.userInfoEndpoint = '';
    this.endSessionEndpoint = '';
    this.checkSessionIframe = '';
    this.revocationEndpoint = '';
    this.introspectionEndpoint = '';
  }
}

import { Component, OnInit } from '@angular/core';
import { UtilService } from './../shared/util/util.service';

@Component({
  selector: 'unauthorized',
  templateUrl: './unauthorized.component.html'
})
export class UnauthorizedComponent implements OnInit {
  displayResponse: string;

  constructor(private _utilService: UtilService) {
    
  }
    ngOnInit(): void {
       
    }


}

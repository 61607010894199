<div class="container">
  <div class="col-12">
    <div class="grid">
      <div class="col-3">
        <h3>Unauthorized</h3>
      </div>
    </div>
  </div>
</div>


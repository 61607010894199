export class ApiCacheOptions {
  public constructor() {
    this.CacheSeconds = 0;
    this.DisableCaching = false;
    this.DeleteCache = false;
    this.DisableSlidingCache = false;
  }

  public CacheSeconds: number;
  public DisableCaching: boolean;
  public DeleteCache: boolean;
  public DisableSlidingCache: boolean;
}

export class CreateTenantConfigDto {
  public constructor() {
    this.tenantId = '';
    this.productId = '';
    this.adminEmail = '';
    this.adminPhone = '';
    this.adminFirstName = '';
    this.adminLastName = '';
    this.displayName = '';
    this.changedBy = '';
    this.notifyEmail = '';
    this.ingestDataFirst = true;
    this.injestionDataOverwriteExisting = true;
    this.injestionDataStartDate =  new Date();;
    this.injestionDataEndDate = new Date(); ;
  }

    tenantId: string;
    productId: string;
    adminEmail: string;
    adminPhone: string;
    adminFirstName: string;
    adminLastName: string;
    displayName: string;
    changedBy: string;
    ingestDataFirst: boolean;
    injestionDataStartDate: Date;
    injestionDataEndDate: Date;
    injestionDataOverwriteExisting: boolean;
    notifyEmail: string;
}

export class SelectableHiddenItemDto {
  public constructor(item? :any) {


    this.selected = false;
    this.hidden = false;

    if(item != null){
       this.item = item;
    }
  }

  public selected: boolean
  public hidden: boolean
  public item: any

}
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser';


import { LocalStorageService } from '../../../../shared/storage/local-storage.service';
import { DashboardService } from '../../../../dashboard/shared/dashboard.service';

import { createEmbeddingContext, DashboardContentOptions, FrameOptions, EmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { AuthService } from '../../../../auth/shared/auth.service';
import { LogService } from '../../../../shared/log/log.service';
import { UtilService } from '../../../../shared/util/util.service';
import { McTradeService } from '../../shared/mctrade.service';
import { ProfileSearchDto } from '../../shared/dto/profile-search-dto';
import { ProfileSummaryDto } from '../../shared/dto/profile-summary-dto';
import { RetentionPredictionResultsDto } from '../../shared/dto/retention-prediction-results-dto';
import { RetentionPredictionSearchDto } from '../../shared/dto/retention-predition-search-dto';
import { SelectableItemDto } from 'src/app/shared/dto/selectable-item-dto';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { ApiService } from '../../../../shared/api/api.service';
import { InisghtsService } from 'src/app/insights/shared/insights.service';
import { CsvService } from 'src/app/shared/csv/csv.service';
import { RetentionFeatureImportanceResultDto } from '../../shared/dto/retention-feature-importance-dto';


@Component({
  selector: 'mctrade-membership-predictive-ml',
  templateUrl: './mctrade-membership-predictive-ml.component.html',
  styleUrls: ['./mctrade-membership-predictive-ml.component.css']
})
export class MctradeMembershipPredictiveMlComponent implements OnInit {

  public profileSearch: ProfileSearchDto = new ProfileSearchDto();
  public retentionPredictionSearch: RetentionPredictionSearchDto = new RetentionPredictionSearchDto();
  public keyword: string = "";
  public profileIdsCsv: string;
  public membersOnly: boolean = true;

  public profiles: Array<SelectableItemDto> = new Array<SelectableItemDto>();
  public profileRetentionPredictionResults: Array<RetentionPredictionResultsDto> = new Array<RetentionPredictionResultsDto>();
  public profileRetentionPredictionSavedResults: Array<RetentionPredictionResultsDto> = new Array<RetentionPredictionResultsDto>();
  private _tenantId: string = "";
  private _productId: string = "";
  public searchingProfiles: boolean = false;

  public retentionFeatureImportanceResults : Array<RetentionFeatureImportanceResultDto> = new Array<RetentionFeatureImportanceResultDto>();


  @ViewChild('scrollTarget')
  scrollTargetRef: ElementRef;

  constructor(private _dashboardService: DashboardService, private _insightsService: InisghtsService, private _logService: LogService, private _authService: AuthService, private _utilService: UtilService, private _notifyService: NotificationService, private _mcTradeDataService: McTradeService, private _apiService: ApiService, private _csvService: CsvService) {


  }
  ngOnInit(): void {

    this.profileSearch.member = true;
    this._tenantId = this._authService.getUserSession().tenantId;
    this._productId = this._authService.getUserSession().productId;


    this._mcTradeDataService.GetSavedProfileRetentionPredictions(this.retentionPredictionSearch).then((data: Array<RetentionPredictionResultsDto>) => {
      this.profileRetentionPredictionSavedResults = new Array<RetentionPredictionResultsDto>();

      for (let item of data) {
        if (item.predictedLabel == false) {
          item.descr = "Predicted Drop"
        } else {
          item.descr = "Predicted Join"
        }
      }


      data = data.sort((a, b) => {
        if (a.predictedLabel == false && b.predictedLabel == false && a.probability > b.probability) {
          return -1;
        } else if (a.predictedLabel == false && b.predictedLabel == false && a.probability < b.probability) {
          return 1;
        } else if (a.predictedLabel == false && b.predictedLabel == true) {
          return -1;
        } else if (a.predictedLabel == true && b.predictedLabel == false) {
          return 1;
        }
        else if (a.predictedLabel == true && b.predictedLabel == true && a.probability < b.probability) {
          return -1;
        } else if (a.predictedLabel == true && b.predictedLabel == true && a.probability > b.probability) {
          return 1;
        } else {
          return 0
        }

      });
      this.profileRetentionPredictionSavedResults = data;

    });

    this._mcTradeDataService.GetSavedRetentionFeatureImportance().then((data: Array<RetentionFeatureImportanceResultDto>) => {

      
      this.retentionFeatureImportanceResults = new Array<RetentionFeatureImportanceResultDto>();

      var itemsAdded = 0;
      for(let item of data){
        item.feature = item.feature.split("_")[0];

        if(this._utilService.arrayContainsEntry(this.retentionFeatureImportanceResults, "feature", item.feature) == false){
          this.retentionFeatureImportanceResults.push(item);
          itemsAdded = itemsAdded + 1
        }

        // if(itemsAdded >= 5) {
        //   break;
        // }
      }
       
    });


  }

  searchProfiles = (): void => {

    this.searchingProfiles = true;
    this.profileSearch = new ProfileSearchDto();
    this.profileSearch.member = this.membersOnly;

    if (this._utilService.isNotNullEmptyOrWhitespace(this.keyword)) {
      this.profileSearch.keyword = this.keyword;
    }

    if (this._utilService.isNotNullEmptyOrWhitespace(this.profileIdsCsv)) {
      //these are a csv or profileIds to convert to an array of ints
      var profileIdssTextArray = this.profileIdsCsv.split(",");
      for (var profileIdString of profileIdssTextArray) {

        this.profileSearch.profileIds.push(this._utilService.toNumber(profileIdString))
      }
    }


    this._mcTradeDataService.searchProfiles(this.profileSearch).then(data => {

      this.profiles = new Array<SelectableItemDto>();
      for (var record of data) {
        var profileItem = new RetentionPredictionResultsDto();
        profileItem.name = record.name;
        profileItem.annualDues = record.annualDues;
        profileItem.profileId = record.profileId;
        profileItem.membershipLevel = record.membershipLevel;
        var selectableItem = new SelectableItemDto(profileItem)
        this.profiles.push(selectableItem);
      }

      setTimeout(() => {
        this.searchingProfiles = false;
        this.scrollTargetRef.nativeElement.scrollIntoView();
      }, 1000)


      // var scrollElement = document.getElementById("lblScrollToLabel");
      // scrollElement.scrollIntoView({
      //   behavior: "smooth",
      //   block: "end",
      //   inline: "end"
      // });


    }, (err: any) => {
      this._notifyService.error(err.error);
      this.searchingProfiles = false;
    });
  }

  predictRetention = (item: SelectableItemDto): void => {

    var profileRetentionItem = <RetentionPredictionResultsDto>item.item;
    item.selected = true;
    var profileIds = new Array<number>();
    profileIds.push(profileRetentionItem.profileId);




    this._mcTradeDataService.getProfileRetentionPredictions(profileIds).then((data: Array<RetentionPredictionResultsDto>) => {
      item.selected = false;
      if (data.length > 0) {
        profileRetentionItem.probability = data[0].probability;

        if (data[0].predictedLabel == false) {

          profileRetentionItem.descr = "Predicted Drop "
        } else {
          profileRetentionItem.descr = "Predicted Join "
        }
        this._insightsService.CreateActivityWithSuccessResult("ML_Profile_Retention_Screen_Manual_Prediction", JSON.stringify(profileRetentionItem));
      }

    }, (err: any) => {
      item.selected = false;
      this._notifyService.error(err.error);
    });
  }

  getProfileRetentionInputDataSaved = (): void => {

    this._notifyService.info("Downloading source data...");

    var profielIds = new Array<number>();
    for (var item of this.profileRetentionPredictionSavedResults) {

      profielIds.push(item.profileId);
    }
    this._mcTradeDataService.downloadProfileRetentionPredictionInputDataAsCsv(profielIds);
  }

  getProfileRetentionInputDataSearched = (): void => {

    this._notifyService.info("Downloading source data...");

    var profielIds = new Array<number>();
    for (var selectableItem of this.profiles) {

      profielIds.push(selectableItem.item.profileId);
    }
    this._mcTradeDataService.downloadProfileRetentionPredictionInputDataAsCsv(profielIds);
  }

  exportToCsv(){

    this._csvService.exportToCsv("retention_predicitons.csv", this.profileRetentionPredictionSavedResults, true);

  }

}

import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser';


import { LocalStorageService } from '../shared/storage/local-storage.service';
import { DashboardService } from '../dashboard/shared/dashboard.service';

import { createEmbeddingContext, DashboardContentOptions, FrameOptions } from 'amazon-quicksight-embedding-sdk';
import { AuthService } from '../auth/shared/auth.service';
import { LogService } from '../shared/log/log.service';
import { RabTenantDto } from '../admin/shared/dto/rab-tenant-dto';


@Component({
  selector: 'insights-builder',
  templateUrl: './insights-builder.component.html',
  styleUrls: ['./insights-builder.component.css']
})
export class InsightsBuilderComponent implements OnInit {


  @Input({ required: false }) manualLoad: boolean = false;

  private _productId: string = "";
  private _tenantId: string = "";


  public webConsoleEmbedUrlRaw: string = "";

  constructor(private _dashboardService: DashboardService, private _logService: LogService, private _authService: AuthService, private _sanitizer: DomSanitizer, private _localStorageService: LocalStorageService) {


  }

  ngOnInit() {

    this._tenantId = this._authService.getUserSession().tenantId;
    this._productId = this._authService.getUserSession().productId;

    if (this.manualLoad == true) {
      //this will be loaded by a p[arent component liek admin when its ready with the tenant
    } else {
      this.loadQuicksightsElements();
    }





  }

  loadQuicksightsElements = (): void => {

    var promise1 = this._dashboardService.getWebConsoleEmbedUrl().then((data: string) => {

      //this.dashboardEmbedUrlRaw = this._sanitizer.bypassSecurityTrustResourceUrl(data);
      this.webConsoleEmbedUrlRaw = data;


    });



    //

    Promise.all([promise1]).then((data) => {
      this.embedQuicksightTools();
    });
  }


  loadQuicksightsElementsByProductIdAndTenantId = (productId: string, tenantId: string): void => {

    var promise1 = this._dashboardService.getWebConsoleEmbedUrlFromProductIdAndTenantId(productId, tenantId).then((data: string) => {

      //this.dashboardEmbedUrlRaw = this._sanitizer.bypassSecurityTrustResourceUrl(data);
      this.webConsoleEmbedUrlRaw = data;


    });

    Promise.all([promise1]).then((data) => {
      this.embedQuicksightTools();
    });
  }

  embedQuicksightTools = async () => {

    var embeddingContext = await createEmbeddingContext({
      onChange: (changeEvent, metadata) => {
        console.log('Context received a change', changeEvent, metadata);
      },
    });


    //Web Console
    var frameOptionsWebConsole = {
      url: this.webConsoleEmbedUrlRaw, //"<YOUR_EMBED_URL>", // replace this value with the url generated via embedding API
      container: '#insights-builder-container',
      height: "700px",
      //width: "1000px",
      onChange: (changeEvent, metadata) => {
        switch (changeEvent.eventName) {
          case 'FRAME_MOUNTED': {
            this._logService.info("WebConsole - Do something when the experience frame is mounted.");
            break;
          }
          case 'FRAME_LOADED': {
            this._logService.info("WebConsole - Do something when the experience frame is loaded.");
            break;
          }
        }
      },
    };

    var contentOptionsWebConsole = {
      onMessage: async (messageEvent, experienceMetadata) => {
        switch (messageEvent.eventName) {
          case 'ERROR_OCCURRED': {
            this._logService.info("WebConsole - Do something when the embedded experience fails loading.");
            break;
          }
        }
      }
    };
    var embeddedConsoleExperience = await embeddingContext.embedConsole(frameOptionsWebConsole, contentOptionsWebConsole);




  };



}
